import { API } from 'aws-amplify'

import { generateDefaultSaltFields } from '../containers/Patient/ClinicalNotes/helpers'
import { NoteType } from '../hooks/useClinicalNoteFields/utils'
import { ClinicalNote, NoteTypes } from '../shared-types'

type getNoteById = (patientId: string, noteId: string) => Promise<ClinicalNote> // Unmapped for legacy-support on ClinicalNotes components

export const getNotesCount = async ({
  startDate,
  endDate,
  noteType,
  signed,
}: {
  startDate: string
  endDate: string
  noteType?: NoteTypes
  signed?: boolean
}): Promise<{ count: number }> => {
  const params = new URLSearchParams()

  params.append('startDate', startDate)
  params.append('endDate', endDate)
  if (noteType) {
    params.append('noteType', noteType)
  }
  if (signed !== undefined) {
    params.append('signed', `${signed}`)
  }
  return await API.get('core-api', `/provider/notes/count?${params}`, {})
}

export const getNoteById: getNoteById = async (patientId, noteId) => {
  return await API.get(
    'core-api',
    `/provider/notes/${noteId}/patient/${patientId}`,
    {}
  )
}

export const getNotePdfS3Key = async (noteId?: string) => {
  if (!noteId) return Promise.resolve(null)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const result = await API.get(
    'clinical-notes',
    `/notes/${noteId}/pdf?timeZone=${timeZone}` +
      `&diagnosisSnapshotIsEnabled=true` +
      `&medicationsSnapshotIsEnabled=true`,
    {}
  )
  return result.s3Key
}

export const loadPatientClinicalNotes = async (patientId: string) => {
  return API.get('patient-profile', `/patients/${patientId}/clinicalNotes`, {})
}

export const autoSaveClinicalNotesV1 = async (
  data: NoteType,
  noteId?: string,
  auditLog = false
) => {
  if (!noteId) {
    // auto-create new note
    return API.post('clinical-notes', `/notes/v1/autosave`, { body: data })
  }

  // Else auto-update existing note
  const query = new URLSearchParams({ auditLog: auditLog.toString() })
  return API.put('clinical-notes', `/notes/v1/autosave/${noteId}?${query}`, {
    body: data,
  })
}

/**
 * This request takes is used to create a note. This is intended to be used to create a note before opening the
 * autosave note editor.
 *
 * @param noteData - The content of the note
 * @returns Promise<ClinicalNote>
 */
export const createNoteV1 = async (
  noteData: NoteType
): Promise<ClinicalNote> => {
  return API.post('clinical-notes', `/notes/v1/autosave`, { body: noteData })
}

/**
 * This request takes a note Id and precisely copies the fiels over to another note.
 * This is intended to be used to create a note before opening the autosave note editor.
 *
 * @param noteId - The note ID that will be used to generate a copy from
 * @returns Promise<ClinicalNote>
 */
export const copyNoteV1 = async (noteId: string): Promise<ClinicalNote> => {
  const query = new URLSearchParams({ copyFrom: noteId })
  // This is necessary to store client-specific times like what `getDefaultFields()` does when SALT-ing a note
  const defaultOverrides = generateDefaultSaltFields()
  return API.post('clinical-notes', `/notes/v1/autosave?${query}`, {
    body: defaultOverrides,
  })
}

/**
 * This request takes a patient's ID and note type, and pulls up a note type for that patient and, if available,
 * will copy that note to a new note and send the body of the new note back. This is intended to be used to create a note before
 * opening the autosave note editor.
 *
 * @param noteType - The type of note to SALT from
 * @param patientId - Patient id used to search for the note type to SALT from
 * @returns Promise<ClinicalNote>
 */
export const saltNoteV1 = async (
  noteType: NoteTypes,
  patientId: string
): Promise<ClinicalNote> => {
  const query = new URLSearchParams({ patientId, saltType: noteType })
  // This is necessary to store client-specific times like what `getDefaultFields()` does when SALT-ing a note
  const defaultOverrides = generateDefaultSaltFields()
  return API.post('clinical-notes', `/notes/v1/autosave?${query}`, {
    body: defaultOverrides,
  })
}
