import React from 'react'

import { logError } from 'libs/errorLib'
import { Link } from 'stories/BaseComponents'

import {
  ClaimsV2Error,
  ClaimsV2KnownErrorNameSet,
} from '../ErrorHandling/Errors'

import styles from './ClaimsErrorBoundary.module.scss'

export const testIds = {
  errorString: 'claims-error-boundary--error-string',
  recoveryLink: 'claims-error-boundary--recovery-link',
}

export const DISPLAY_STRINGS = {
  returnToPatientBilling: 'Return to Patient Billing',
  problemLoadingPage: 'Sorry there was a problem loading this page',
}

/**
 * Use for cases where you want to trap and show specific error message to the user
 */
export class ClaimsErrorBoundary extends React.Component<React.PropsWithChildren> {
  state = {
    error: null,
    errorStringToShow: '',
    recoveryUrl: '',
  }

  componentDidCatch(error: ClaimsV2Error, errorInfo: React.ErrorInfo) {
    // @ts-expect-error errorLib.js cannot be easily fully typed; errorInfo's type is not correctly inferrable
    logError(error, errorInfo, { 'app.showed_error_boundary': 'yes' })

    const errorStringToShow = ClaimsV2KnownErrorNameSet.has(error.name)
      ? error.message
      : DISPLAY_STRINGS.problemLoadingPage

    const urlEncodedParams = error?.patientId
      ? new URLSearchParams({ patientId: error?.patientId })
      : ''

    this.setState({
      error,
      errorStringToShow,
      recoveryUrl: `/patient/billing?${urlEncodedParams}`,
    })
  }

  render() {
    if (this.state.error !== null) {
      return (
        <div className={styles.wrapper}>
          <h4 data-testid={testIds.errorString}>
            {this.state.errorStringToShow}
          </h4>
          <Link
            className={styles.recoveryLink}
            type="button"
            to={this.state.recoveryUrl}
            testId={testIds.recoveryLink}
          >
            {DISPLAY_STRINGS.returnToPatientBilling}
          </Link>
        </div>
      )
    }
    return this.props.children
  }
}
