import React, { useEffect, useState } from 'react'

import { Modal } from 'antd'
import { Location } from 'history'
import { Prompt } from 'react-router-dom'

import { Button } from '../../../stories/BaseComponents'

export interface NavigationGuardModalProps {
  title?: string
  content?: string
  when?: boolean
  navigation: (pathName: string) => void
  saveLoading: boolean
  saveSubmitLoading: boolean
}

/**
 * Component used to prevent navigating away by popping up a confirmation modal first
 */
const ClaimsNavigationGuardModal: React.FC<NavigationGuardModalProps> = ({
  when,
  navigation,
  saveLoading,
  saveSubmitLoading,
}) => {
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    // this controls native browser pop-up that guards against page refreshes & closing tab/window
    if (!when || saveSubmitLoading || saveLoading) {
      // disabled by default
      // also use "when" prop that is passed to Prompt for logic parity
      return
    }

    const unloadGuard = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadGuard)
    return () => {
      window.removeEventListener('beforeunload', unloadGuard)
    }
  }, [when, saveLoading, saveSubmitLoading])

  useEffect(() => {
    // runs the navigation after user clicks ok
    if (confirmedNavigation && lastLocation) {
      navigation(`${lastLocation.pathname}${lastLocation.search ?? ''}`) // Navigate to the previous blocked location
      setShowModal(false)
    }
  }, [confirmedNavigation, lastLocation])

  const handleBlockedNavigation = (nextLocation: Location | any): boolean => {
    const pathname = nextLocation.pathname ?? nextLocation.location.pathname
    const blockNavigation =
      !confirmedNavigation &&
      // Does not prevent going to a different claim, but that should not be an available UI action from within a claim
      !pathname.includes('/patient/billing/claim') // will trap for '/claim-create'
    if (blockNavigation && !(saveLoading || saveSubmitLoading)) {
      setLastLocation(nextLocation)
      setShowModal(true)
      return false
    } else {
      setLastLocation(nextLocation)
    }
    return true
  }

  const handleOkClick = async () => {
    setShowModal(false)
    setConfirmedNavigation(true)
  }

  const handleCancelClick = () => {
    setLastLocation(null)
    setConfirmedNavigation(false)
    setShowModal(false)
  }

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        title="Continue without saving?"
        visible={showModal}
        confirmLoading={false}
        footer={[
          <Button
            data-testid="cancel-discard-button"
            color="#262626"
            onClick={() => handleCancelClick()}
          >
            Cancel
          </Button>,
          <Button
            data-testid="discard-button"
            type="primary"
            color="#262626"
            onClick={() => handleOkClick()}
          >
            Ok
          </Button>,
        ]}
      >
        <p>{'This claim has unsaved changes. Close without saving?'}</p>
      </Modal>
    </>
  )
}
export default ClaimsNavigationGuardModal
