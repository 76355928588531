import { BillingCode } from 'hooks/queries/useBillingCodes'
import { BillingCodeToDxPointers } from 'hooks/useBillingInfo/useBillingCodeDxMapping'
import { MODS_LIMIT } from 'libs/constants'
import { BillingTemplate } from 'shared-types'
import { generateNotification } from 'stories/BaseComponents'
import { NotificationType } from 'stories/BaseComponents/Notification'

import { ClaimFormDiagnosisWithOrder, ClaimFormLineItem } from '../types'

/**
 * @returns default diagnosis pointers for a billing code
 */
export const getDefaultDxPointers = (
  billingCodeId: number,
  mapping: BillingCodeToDxPointers,
  includedDiagnosesWithOrder: ClaimFormDiagnosisWithOrder[]
) => {
  const pointers = mapping[billingCodeId]?.latestDxCodes
    ?.map(
      (dxCode) =>
        includedDiagnosesWithOrder.find((dx) => dx.code === dxCode)?.order
    )
    .filter((pointer) => pointer !== undefined)
  if (pointers?.length) {
    return pointers as number[]
  }
  return [0]
}

export const billingCodeToLineItemBillingCode = ({
  id,
  code,
  version,
  ownerId,
  shortDescription,
}: BillingCode): ClaimFormLineItem['billingCode'] => ({
  id,
  code,
  version,
  ownerId,
  description: shortDescription ?? '',
})

const billingCodeToLineItem = (
  billingCode: BillingCode,
  mapping: BillingCodeToDxPointers,
  includedDiagnosesWithOrder: ClaimFormDiagnosisWithOrder[]
): ClaimFormLineItem => ({
  modifiers: [],
  units: 1,
  unitChargeAmountCents: 0,
  totalAdjustmentAmountCents: 0,
  billingCode: billingCodeToLineItemBillingCode(billingCode),
  dxPointers: getDefaultDxPointers(
    billingCode.id,
    mapping,
    includedDiagnosesWithOrder
  ),
})
/**
 * Merge two line items array
 * @param startingItems
 * @param addOnItems
 * @return Merged line items and overlapped billing codes
 * StartingItems takes priority:
 *  1. If a billing code in addOnItems does not exist in the startingItems, add it to the result.
 *  2. If a billing code in addOnItems already exist in the startingItems, add up the two unit counts for this billing code, and keep other fields in the startingItems
 */
export const mergeLineItems = (
  startingItems: ClaimFormLineItem[],
  addOnItems: ClaimFormLineItem[]
): {
  lineItems: ClaimFormLineItem[]
  duplicatedBillingCodes: string[]
} => {
  if (!startingItems.length || !addOnItems.length) {
    return {
      lineItems: [...startingItems, ...addOnItems],
      duplicatedBillingCodes: [],
    }
  }

  const duplicatedBillingCodes: string[] = []
  const lineItems = [...startingItems]

  addOnItems.forEach((lineItem) => {
    const lineItemWithSameBillingCodeIndex = lineItems.findIndex(
      ({ billingCode: { code } }) => code === lineItem.billingCode.code
    )
    if (lineItemWithSameBillingCodeIndex === -1) {
      lineItems.push(lineItem)
    } else {
      lineItems[lineItemWithSameBillingCodeIndex] = {
        ...lineItems[lineItemWithSameBillingCodeIndex],
        units:
          lineItems[lineItemWithSameBillingCodeIndex].units + lineItem.units,
      }
      duplicatedBillingCodes.push(lineItem.billingCode.code)
    }
  })

  return {
    lineItems,
    duplicatedBillingCodes,
  }
}

export const notifyDuplicatedBillingCodeIfAny = (codes: string[]) => {
  const numOfCodes = codes.length

  if (!numOfCodes) return

  let message = ''
  if (numOfCodes === 1) {
    message = `${codes[0]} is already on the claim. We increased the unit count for you.`
  } else {
    const copy = [...codes]
    const lastItem = copy.pop()
    message = `${copy.join(', ')}${
      numOfCodes > 2 ? ', and ' : ' and '
    }${lastItem} are already on the claim. We increased their unit counts for you.`
  }
  generateNotification(message, NotificationType.SUCCESS)
}

export const addBillingCodeToLineItems = (
  currentLineItems: ClaimFormLineItem[],
  newBillingCode: BillingCode,
  mapping: BillingCodeToDxPointers,
  includedDiagnosesWithOrder: ClaimFormDiagnosisWithOrder[]
): ClaimFormLineItem[] => {
  const { lineItems, duplicatedBillingCodes } = mergeLineItems(
    currentLineItems,
    [billingCodeToLineItem(newBillingCode, mapping, includedDiagnosesWithOrder)]
  )
  notifyDuplicatedBillingCodeIfAny(duplicatedBillingCodes)
  return lineItems
}

const billingTemplateToLineItems = (
  billingTemplate: BillingTemplate,
  mapping: BillingCodeToDxPointers,
  includedDiagnosesWithOrder: ClaimFormDiagnosisWithOrder[]
): ClaimFormLineItem[] =>
  billingTemplate.billingTemplateCodes?.map(
    ({
      modifiers,
      unitChargeAmountCents,
      unit,
      discountAmountCents,
      billingCode,
    }) => ({
      billingTemplateId: billingTemplate.id,
      modifiers: modifiers.filter((m) => !!m),
      unitChargeAmountCents,
      units: unit,
      totalAdjustmentAmountCents: discountAmountCents,
      billingCode: billingCodeToLineItemBillingCode(billingCode as BillingCode),
      dxPointers: getDefaultDxPointers(
        (billingCode as BillingCode).id,
        mapping,
        includedDiagnosesWithOrder
      ),
    })
  ) ?? []

export const addBillingTemplateToLineItems = (
  currentLineItems: ClaimFormLineItem[],
  billingTemplate: BillingTemplate,
  mapping: BillingCodeToDxPointers,
  includedDiagnosesWithOrder: ClaimFormDiagnosisWithOrder[]
): ClaimFormLineItem[] => {
  const lineItemsFromNewTemplate = billingTemplateToLineItems(
    billingTemplate,
    mapping,
    includedDiagnosesWithOrder
  )

  const lineItemsWithoutTemplate = currentLineItems.filter(
    ({ billingTemplateId }) => !billingTemplateId
  )

  const { lineItems, duplicatedBillingCodes } = mergeLineItems(
    lineItemsFromNewTemplate,
    lineItemsWithoutTemplate
  )

  notifyDuplicatedBillingCodeIfAny(duplicatedBillingCodes)
  return lineItems
}

// Product requirement: unlike invoice, disregard the discount (totalAdjustmentAmountCents) when calculating total
export const getLineItemTotalCharge = ({
  units,
  unitChargeAmountCents,
}: ClaimFormLineItem) => units * unitChargeAmountCents

export const getDxPointersDisplay = (dxPointers: number[]) =>
  dxPointers.map((v) => v + 1).join(', ')

// Replace "" with "0" for and join with "," for display/edit
export const toModifiersInputValue = (modifiers: string[]) =>
  modifiers.join(', ')

export const toModifiers = (modifiersInput: string) => {
  const trimmed = modifiersInput.trim()
  return trimmed.length
    ? trimmed
        .split(',')
        .map((m) => m.trim())
        .filter((m) => !!m)
    : []
}

export const validateModifiers = (modifiers: string[]) => {
  if (modifiers.length > MODS_LIMIT) return false
  if (modifiers.some((mod) => !/^[a-zA-Z0-9]{2}$/.test(mod))) {
    return false
  }
  return true
}

export const getTotalInsuranceCharge = (lineItems: ClaimFormLineItem[]) =>
  lineItems.reduce((accu, curr) => accu + getLineItemTotalCharge(curr), 0)
