export enum AggregationMethodEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface GraphPlotProp {
  category: string
  date: string
  value: number
}

export type GraphDataProps = {
  [key in AggregationMethodEnum]: GraphPlotProp[]
}

export enum ReportType {
  NOTES = 'notes',
  APPOINTMENTS = 'appointments',
  SURVEYS = 'surveys',
}

export * from './graphConfigs'
