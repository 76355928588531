import { TimePicker } from 'antd'
import Select, { DefaultOptionType } from 'antd/lib/select'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { useFeatureFlags } from 'libs/featureFlags'
import moment from 'moment/moment'
import { Teammate } from 'shared-types'

import { Link } from '../../../../../stories/BaseComponents'
import InlineEditDate from '../../../../../stories/BaseComponents/InlineEditFields/InlineEditDate'
import { Note, NoteLocation } from '../../../types'
import { getNoteTimeDisplay, getTimezoneAbbreviation } from '../../../utils'
import { CoreData } from './CoreData'

import styles from './NoteCoreData.module.scss'

const APPOINTMENT_DATE_MOMENT_FORMAT = 'MM/DD/YYYY'

type OnChangeType = (val: Partial<Note>) => void

interface AppointmentDatePickerProps {
  appointmentDate: Note['appointmentDate']
  startTime: Note['startTime']
  timezone: Note['timezone']
  onChange: OnChangeType
}

const AppointmentDatePicker = ({
  appointmentDate,
  startTime,
  timezone,
  onChange,
}: AppointmentDatePickerProps) => {
  const handleOnSave = (value: string) => {
    const change: Partial<Note> = {}
    const date = moment(value, APPOINTMENT_DATE_MOMENT_FORMAT)

    change.appointmentDate = date.format('YYYY-MM-DD')

    // If the appointmentDate changes, we also need to modify the 'date'
    // portion of the startTime,
    if (startTime) {
      // Update the date portion of the startTime. Since startTime is in UTC,
      // we need to do the following:
      // 1). Transform startTime into local note time.
      // 2). Perform the date adjustment.
      // 3). Transform back to UTC time.
      //
      // This means that startTime could have a different date than the appointmentDate
      // when startTime is in UTC format. But when startTime is transformed to local timezone
      // of the note, it will always match appointmentDate.

      const localTime = timezone
        ? utcToZonedTime(startTime, timezone)
        : new Date(startTime)

      localTime.setFullYear(date.year(), date.month(), date.date())

      const updatedUtcTime = timezone
        ? zonedTimeToUtc(localTime, timezone)
        : localTime

      change.startTime = updatedUtcTime.toISOString()
    }

    onChange(change)
  }

  return (
    <InlineEditDate
      isLocal
      value={moment(appointmentDate).format(APPOINTMENT_DATE_MOMENT_FORMAT)}
      onSave={handleOnSave}
      padded={true}
      after={moment().add(100, 'year')}
      allowClear={false}
      testId={'appointmentDate'}
    />
  )
}

interface StartTimePickerProps {
  appointmentDate: Note['appointmentDate']
  startTime: Note['startTime']
  timezone: Note['timezone']
  onChange: OnChangeType
}

const StartTimePicker = ({
  appointmentDate,
  startTime,
  timezone,
  onChange,
}: StartTimePickerProps) => {
  const TIME_FORMAT = 'hh:mm A'

  const handleOnChange = (time: moment.Moment | null) => {
    let newStartTime: string | null = null
    if (time) {
      const date = moment(appointmentDate, 'YYYY-MM-DD')
      const originalTime = time.toDate()
      const dateWithTime = new Date(
        date.year(),
        date.month(),
        date.date(),
        originalTime.getHours(),
        originalTime.getMinutes()
      )

      if (timezone) {
        const utcDate = zonedTimeToUtc(dateWithTime, timezone)
        newStartTime = utcDate.toISOString()
      } else {
        newStartTime = dateWithTime.toISOString()
      }
    }

    onChange({
      startTime: newStartTime,
    })
  }

  return (
    <div className={styles.timePickerWrapper}>
      <TimePicker
        className={styles.timePicker}
        defaultValue={
          startTime
            ? moment(
                timezone
                  ? utcToZonedTime(startTime, timezone)
                  : new Date(startTime),
                TIME_FORMAT
              )
            : undefined
        }
        format={TIME_FORMAT}
        onChange={handleOnChange}
        use12Hours
        showSecond={false}
        bordered={false}
        placeholder={'Select'}
      />
      {startTime && (
        // Attempting to show the timezone as part of the TimePicker `format` prop
        // will cause issues when trying to type times into the input field via keyboard.
        // As such, we do the timezone display ourselves overlayed onto the picker input
        // with some additional padding.
        <span
          data-testid={'timepicker-timezone'}
          className={styles.timezoneDisplay}
        >
          {getTimezoneAbbreviation({
            time: startTime,
            timezone: timezone,
          })}
        </span>
      )}
    </div>
  )
}

/**
 * For handling filter/search logic in the Select dropdowns.
 */
const defaultFilterOption = (
  input: string,
  option: DefaultOptionType | undefined
) =>
  (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())

interface NoteCoreDataProps {
  note: Note
  onChange: OnChangeType
  teamOptions: Array<Teammate>
  locationOptions: Array<NoteLocation>
  isLoading: boolean
  isSigned?: boolean
}

export const NoteCoreData = ({
  note,
  onChange,
  teamOptions,
  locationOptions,
  isLoading,
  isSigned = false,
}: NoteCoreDataProps) => {
  const { claims } = useFeatureFlags()
  return (
    <CoreData
      isLoading={isLoading}
      fields={{
        patient: {
          type: 'readonly',
          readonlyValue: note.patientName,
        },
        dateOfVisit: {
          type: isSigned ? 'readonly' : 'dynamic',
          readonlyValue: moment(note.appointmentDate).format(
            APPOINTMENT_DATE_MOMENT_FORMAT
          ),
          dynamicValue: (
            <AppointmentDatePicker
              appointmentDate={note.appointmentDate}
              startTime={note.startTime}
              timezone={note.timezone}
              onChange={onChange}
            />
          ),
        },
        startTime: {
          type: isSigned ? 'readonly' : 'dynamic',
          readonlyValue: note.startTime
            ? getNoteTimeDisplay({
                time: note.startTime,
                timezone: note.timezone ?? null,
              })
            : '',
          dynamicValue: (
            <StartTimePicker
              appointmentDate={note.appointmentDate}
              startTime={note.startTime}
              timezone={note.timezone}
              onChange={onChange}
            />
          ),
        },
        location: {
          type: isSigned ? 'readonly' : 'dynamic',
          readonlyValue: note.locationName,
          dynamicValue:
            locationOptions.length === 0 ? (
              <div>
                No locations found •&nbsp;
                <Link to={'/settings/location'} target="_blank">
                  Add one
                </Link>
              </div>
            ) : (
              <Select<Note['locationId'] | undefined>
                data-testid={'location-select'}
                value={note.locationId ?? undefined}
                options={locationOptions.map((location) => ({
                  value: location.LocationId,
                  label: location.LocationName,
                }))}
                filterOption={defaultFilterOption}
                onChange={(value) => {
                  onChange({
                    locationId: value ? value : null,
                  })
                }}
                className={styles.minWidth}
                showSearch={true}
                allowClear={true}
                bordered={false}
                placeholder={'Select'}
              />
            ),
        },
        renderingProvider: {
          type: isSigned ? 'readonly' : 'dynamic',
          readonlyValue: note.renderingProviderName,
          dynamicValue: (
            <Select<Note['renderingProviderId'] | undefined>
              data-testid={'renderingProvider-select'}
              value={note.renderingProviderId ?? undefined}
              options={teamOptions.map((team) => {
                return {
                  value: team.cognitoId,
                  label: team.name,
                  disabled: team.isDeactivated,
                }
              })}
              filterOption={defaultFilterOption}
              onChange={(value) => {
                onChange({
                  renderingProviderId: value ? value.toString() : null,
                })
              }}
              className={styles.minWidth}
              showSearch={true}
              allowClear={true}
              bordered={false}
              placeholder={'Select'}
            />
          ),
        },
        ...(claims && {
          billingType: {
            type: isSigned ? 'readonly' : 'dynamic',
            readonlyValue: note.billingType ?? '',
            dynamicValue: (
              <Select<Note['billingType'] | undefined>
                data-testid={'billingType-select'}
                value={note.billingType ?? undefined}
                options={[
                  { value: 'In-network', label: 'In-network' },
                  { value: 'Private pay', label: 'Private pay' },
                ]}
                filterOption={defaultFilterOption}
                onChange={(value) => {
                  onChange({
                    billingType: value ?? null,
                  })
                }}
                className={styles.minWidth}
                showSearch={true}
                allowClear={true}
                bordered={false}
                placeholder={'Select'}
              />
            ),
          },
        }),
      }}
    />
  )
}
