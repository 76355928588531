import React, { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import { useChangePayers } from '../../../hooks/usePatientProfile'
import {
  InsuranceHolder,
  PatientInsurance,
} from '../../../hooks/usePatientProfile/shared-types'
import { getInsurancePayerOptions } from '../../../libs/utils'
import { CLAIM_STATUS } from '../../../shared-types'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import {
  handleSecondaryInsuranceUpdate,
  populateSecondaryInsuranceInfo,
} from './InsuranceClaim-helper'
import {
  InsuranceHolderRelationshipEnum,
  InsuranceHolderRelationshipOptions,
  InsuranceSecondary,
  InsuranceSecondaryHolder,
  MEDICARE_CLAIM_FILING_CODES,
  secondaryInsuranceComponents,
} from './constants'

import styles from './InsuranceClaim.module.scss'

export interface SecondaryInsuranceClaimProps {
  loading?: boolean
  testId?: string
  secondaryInsurance: null | PatientInsurance | undefined
  setSecondaryInsurance: Function
  secondaryHolder: null | InsuranceHolder | undefined
  setSecondaryHolder: Function
  includeSecondary: boolean
  setIncludeSecondary: Function
  disabled?: boolean
  claimStatus?: CLAIM_STATUS
}

const Loading = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const SecondaryInsuranceClaim: React.FC<SecondaryInsuranceClaimProps> = ({
  testId,
  loading,
  secondaryInsurance,
  setSecondaryInsurance,
  secondaryHolder,
  setSecondaryHolder,
  includeSecondary,
  setIncludeSecondary,
  disabled = false,
  claimStatus,
}) => {
  const { data: changeList, isFetching: loadingChangeList } = useChangePayers()

  const [secondary, setSecondary] = useState<FieldGroupProps[]>(
    secondaryInsuranceComponents
  )

  useEffect(() => {
    if (loading || loadingChangeList || !changeList) return
    let itemsCopy: any = cloneDeep(secondaryInsuranceComponents)
    if (!secondaryInsurance)
      return setSecondary([{ groupName: 'Secondary insurance', items: [] }])
    // insurance name field logic
    const insuranceNameField = itemsCopy[0].items.find(
      (item: any) => item?.id === InsuranceSecondary.secondaryInsuranceName
    )
    const changeOptions: {
      value: string
      label: string
      disabled?: boolean
    }[] = getInsurancePayerOptions(changeList)
    const orgNameArray: string[] = changeList.map((el) => el.organizationName)
    if (
      secondaryInsurance.insuranceName &&
      !orgNameArray.includes(secondaryInsurance.insuranceName)
    ) {
      changeOptions.unshift({
        value: secondaryInsurance.insuranceName,
        label: secondaryInsurance.insuranceName,
        disabled: true,
      })
      insuranceNameField.isWrongValue = true
    }
    insuranceNameField.options = changeOptions
    // insurance subscriber relationship fields logic
    const nonSelfSubscriberFields = Object.values(
      InsuranceSecondaryHolder
    ).filter((id) => id !== InsuranceSecondaryHolder.secondaryRelationship)
    const selfSubscriberFields = itemsCopy[0].items.filter(
      (item: any) => !nonSelfSubscriberFields.includes(item?.id)
    )
    if (
      secondaryInsurance.isPrimaryInsuranceHolder ||
      !secondaryHolder ||
      !secondaryHolder.relationship
    ) {
      itemsCopy[0].items = selfSubscriberFields
    } else if (
      secondaryHolder &&
      secondaryHolder.relationship &&
      itemsCopy[0].items.length === selfSubscriberFields?.length
    ) {
      itemsCopy = cloneDeep(secondaryInsuranceComponents)
    }
    const relationshipOptions = cloneDeep(InsuranceHolderRelationshipOptions)
    const suscriberRelationshipField = itemsCopy[0].items.find(
      (item: any) => item?.id === InsuranceSecondaryHolder.secondaryRelationship
    )
    if (
      secondaryHolder?.relationship &&
      !Object.values(InsuranceHolderRelationshipEnum).includes(
        secondaryHolder.relationship
      )
    ) {
      suscriberRelationshipField.isWrongValue = true
      relationshipOptions.unshift({
        value: secondaryHolder.relationship,
        label: secondaryHolder.relationship,
        disabled: true,
      })
      suscriberRelationshipField.options = relationshipOptions
    } else {
      suscriberRelationshipField.isWrongValue = false
      suscriberRelationshipField.options = relationshipOptions
    }
    // insurance type field logic
    const isMedicareSecondary = MEDICARE_CLAIM_FILING_CODES.includes(
      secondaryInsurance?.claimFilingCode ?? ''
    )
    const isPresubmitStatus =
      claimStatus &&
      [CLAIM_STATUS.DRAFT, CLAIM_STATUS.CHANGE_ERROR].includes(claimStatus)
    const hasInsuranceTypeCode = !!secondaryInsurance.insuranceTypeCode
    const shouldShowInsuranceTypeCodeField =
      (isMedicareSecondary && isPresubmitStatus) || hasInsuranceTypeCode
    if (!shouldShowInsuranceTypeCodeField) {
      itemsCopy[0].items = itemsCopy[0].items.filter(
        (item: any) =>
          item?.id !== InsuranceSecondary.secondaryInsuranceTypeCode
      )
    }
    populateSecondaryInsuranceInfo(
      itemsCopy,
      secondaryInsurance,
      secondaryHolder
    )
    setSecondary(itemsCopy)
  }, [loading, secondaryInsurance, secondaryHolder, loadingChangeList])

  // To be tested when adding saving tests to components
  /* istanbul ignore next */
  const handleSave = (
    newValue: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    const newVal: any =
      typeof newValue === 'string' ? newValue.trim() : newValue
    const { secondaryInsuranceItem, shouldSave } =
      handleSecondaryInsuranceUpdate(
        newVal,
        id,
        secondaryInsurance,
        secondaryHolder,
        changeList ?? []
      )
    if (shouldSave) {
      setSecondaryInsurance(secondaryInsuranceItem.patientInsurance)
      setSecondaryHolder(secondaryInsuranceItem.insuranceHolder)
    }
  }

  return (
    <SkeletonLoadingTransition
      isLoading={loading || loadingChangeList}
      skeletonComponent={
        <>
          <Loading />
          <Loading />
        </>
      }
      loadedComponent={
        <div className={styles.topMargin}>
          <Fields
            testId={testId}
            items={secondary}
            compact={false}
            handleSave={handleSave}
            isIncluded={includeSecondary}
            setIsIncluded={setIncludeSecondary}
            includedText="Include patient’s secondary insurance on this claim"
            isClaim
            disableAll={disabled}
          />
        </div>
      }
    />
  )
}

export default SecondaryInsuranceClaim
