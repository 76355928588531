import React, { useMemo } from 'react'

import { useFormikContext } from 'formik'

import { PracticeDataType } from '../../../../hooks/usePatientProfile/shared-types'
import { Card } from '../../../../stories/BaseComponents'
import InlineField from '../InlineField'
import { sectionContainerBodyStyle, sectionContainerStyle } from '../constants'
import { useLocations, useNotes, usePosCodes, useProviders } from '../hooks'
import { ClaimForm, IsReadOnlyModeProp } from '../types'
import { getNewValues, getPopulatedFields } from './appointment-helper'

import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-appointment',
}
type AppointmentProps = {
  patientId: string
  practiceData: Omit<PracticeDataType, 'CreatedAt'>
} & IsReadOnlyModeProp
const Appointment: React.FC<AppointmentProps> = ({
  patientId,
  practiceData,
  readOnly,
}) => {
  const {
    data: { noteOptions, notesById },
  } = useNotes(patientId)
  const {
    data: { posOptions },
  } = usePosCodes()
  const {
    data: { locationOptions, locationsByLocationId },
  } = useLocations()

  const {
    data: { providersById },
  } = useProviders()

  const { values, setValues } = useFormikContext<ClaimForm>()

  const populatedFields = useMemo(
    () => getPopulatedFields(noteOptions, locationOptions, posOptions),
    [noteOptions, locationOptions, posOptions]
  )

  const handleChange = (name: string, val: string | null) => {
    const newValues = getNewValues(
      values,
      name,
      val,
      notesById,
      locationsByLocationId,
      practiceData,
      providersById
    )
    setValues(newValues)
  }

  return (
    <Card
      testId={testIds.container}
      style={sectionContainerStyle}
      bodyStyle={sectionContainerBodyStyle}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>{'Appointment information'}</span>
        </div>
        <div>
          {populatedFields.map((el) => (
            <InlineField
              {...el}
              key={el.name}
              onChange={handleChange}
              disabled={readOnly}
            />
          ))}
        </div>
      </div>
    </Card>
  )
}

export default Appointment
