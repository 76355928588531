import React from 'react'

import NationalDrugCode from 'containers/Patient/Claims/NationalDrugCode'
import { useFormikContext } from 'formik'

import { ClaimForm, IsReadOnlyModeProp, NdcItem } from '../types'

/**
 * A simple wrapper to get the form context values, this way we can share the same NationalDrugCode component with V1.
 * Pull NationalDrugCode into claimsV2 directory when V1 goes away.
 */
const NationalDrugCodeTable: React.FC<IsReadOnlyModeProp> = ({ readOnly }) => {
  const {
    values: { lineItems, ndcs },
    setFieldValue,
  } = useFormikContext<ClaimForm>()

  const handleChange = (items: NdcItem[]) => {
    setFieldValue('ndcs', items)
  }

  return (
    <NationalDrugCode
      lineItems={lineItems}
      ndcItems={ndcs}
      onNdcItemsChange={handleChange}
      isEditingDisabled={readOnly}
    />
  )
}

export default NationalDrugCodeTable
