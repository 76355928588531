import { parse } from 'qs'
import { useLocation } from 'react-router-dom'

export const useQueryParams = <T>() => {
  const location = useLocation()
  const params = parse(location.search as string, {
    ignoreQueryPrefix: true,
  }) as Partial<T>

  return params
}
