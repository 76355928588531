import { ReactNode, useMemo, useState } from 'react'

import { ExclamationCircleFilled } from '@ant-design/icons'
import { Table, Tooltip } from 'antd'
import { FixedType } from 'rc-table/lib/interface'
import { useHistory } from 'react-router-dom'

import {
  convertCentsToDollars,
  filterSuccessful,
  formatNumberStringToUsdString,
  isFullyRefunded,
  totalRefund,
} from '../../../libs/billing'
import { REFUND_MODAL_MODE } from '../../../libs/constants'
import { getCreatedAtContent, handlePaymentsSort } from '../../../libs/utils'
import {
  BillableEntityPayment,
  InsuranceClaim,
  Invoice,
  PaymentAttempt,
  PaymentStatus,
  RefundAttempt,
} from '../../../shared-types'
import { Tag } from '../../../stories/BaseComponents'
import { PaymentTableItem } from '../../../stories/BaseComponents/tableData.types'
import { buildClaimRoute } from '../ClaimsV2/utils'
import { getPaymentMethodDisplayContent } from './Billing'
import { RefundModal } from './RefundModal'

import themeStyles from '../../../_theme.module.scss'
import styles from './Billing.module.scss'

interface TransactionsTableProps {
  payments: PaymentAttempt[]
  refunds: RefundAttempt[]
  patientId: string
}

export const getStatusContent = (transaction: PaymentAttempt) => {
  if (isFullyRefunded(transaction)) {
    return <Tag color="default">Refunded</Tag>
  } else if (totalRefund(transaction) > 0) {
    return <Tag color="default">Partial refund</Tag>
  }

  switch (transaction.status) {
    case PaymentStatus.SUCCESSFUL:
      return <Tag color="green">Success</Tag>
    case PaymentStatus.FAILED:
      return transaction?.errorMessage?.length ? (
        <Tooltip title={transaction.errorMessage} placement="topLeft">
          <Tag color={themeStyles.red7}>
            <ExclamationCircleFilled /> Failed
          </Tag>
        </Tooltip>
      ) : (
        <Tag color={themeStyles.red7}>
          <ExclamationCircleFilled /> Failed
        </Tag>
      )
    case PaymentStatus.PENDING:
      return <Tag color="blue">Pending</Tag>
    case PaymentStatus.DISPUTE_LOST:
      // The patient "lost" the dispute, so from the provider's perspective it's a positive outcome and
      // therefore the color is green.
      return <Tag color="green">Dispute lost</Tag>
    case PaymentStatus.DISPUTE_WON:
      // The patient "won" the dispute, so from the provider's perspective it's a negative outcome and
      // therefore the color is red.
      return <Tag color="red">Dispute won</Tag>
    case PaymentStatus.ACTIVE_DISPUTE:
      return <Tag color="gold">Active dispute</Tag>
    case PaymentStatus.CANCELLED:
      return <Tag color="default">Canceled</Tag>
    default:
      return null
  }
}

export default function TransactionsTable({
  payments,
  refunds,
  patientId,
}: TransactionsTableProps) {
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)
  const [paymentToRefund, setPaymentToRefund] = useState<any>()
  const [invoicesForPaymentToRefund, setInvoicesForPaymentToRefund] = useState<
    Invoice[]
  >([])
  const history = useHistory()
  const [claimsForPaymentToRefund, setClaimsForPaymentToRefund] = useState<
    InsuranceClaim[]
  >([])

  /**
   * Generate content based on Invoice or Insurance Numbers
   * @param {Array<BillableEntityPayment>} attributions - An array of billable entity payments
   * @returns {ReactNode | null} - A ReactNode to be rendered or null if no data
   */
  const getAppliedToContent = (
    attributions: Array<BillableEntityPayment>,
    providerId: string
  ): ReactNode | null => {
    if (!Array.isArray(attributions) || attributions.length === 0) return null

    /**
     * Generate a clickable link
     * @param {string} prefix - Prefix to prepend before the label
     * @param {string} label - Text to display for the link
     * @param {string} path - URL path to navigate to
     * @param {Record<string, any>} extraInfo - Extra information to pass to the URL
     * @returns {ReactNode} - A ReactNode link element
     */
    const generateLink = (
      prefix: string,
      label: string,
      path: string,
      extraInfo: Record<string, any>
    ): ReactNode => (
      <a
        data-testid={`link-${label}`}
        className={styles.link}
        onClick={() => history.push(path, extraInfo)}
        onKeyDown={() => history.push(path, extraInfo)}
      >
        {prefix ? `${prefix} ${label}` : label}
      </a>
    )

    const invoiceNumbers: Set<ReactNode> = new Set()
    const insuranceNumbers: Set<ReactNode> = new Set()
    const existingAttributionId = new Set()
    attributions.forEach((attribution) => {
      if (
        existingAttributionId.has(attribution.invoiceUuid) ||
        existingAttributionId.has(attribution.insuranceClaimUuid)
      )
        return
      if (attribution?.invoiceUuid) {
        const path = `/invoice?patientId=${patientId}&invoiceUuid=${attribution.invoiceUuid}`
        const label = `#${(attribution.invoice as Invoice).invoiceNumber}`
        const prefix = invoiceNumbers.size === 0 ? 'Invoice' : ''
        existingAttributionId.add(attribution.invoiceUuid)
        invoiceNumbers.add(generateLink(prefix, label, path, {}))
      } else if (attribution?.insuranceClaimUuid) {
        const path = buildClaimRoute({
          claimId: attribution.insuranceClaimUuid,
          patientId,
          providerId,
        })
        const label = `#${attribution.insuranceClaim?.patientControlNumber}`
        const prefix = insuranceNumbers.size === 0 ? 'Claim' : ''
        existingAttributionId.add(attribution.insuranceClaimUuid)
        insuranceNumbers.add(generateLink(prefix, label, path, {}))
      }
    })

    /**
     * Render a set of ReactNodes
     * @param {Set<ReactNode>} set - The set of ReactNodes to render
     * @returns {ReactNode} - A ReactNode containing the rendered set
     */
    const renderSet = (set: Set<ReactNode>): ReactNode => (
      <span>
        {Array.from(set).reduce(
          (prev, curr, index) => [prev, index > 0 && ', ', curr],
          []
        )}
      </span>
    )

    if (invoiceNumbers.size > 0) {
      return renderSet(invoiceNumbers)
    }

    if (insuranceNumbers.size > 0) {
      return renderSet(insuranceNumbers)
    }

    return null
  }

  const handleCloseRefundModal = () => {
    setIsRefundModalOpen(false)
    setPaymentToRefund(null)
    setInvoicesForPaymentToRefund([])
  }

  const handleRefundClick = (payment: PaymentAttempt) => {
    setPaymentToRefund(payment)

    const invoicesToUse = [] as Array<Invoice>
    const claimsToUse = [] as Array<InsuranceClaim>
    if (payment?.billableEntityPayments) {
      for (const bep of payment.billableEntityPayments) {
        if (bep.invoice) {
          invoicesToUse.push(bep.invoice)
        }

        if (bep.insuranceClaim) {
          claimsToUse.push(bep.insuranceClaim)
        }
      }
    }

    setClaimsForPaymentToRefund(claimsToUse)
    setInvoicesForPaymentToRefund(invoicesToUse)
    setIsRefundModalOpen(true)
  }

  const getRefundActionsContent = (payment: PaymentAttempt) => {
    //Disallow refunds for unsuccessful payments.
    if (payment.status !== PaymentStatus.SUCCESSFUL) {
      return null
    }
    const totalRefundedCents = payment.refundAttempts
      ?.filter(filterSuccessful)
      .reduce((acc, currentRefund) => {
        return acc + currentRefund.totalAmountCents
      }, 0)
    // Do not allow a refund for payments that have refunds associated with them
    // that add up to the original payment value
    if (totalRefundedCents >= payment.totalAmountCents) {
      return null
    }
    return (
      <a
        className={styles.paymentActionLink}
        data-testid={`refund-${payment.uuid}`}
        onClick={() => handleRefundClick(payment)}
        onKeyDown={() => handleRefundClick(payment)}
      >
        Refund
      </a>
    )
  }

  const getTotalAvailableCreditForPayment = (payment: PaymentAttempt) => {
    if (payment.status !== PaymentStatus.SUCCESSFUL) return null
    let totalAvailableCredit = payment.amountCentsUnapplied
    refunds
      .filter(
        (refund) =>
          refund.paymentAttempt.uuid === payment.uuid &&
          refund.status === PaymentStatus.SUCCESSFUL
      )
      .forEach((refund) => {
        // refund.amountCentsUnapplied is a positive int
        // it is the amount cents from the refund that has not been attributed to an invoice by way of a BillableEntityPayment
        totalAvailableCredit -= refund.amountCentsUnapplied
      })
    return totalAvailableCredit
  }

  const sortedPayments = useMemo(
    () => payments.sort(handlePaymentsSort),
    [payments]
  )

  const formatTransactionsAsDataSource = () => {
    const transactionsAsDataSource = sortedPayments.map((payment, idx) => ({
      key: idx.toString(),
      payment,
    }))

    return transactionsAsDataSource
  }

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'left' as FixedType,
      width: 100,
      render: (_text: string, record: PaymentTableItem) => {
        return getStatusContent(record.payment)
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      sorter: (a: PaymentTableItem, b: PaymentTableItem) => {
        return (
          new Date(a.payment.createdAt).getTime() -
          new Date(b.payment.createdAt).getTime()
        )
      },
      render: (_text: string, record: PaymentTableItem) => (
        <span>{getCreatedAtContent(record.payment.createdAt.toString())}</span>
      ),
    },
    {
      title: 'Applied to',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 100,
      render: (_text: string, record: PaymentTableItem) => {
        return getAppliedToContent(
          record.payment.billableEntityPayments,
          record.payment.actingUserId
        )
      },
    },
    {
      title: 'Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      width: 200,
      render: (_text: string, record: PaymentTableItem) => {
        return getPaymentMethodDisplayContent(record.payment.paymentMethod)
      },
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo',
      width: 150,
      render: (_memo: any, paymentRecord: PaymentTableItem) => {
        const sortedRefunds = paymentRecord.payment.refundAttempts.sort(
          (a, b) => {
            return a.createdAt < b.createdAt ? 1 : -1
          }
        )
        if (sortedRefunds.length) {
          return sortedRefunds[0].memo
        } else {
          return paymentRecord.payment.memo
        }
      },
    },
    {
      title: 'Refund amount',
      dataIndex: 'refundAmount',
      key: 'refundAmount',
      width: 150,
      sorter: (a: PaymentTableItem, b: PaymentTableItem) => {
        return totalRefund(a.payment) - totalRefund(b.payment)
      },
      render: (_text: string, record: PaymentTableItem) => {
        const refundAmount = totalRefund(record.payment)
        if (!refundAmount) return null
        return (
          <span className={styles.negative}>
            -
            {formatNumberStringToUsdString(convertCentsToDollars(refundAmount))}
          </span>
        )
      },
    },
    {
      title: 'Payment amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      width: 150,
      sorter: (a: PaymentTableItem, b: PaymentTableItem) => {
        return a.payment.totalAmountCents - b.payment.totalAmountCents
      },
      render: (_text: string, record: PaymentTableItem) => {
        return (
          <span>
            {formatNumberStringToUsdString(
              convertCentsToDollars(record.payment.totalAmountCents)
            )}
          </span>
        )
      },
    },
    {
      title: 'Available credit',
      dataIndex: 'availableCredit',
      key: 'availableCredit',
      width: 150,
      sorter: (a: PaymentTableItem, b: PaymentTableItem) => {
        const creditA = getTotalAvailableCreditForPayment(a.payment)
        const creditB = getTotalAvailableCreditForPayment(b.payment)

        // need to use actual equality with null here, as $0 will be evaluated same as null
        if (creditA === null && creditB === null) return 0
        if (creditA === null) return -1
        if (creditB === null) return 1
        return creditA - creditB
      },
      render: (_text: string, record: PaymentTableItem) => {
        const availableCredit = getTotalAvailableCreditForPayment(
          record.payment
        )
        return (
          <span>
            {availableCredit !== null
              ? formatNumberStringToUsdString(
                  convertCentsToDollars(availableCredit)
                )
              : '--'}
          </span>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right' as FixedType,
      width: 100,
      render: (_text: string, record: PaymentTableItem) => {
        return getRefundActionsContent(record.payment)
      },
    },
  ]

  return (
    <>
      <RefundModal
        isOpen={isRefundModalOpen}
        onClose={handleCloseRefundModal}
        payment={paymentToRefund}
        paymentMethod={paymentToRefund?.paymentMethod}
        patientId={patientId}
        invoices={invoicesForPaymentToRefund}
        insuranceClaims={claimsForPaymentToRefund}
        mode={REFUND_MODAL_MODE.PAYMENT}
      />
      <Table
        size="middle"
        scroll={{ x: 1200 }}
        columns={columns}
        dataSource={formatTransactionsAsDataSource()}
      />
    </>
  )
}
