import React from 'react'

import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { format, toDate } from 'date-fns'
import { CSVLink } from 'react-csv'

import { prettifyCamelCase } from '../../libs/utils'
import { TestId } from '../../shared-types'
import { CSVTableDataTransformer, CSVTableDataTypes } from './tableData.types'

import './_shared.scss'

export interface CSVTableHeader {
  readonly key: string // Key to the value in the data object
  readonly label: string // Label to display at top of CSV
}

export type CSVExportButtonProps = {
  headers: CSVTableHeader[]
  data: CSVTableDataTypes[]
  dataTransformer?: CSVTableDataTransformer
  tableName: string
  loading: boolean
  clickHandler?: () => void
} & TestId

export function removeDangerousCSVCharacters(
  data: Partial<CSVTableDataTypes & { date?: string; time?: string }>[]
) {
  return data.map((record) => {
    for (const [key, value] of Object.entries(record)) {
      // Don't modify negative numbers if they have only digits or periods
      if (String(value).match(/^-(\d|\.)+$/)) {
        continue
      }
      // Replace all leading bad characters including spaces which can
      // obscure a second bad character but will be stripped by most
      // spreadsheet software.
      record[key as keyof typeof record] = String(value).replace(
        /^([@+=-]|\s)+/,
        ''
      )
    }
    return record
  })
}

const CSVExportButton: React.FC<CSVExportButtonProps> = ({
  data,
  dataTransformer,
  headers,
  tableName,
  clickHandler,
  loading,
  testId,
}) => {
  const outputFileName = `${tableName}.csv`

  const displayedTableHeaders: CSVTableHeader[] = headers.map((columnKey) => ({
    key: columnKey.key,
    label: prettifyCamelCase(columnKey.label, 'ALL_WORDS'),
  }))

  const DATE_FORMAT = 'MM/dd/yyyy'
  const TIME_FORMAT = 'hh:mm a'

  const transformedData = dataTransformer ? data.map(dataTransformer) : data
  const displayData = removeDangerousCSVCharacters(
    transformedData.map((record: CSVTableDataTypes & { date?: Date }) => ({
      ...record,
      date: record.date ? format(toDate(record.date), DATE_FORMAT) : '',
      time: record.date ? format(toDate(record.date), TIME_FORMAT) : '',
    }))
  )

  const onClick = () => {
    if (clickHandler) {
      clickHandler()
    }
  }

  return (
    <Button
      disabled={loading}
      type="default"
      className="csv-download-btn"
      data-testid={testId}
    >
      <CSVLink
        filename={outputFileName}
        headers={displayedTableHeaders}
        data={displayData}
        onClick={onClick}
        className="csv-download-btn-content"
      >
        <DownloadOutlined />
        Download
      </CSVLink>
    </Button>
  )
}

export default CSVExportButton
