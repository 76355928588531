import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import cn from 'classnames'

import { getPersonalSettings } from '../../api/api-lib'
import {
  getPermissionCookie,
  grantRole,
  revokeRole,
} from '../../api/permissions'
import { PersonalSettings } from '../../containers/Provider/SettingsPersonal'
import { useHasPermissions } from '../../hooks/useHasPermissions'
import { useAppContext } from '../../libs/contextLib'
import { onError } from '../../libs/errorLib'
import { useFeatureFlags } from '../../libs/featureFlags'
import { Teammate } from '../../shared-types'
import { formatPhoneNumber } from '../../shared/Helpers/utils'
import Card from '../BaseComponents/Card'
import Checkbox from '../BaseComponents/Checkbox'
import Divider from '../BaseComponents/Divider'
import Tooltip from '../BaseComponents/Tooltip'
import { READ_PERMISSIONS, WRITE_PERMISSIONS } from './_constants'

import styles from './TeamManagementDetailsPanel.module.scss'

export interface TeamManagementDetailsPanelProps {
  storyBook: boolean
  teammate: Teammate
  advancemdEnabledOnClinic: boolean
  closeSlider: Function
  setTeammate: Function
  canSeeAllPatientsRoleId: number | undefined
  refreshTeammates: Function
}

const TeamManagementDetailsPanel: React.FC<TeamManagementDetailsPanelProps> = ({
  storyBook,
  teammate,
  advancemdEnabledOnClinic,
  closeSlider,
  canSeeAllPatientsRoleId,
  refreshTeammates,
}) => {
  const { appSettings } = useAppContext()
  const [personalSettings, setPersonalSettings] = useState<PersonalSettings>()

  const { labs } = useFeatureFlags()

  const { refetch } = useQuery(
    ['userPermissions'],
    getPermissionCookie, // 5 minutes
    { staleTime: 60000 * 5 }
  )

  useEffect(() => {
    async function getSettingsFromAPI() {
      try {
        const settings = await getPersonalSettings(teammate.cognitoId)
        setPersonalSettings(settings)
      } catch (err) {
        console.error('Error when fetching PersonalSettings', err)
      }
    }
    if (teammate) {
      getSettingsFromAPI()
    }
  }, [teammate])

  const canSeeAllPatients = useCallback(() => {
    return teammate.roles?.canSeeAllPatients === READ_PERMISSIONS
  }, [teammate.roles])

  const [cliniciansOwnPatientsSetting, setCliniciansOwnPatientsSetting] =
    useState<boolean>(!canSeeAllPatients())

  useEffect(() => {
    setCliniciansOwnPatientsSetting(!canSeeAllPatients())
  }, [canSeeAllPatients(), teammate])

  const enabledDisabledErxString = useMemo(() => {
    if (teammate.erxUserId) {
      if (appSettings.erx || storyBook) {
        return 'Enabled'
      }
    }

    return 'Disabled'
  }, [teammate.erxUserId, appSettings.erx, storyBook])

  const handleAmdStyling = useCallback(() => {
    return `${styles.dot} ${teammate.amdProfileId ? styles.green : styles.gray}`
  }, [teammate.amdProfileId])

  const enabledDisabledAmdString = useCallback(() => {
    return teammate.amdProfileId ? 'Enabled' : 'Disabled'
  }, [teammate.amdProfileId])

  async function updateSeeOnlyPatientSetting(checkedValue: boolean) {
    if (storyBook) {
      return
    }
    if (canSeeAllPatientsRoleId) {
      const body = {
        roleIds: [canSeeAllPatientsRoleId],
        providerId: teammate.cognitoId,
      }
      setCliniciansOwnPatientsSetting(checkedValue)
      try {
        checkedValue ? await revokeRole(body) : await grantRole(body)
      } catch (e) {
        onError(
          e,
          500,
          'There was an internal error processing your request. Please inform your administrator.'
        )
      }
      refreshTeammates()

      refetch()
    } else {
      console.error('roleId for viewAllPatient needs to be defiend')
    }
  }
  const hasAddSuperAdminRolePermissions = useCallback(() => {
    return teammate?.roles?.adminUsers === WRITE_PERMISSIONS
  }, [teammate])

  const hasAddAdminRolePermissions = useCallback(() => {
    return (
      teammate?.roles?.adminUsers === WRITE_PERMISSIONS ||
      teammate?.roles?.nonAdminUsers === WRITE_PERMISSIONS
    )
  }, [teammate])

  const hasRenderServicesPermissions = useCallback(() => {
    return teammate.roles?.renderServices === WRITE_PERMISSIONS
  }, [teammate])

  const hasLabsServicesPermissions = useCallback(() => {
    return teammate.roles?.labsServices === WRITE_PERMISSIONS
  }, [teammate])

  const isErxEnabled = useMemo(() => {
    if (!teammate.erxUserId) {
      return false
    }
    return appSettings.erx || storyBook
  }, [storyBook, teammate.erxUserId, appSettings.erx])

  return (
    <div id={styles.detailsPanel} className="detailsContainer">
      <Card
        id={styles.detailsPanelCard}
        bodyStyle={{ padding: '0' }}
        data-id="details"
        bordered={true}
      >
        <div id={styles.topDiv} className={styles.detailsContainer}>
          <CloseOutlined
            id={styles.closeButton}
            onClick={() => {
              closeSlider(false)
            }}
          />
          <div id={styles.name}>{teammate.name}</div>
          <div>{teammate.email}</div>
        </div>
        <Divider
          orientationMargin="0"
          style={{ marginTop: '15px', marginBottom: '5px' }}
        />
        <div id={styles.midDiv} className={styles.detailsContainer}>
          <div className={styles.columns}>
            <span>Phone Number:</span>
            <span>Credential:</span>
            <span>NPI:</span>
            <span>eRx:</span>
            {advancemdEnabledOnClinic ? <span>Advanced MD:</span> : null}
          </div>
          <div className={styles.columns} id={styles.detailValues}>
            <span id={styles.phoneNumber}>
              {formatPhoneNumber(personalSettings?.ProviderPhone)}
            </span>
            <span>{personalSettings?.ProviderCredential}</span>
            <span>{personalSettings?.ProviderNpiNumber}</span>
            <Tooltip
              placement="top"
              overlayStyle={{ whiteSpace: 'pre-line' }}
              zIndex={100000}
              title={`Please reach out to our support team if you are interested in ${
                isErxEnabled ? 'removing' : 'adding'
              } eRx.`}
            >
              <span
                className={cn(styles.dot, {
                  [styles.green]: teammate.erxUserId,
                  [styles.gray]: !teammate.erxUserId,
                })}
              />
              {enabledDisabledErxString}
            </Tooltip>
            {advancemdEnabledOnClinic ? (
              <span>
                <span className={handleAmdStyling()}></span>
                {enabledDisabledAmdString()}
              </span>
            ) : null}
          </div>
        </div>
        <Divider orientationMargin="0" />
        <div id={styles.botDiv} className={styles.detailsContainer}>
          <Tooltip
            placement="top"
            overlayStyle={{ whiteSpace: 'pre-line' }}
            zIndex={100000}
            title="When this box is checked this clinician will only see patients that they are part of the care team for."
          >
            <Checkbox
              key="clinicians-own-patients-setting"
              checked={cliniciansOwnPatientsSetting}
              onChange={(e) => {
                updateSeeOnlyPatientSetting(e.target.checked)
              }}
              disabled={
                !useHasPermissions({
                  adminUsers: 'WRITE',
                  nonAdminUsers: 'WRITE',
                })
              }
            >
              Show clinician's patients only
            </Checkbox>
          </Tooltip>
        </div>
        <Divider orientationMargin="0" />

        <div className={`${styles.mainPermissionsDiv}`}>
          <div
            id={styles.permissionsDiv}
            className={`${styles.permissionsDiv}`}
          >
            Permissions
          </div>
          <div className={`${styles.permissionsAllDiv}`}>
            {hasAddSuperAdminRolePermissions() ? (
              <CheckOutlined
                className={`${styles.checkPermission} ${styles.checkColorPermission}`}
              />
            ) : (
              <CloseOutlined
                className={`${styles.checkPermission} ${styles.crossColorPermission}`}
              />
            )}
            <span>Add or edit all user roles</span>
          </div>
          <div className={`${styles.permissionsAllDiv}`}>
            {hasAddAdminRolePermissions() ? (
              <CheckOutlined
                className={`${styles.checkPermission} ${styles.checkColorPermission}`}
              />
            ) : (
              <CloseOutlined
                className={`${styles.checkPermission} ${styles.crossColorPermission}`}
              />
            )}
            <span>Add or edit admin and non-admin user roles</span>
          </div>
          <div className={`${styles.permissionsAllDiv}`}>
            <CheckOutlined
              className={`${styles.checkPermission} ${styles.checkColorPermission}`}
            />
            <span>Sign notes</span>
          </div>
          <div className={`${styles.permissionsAllDiv}`}>
            {hasRenderServicesPermissions() ? (
              <CheckOutlined
                className={`${styles.checkPermission} ${styles.checkColorPermission}`}
              />
            ) : (
              <CloseOutlined
                className={`${styles.checkPermission} ${styles.crossColorPermission}`}
              />
            )}
            <span>Render services</span>
          </div>
          {labs && (
            <div className={`${styles.permissionsAllDiv}`}>
              {hasLabsServicesPermissions() ? (
                <CheckOutlined
                  className={`${styles.checkPermission} ${styles.checkColorPermission}`}
                />
              ) : (
                <CloseOutlined
                  className={`${styles.checkPermission} ${styles.crossColorPermission}`}
                />
              )}
              <span>Labs services</span>
            </div>
          )}
        </div>
        <div className={`${styles.endOfPermissionsFlex}`}>
          <span>
            {'To learn more about roles and permissions, '}
            <a
              className={`${styles.checkPermission}`}
              href={
                'https://support.osmind.org/en/articles/6446843-user-roles-permissions'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              read the support article.
            </a>
          </span>
        </div>
      </Card>
    </div>
  )
}

export default TeamManagementDetailsPanel
