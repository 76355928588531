import React, { useMemo } from 'react'

import { ColumnsType } from 'antd/lib/table'
import { useFormikContext } from 'formik'
import {
  centsToUsdString,
  convertCentsToDollarsNumber,
  convertDollarsToCents,
  getBillingCodeDisplayName,
} from 'libs/billing'
import { DX_POINTER_LIMIT, PLACEHOLDER } from 'libs/constants'
import {
  Button,
  InputNumber,
  Option,
  Table,
  USDInput,
} from 'stories/BaseComponents'
import {
  EditableInput,
  EditableSelect,
} from 'stories/BaseComponents/EditableInputs'

import { ClaimForm, ClaimFormLineItem, IsReadOnlyModeProp } from '../types'
import { getDxPointerOptions } from '../utils'
import {
  getDxPointersDisplay,
  getLineItemTotalCharge,
  getTotalInsuranceCharge,
  toModifiers,
  toModifiersInputValue,
  validateModifiers,
} from './Procedures-helper'

import lineItemsTableStyles from './LineItemsTable.module.scss'
import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-line-items-container',
  table: 'claim-line-items-table',
  row: 'claim-line-items-row',
  insuranceCharge: 'claim-line-items-insurance-charge',
}

const Label: React.FC<{ text: string; required?: boolean }> = ({
  text,
  required = false,
}) => (
  <>
    {text}
    {required ? <span className={styles.requiredSymbol}>{'*'}</span> : null}
  </>
)
type LineItemsTableProps = {
  loading?: boolean
} & IsReadOnlyModeProp
const LineItemsTable: React.FC<LineItemsTableProps> = ({
  loading,
  readOnly,
}) => {
  const {
    values: { lineItems, diagnoses },
    setFieldValue,
  } = useFormikContext<ClaimForm>()

  const handleDeleteLineItem = (billingCodeId: number) => {
    const newLineItems = lineItems.filter(
      ({ billingCode: { id } }) => id !== billingCodeId
    )
    setFieldValue('lineItems', newLineItems)
  }

  const handleUpdateLineItem = (
    billingCodeId: number,
    key: keyof ClaimFormLineItem,
    value: any
  ) => {
    const newLineItems = lineItems.map((el) => {
      if (el.billingCode.id === billingCodeId) {
        return { ...el, [key]: value }
      } else {
        return el
      }
    })
    setFieldValue('lineItems', newLineItems)
  }

  const dxPointerOptions = useMemo(
    () => getDxPointerOptions(diagnoses),
    [diagnoses]
  )

  const columns: ColumnsType<ClaimFormLineItem> = [
    {
      title: <Label text="Code" required />,
      dataIndex: 'name',
      fixed: 'left',
      width: 240,
      render: (_, { billingCode }) => (
        <span data-testid={`${testIds.row}-${billingCode.id}-name`}>
          {getBillingCodeDisplayName(billingCode)}
        </span>
      ),
    },
    {
      title: <Label text="Mods" />,
      dataIndex: 'modifiers',
      width: 200,
      render: (_, { modifiers, billingCode: { id } }) => {
        return (
          <EditableInput
            value={toModifiersInputValue(modifiers)}
            placeholder={'0, 0, 0, 0'}
            validation={{
              validate: (value) => validateModifiers(toModifiers(value)),
              errorMessage:
                'Modifiers should be 2 digit alphanumeric characters. Each billing code can have up to 4 modifiers.',
            }}
            onChange={(value) => {
              handleUpdateLineItem(id, 'modifiers', toModifiers(value))
            }}
            testId={`${testIds.row}-${id}-modifiers`}
            disabled={readOnly}
          />
        )
      },
    },
    {
      title: <Label text="Dx pointers" required />,
      dataIndex: 'dxPointers',
      width: 200,
      render: (_, { dxPointers, billingCode: { id } }) => (
        <EditableSelect<number[]>
          placeholder={PLACEHOLDER}
          shouldHighlight={!dxPointers.length}
          label={getDxPointersDisplay(dxPointers)}
          value={dxPointers}
          options={dxPointerOptions.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}{' '}
            </Option>
          ))}
          onChange={(pointers) => {
            handleUpdateLineItem(
              id,
              'dxPointers',
              pointers.slice(0, DX_POINTER_LIMIT).sort((a, b) => a - b)
            )
          }}
          mode="multiple"
          limit={DX_POINTER_LIMIT}
          testId={`${testIds.row}-${id}-mdxPointers`}
          disabled={readOnly}
        />
      ),
    },
    {
      title: <Label text="Units" required />,
      dataIndex: 'units',
      width: 120,
      render: (_, { units, billingCode: { id } }) => (
        <InputNumber
          value={units}
          onChange={(value) => {
            // only integer
            handleUpdateLineItem(id, 'units', Math.floor(value as number))
          }}
          step={1}
          min={0}
          testId={`${testIds.row}-${id}-units`}
          disabled={readOnly}
        />
      ),
    },
    {
      title: <Label text="Unit charge" required />,
      dataIndex: 'unitChargeAmountCents',
      width: 240,
      render: (_, { unitChargeAmountCents, billingCode: { id, code } }) => (
        <USDInput
          value={convertCentsToDollarsNumber(unitChargeAmountCents)}
          onChange={(value) => {
            handleUpdateLineItem(
              id,
              'unitChargeAmountCents',
              convertDollarsToCents(value)
            )
          }}
          testId={`${testIds.row}-${code.replace(
            /\s/g,
            '-'
          )}-unitChargeAmountCents`}
          disabled={readOnly}
        />
      ),
    },
    {
      title: <Label text="Total" required />,
      dataIndex: 'total',
      width: 120,
      render: (_, item) => (
        <span data-testid={`${testIds.row}-${item.billingCode.id}-total`}>
          {centsToUsdString(getLineItemTotalCharge(item))}
        </span>
      ),
    },
    {
      title: <Label text="Actions" />,
      dataIndex: 'actions',
      width: 80,
      render: (_, { billingCode: { id } }) => (
        <Button
          testId={`${testIds.row}-${id}-delete-button`}
          type="link"
          danger
          onClick={() => handleDeleteLineItem(id)}
          style={{ paddingLeft: 0 }}
          disabled={readOnly}
        >
          Delete
        </Button>
      ),
    },
  ]
  return (
    <div data-testid={testIds.container}>
      <Table
        showBoxContainer={false}
        testId={testIds.table}
        dataSource={lineItems}
        pagination={false}
        columns={columns}
        noToolsSection
        scroll={{ x: true }}
        loading={loading}
      />
      <div
        className={lineItemsTableStyles.totalCharge}
        data-testid={testIds.insuranceCharge}
      >
        <span>Billing to insurance</span>
        <span>{centsToUsdString(getTotalInsuranceCharge(lineItems))}</span>
      </div>
    </div>
  )
}

export default LineItemsTable
