import React, { useEffect, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'

import { CLAIM_STATUS, Teammate } from '../../../shared-types'
import {
  Button,
  Col,
  Option,
  Row,
  Select,
  Text,
} from '../../../stories/BaseComponents'
import SignaturePreview, {
  FontOptions,
} from '../../../stories/BaseComponents/SignaturePreview'

import styles from './InsuranceClaim.module.scss'

export interface ClaimSignatureRelationProps {
  teammates: Teammate[]
  signingProvider: string | null
  setSigningProvider: Function
  teamLoading: boolean
  handleDiscard: Function
  handleSaveAsDraft: Function
  handleSubmitClaim: Function
  saveLoading: boolean
  claimStatus: CLAIM_STATUS
  disabled?: boolean
  saveSubmitLoading: boolean
  deletionDisabled?: boolean
}

const ClaimSignature: React.FC<ClaimSignatureRelationProps> = ({
  teammates,
  teamLoading,
  signingProvider,
  setSigningProvider,
  handleDiscard,
  handleSaveAsDraft,
  handleSubmitClaim,
  saveLoading,
  claimStatus,
  disabled,
  saveSubmitLoading,
  deletionDisabled,
}) => {
  const [teammatesOptions, setTeammatesOptions] = useState<Teammate[]>([])
  const [selectedTeammate, setSelectedTeammate] = useState<string | null>(null)
  const [font, setFont] = useState<FontOptions>(FontOptions.SACRAMENTO)
  const [providerName, setProviderName] = useState<string>('')

  useEffect(() => {
    if (!teamLoading) {
      const actTeammates = teammates.filter((t) => !t.isDeactivated && t.name)
      setTeammatesOptions(actTeammates)
    }
  }, [teammates, teamLoading])

  useEffect(() => {
    if (signingProvider) {
      const teammate = teammatesOptions.find(
        (t) => t.cognitoId === signingProvider
      )
      setProviderName(teammate?.name || '')
      setSelectedTeammate(signingProvider)
      // @ts-ignore
      setFont(teammate?.signatureFont || FontOptions.SACRAMENTO)
    }
  }, [signingProvider, teammatesOptions])

  function handleAddSignature() {
    setSigningProvider(selectedTeammate)
  }

  return (
    <>
      <Text className={styles.subTitle}>Signature</Text>
      <Row className={styles.container}>
        <Col className={styles.selectCol}>
          <div className={styles.fullWidth}>
            <div className={styles.subheader}>
              Submitting provider
              <span style={{ color: '#F5222D' }}>{' *'}</span>
            </div>
            <div>
              <Select
                loading={teamLoading}
                style={{ width: '100%' }}
                value={selectedTeammate}
                filterOption={false}
                onSelect={(value: any) => setSelectedTeammate(value)}
                placeholder="Select"
                disabled={disabled}
                testId={'signature-select'}
              >
                {teammatesOptions.map((t) => {
                  return (
                    <Option value={t.cognitoId} key={t.cognitoId}>
                      {t.name ? t.name : t.email}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
        </Col>
        <Col className={styles.addButtonContainer}>
          <div className={styles.halfWidth}>
            <Button
              testId="add-signature-provider-button"
              className={styles.addsignaturebutton}
              icon={<PlusOutlined />}
              type="default"
              color="#262626"
              onClick={handleAddSignature}
              disabled={disabled}
            >
              Add signature
            </Button>
          </div>
        </Col>
      </Row>
      <Row className={styles.signatureContainer}>
        <SignaturePreview name={providerName} font={font} />
      </Row>
      {(claimStatus === CLAIM_STATUS.DRAFT ||
        claimStatus === CLAIM_STATUS.CHANGE_ERROR ||
        !claimStatus) && (
        <Row className={styles.claimButtonsContainer}>
          <Button
            testId="discard-button"
            type="default"
            color="#262626"
            disabled={deletionDisabled}
            // This doesn't actually delete the claim, it just navigates without saving changes
            onClick={() => handleDiscard()}
          >
            Discard changes
          </Button>
          <Button
            className={styles.rightButtons}
            testId="save-as-draft-signature-button"
            type="default"
            color="#262626"
            loading={saveLoading}
            disabled={disabled || saveSubmitLoading}
            onClick={() => handleSaveAsDraft()}
          >
            Save draft
          </Button>
          <Button
            className={styles.rightButtons}
            loading={saveSubmitLoading}
            disabled={disabled || saveLoading}
            testId="submit-button"
            type="primary"
            color="#262626"
            onClick={() => handleSubmitClaim()}
          >
            Submit claim
          </Button>
        </Row>
      )}
    </>
  )
}

export default ClaimSignature
