import { MutationStatus, QueryStatus } from '@tanstack/react-query'
import { format } from 'date-fns'
import z from 'zod'

import { ChangePayersList } from '../../../api/intakeForms'
import { Diagnosis } from '../../../shared-types'
import {
  CLAIM_INSURANCE_TYPE_TO_FILING_CODE,
  MEDICARE_CLAIM_FILING_CODES,
  RELATIONSHIP,
} from './constants'
import { toEinInputValue, toSsnInputValue } from './mappers'
import {
  AddressInput,
  ClaimForm,
  ClaimFormDiagnosis,
  ClaimFormLineItem,
  ClaimFormNestedSectionKeys,
  ClaimFormProvider,
  ClaimFormStatus,
  ClaimProvider,
  ClaimStatus,
  TAX_TYPE,
} from './types'

export const buildClaimRoute = ({
  claimId,
  patientId,
  providerId,
}: {
  claimId: string
  patientId: string
  providerId: string
}) => {
  // We should be able to use generatePath from 'react-router-dom', but that breaks tests
  // return generatePath('/patient/billing/claim', { claimId, patientId, providerId })

  const urlParams = new URLSearchParams()
  Object.entries({ claimId, patientId, providerId }).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      urlParams.set(key, String(value))
    }
  })

  return `/patient/billing/claim?${urlParams}`
}

export const isFormBusy = (formStatus: ClaimFormStatus) =>
  !!formStatus &&
  [
    'INITIAL_LOADING',
    'SAVING_CLAIM',
    'DELETING_CLAIM',
    'CANCELLING_CLAIM',
    'SAVING_CLAIM_MEMO',
    'SUBMITTING_CLAIM',
    'MANUALLY_UPDATING_CLAIM_STATUS',
  ].includes(formStatus)
export const isFormInitialLoading = (formStatus: ClaimFormStatus) =>
  formStatus === 'INITIAL_LOADING'
export const isFormSaving = (formStatus: ClaimFormStatus) =>
  formStatus === 'SAVING_CLAIM'
export const isFormDeleting = (formStatus: ClaimFormStatus) =>
  formStatus === 'DELETING_CLAIM'
export const isFormSavingMemo = (formStatus: ClaimFormStatus) =>
  formStatus === 'SAVING_CLAIM_MEMO'
export const isFormSubmitting = (formStatus: ClaimFormStatus) =>
  formStatus === 'SUBMITTING_CLAIM'
export const isLoadingStatus = (
  networkStatus: QueryStatus | MutationStatus
): boolean => networkStatus === 'loading'
export const isErrorStatus = (
  networkStatus: QueryStatus | MutationStatus
): boolean => networkStatus === 'error'
export const isSuccessStatus = (
  networkStatus: QueryStatus | MutationStatus
): boolean => networkStatus === 'success'

export const getClaimFilingCodeForPayer = (
  payersByName: { [name: string]: ChangePayersList },
  payerName: string
) => {
  const type = payersByName[payerName]?.claimInsuranceType
  if (type) {
    return CLAIM_INSURANCE_TYPE_TO_FILING_CODE[type] ?? null
  }
  return null
}

export const isSubscriberSelf = (subscriberRelationship: string | null) =>
  subscriberRelationship === RELATIONSHIP.SELF

export const isMedicare = (claimFilingCode: string | null) =>
  !!(claimFilingCode && MEDICARE_CLAIM_FILING_CODES.includes(claimFilingCode))

// address1 input value -> address1 display
export const getAddressInputDisplay = (addressObj: AddressInput): string => {
  return [
    addressObj.Address1,
    addressObj.City,
    addressObj.State,
    addressObj.Zipcode,
  ]
    .filter((el) => el)
    .join(', ')
}

export const isClaimPresubmit = (claimStatus: ClaimStatus) =>
  [ClaimStatus.DRAFT, ClaimStatus.CHANGE_ERROR].includes(claimStatus)

export const isClaimCancellable = (claimStatus: ClaimStatus) =>
  [ClaimStatus.SUBMITTED, ClaimStatus.ADJUDICATED].includes(claimStatus)

export const isClaimNotSubmitted = (claimStatus: ClaimStatus) =>
  [ClaimStatus.DRAFT, ClaimStatus.CHANGE_ERROR, ClaimStatus.CANCELED].includes(
    claimStatus
  )

export const formatPhoneNumber = (val: string) =>
  val.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
export const formatEin = (val: string) =>
  val.replace(/^(\d{2})(\d{7})$/, '$1-$2')
export const formatSsn = (val: string) =>
  val.replace(/^(\d{3}|\*{3})(\d{2}|\*{2})(\d{4})$/, '$1-$2-$3')

export const validatorFactory = (schema: z.Schema) => (val: any) => {
  const { error } = schema.safeParse(val)
  const errorMessage = error?.issues?.[0].message
  return errorMessage
}

export const extractDigits = (val: string) => val.replace(/\D/g, '')

export const getNewValuesOnNestedFieldChange = (
  values: ClaimForm,
  fieldName: string,
  fieldValue: any
): ClaimForm => {
  const [sectionKey, fieldKey] = fieldName.split('.')
  return {
    ...values,
    [sectionKey]: {
      ...values[sectionKey as ClaimFormNestedSectionKeys],
      [fieldKey]: fieldValue,
    },
  }
}

export const getNewBillingValuesOnProviderChange = (
  billing: ClaimForm['billing'],
  newProviderId: string | null,
  providersById: { [index: string]: ClaimProvider }
): ClaimForm['billing'] => {
  const selectedProvider = newProviderId ? providersById[newProviderId] : null
  const isSsn = selectedProvider?.useEINorSSN === TAX_TYPE.SSN
  const isEin = selectedProvider?.useEINorSSN === TAX_TYPE.EIN
  return {
    ...billing,
    providerId: newProviderId,
    phoneNumber: selectedProvider?.phone ?? null,
    taxType: selectedProvider?.useEINorSSN ?? null,
    ein: isEin ? toEinInputValue(selectedProvider?.ein) : null,
    ssn: isSsn ? toSsnInputValue(selectedProvider?.ssnLastFour) : null,
    ssnFirstFive: isSsn ? selectedProvider?.ssnFirstFive : null,
    ssnLastFour: isSsn ? selectedProvider?.ssnLastFour : null,
    npi: selectedProvider?.billingNPI ?? null,
    taxonomyCode: null,
  }
}

export const getNewProviderValuesOnProviderChange = (
  provider: ClaimFormProvider,
  newProviderId: string | null,
  providersById: { [index: string]: ClaimProvider }
): ClaimFormProvider => {
  const selectedProvider = newProviderId ? providersById[newProviderId] : null
  const isSsn = selectedProvider?.useEINorSSN === TAX_TYPE.SSN
  const isEin = selectedProvider?.useEINorSSN === TAX_TYPE.EIN
  return {
    ...provider,
    providerId: newProviderId,
    taxType: selectedProvider?.useEINorSSN ?? null,
    providerEin: isEin ? toEinInputValue(selectedProvider?.ein) : null,
    providerSsn: isSsn ? toSsnInputValue(selectedProvider?.ssnLastFour) : null,
    providerSsnFirstFive: isSsn ? selectedProvider?.ssnFirstFive : null,
    providerSsnLastFour: isSsn ? selectedProvider?.ssnLastFour : null,
    providerNpi: selectedProvider?.billingNPI ?? null,
    providerTaxonomyCode: null,
  }
}

/**
  Replace diagnoses in claims with newly fetched and sorted active diagnoses data,
  while keeping excluded diagnoses excluded.
 */
export const getRefreshedDiagnoses = (
  diagnosesInClaim: ClaimFormDiagnosis[],
  sortedActiveDiagnoses: Diagnosis[]
) => {
  const excludedDiagnosisIds = new Set(
    diagnosesInClaim.filter((d) => d.isExcluded).map((d) => d.diagnosisId)
  )
  const newDiagnoses = sortedActiveDiagnoses.map(
    ({ DiagnosisCode, Diagnosis, DiagnosisId, DiagnosisDate }) => ({
      code: DiagnosisCode,
      description: Diagnosis,
      diagnosisId: DiagnosisId,
      diagnosisDate: DiagnosisDate,
      isExcluded: excludedDiagnosisIds.has(DiagnosisId),
    })
  )
  return newDiagnoses
}

export const getIncludedDiagnosesWithOrder = (
  diagnoses: ClaimFormDiagnosis[]
) =>
  diagnoses
    .filter((d) => !d.isExcluded)
    .map((d, index) => ({ ...d, order: index }))

export const getDxPointerOptions = (diagnoses: ClaimFormDiagnosis[]) =>
  getIncludedDiagnosesWithOrder(diagnoses).map(({ code, order }) => ({
    label: `${order + 1} (${code})`,
    value: order,
  }))

// when diagnoses changes, valid dx pointer options also change. We need to look through lineItems and remove any dx pointers that have become invalid
export const removeInvalidDxPointersFromLineItems = (
  lineItems: ClaimFormLineItem[],
  diagnoses: ClaimFormDiagnosis[]
): ClaimFormLineItem[] => {
  const validDxPointers = new Set(
    getDxPointerOptions(diagnoses).map((option) => option.value)
  )
  const newLineItems = lineItems.map((lineItem) => ({
    ...lineItem,
    dxPointers: lineItem.dxPointers.filter((dxPointer) =>
      validDxPointers.has(dxPointer)
    ),
  }))
  return newLineItems
}

export const getAddress2FromAddress = (
  address?: AddressInput | null
): string | null => address?.Address2 || null

export const formatDate = (dateString: string) =>
  format(new Date(dateString), 'MM/dd/yyyy')
