import { useContext, useEffect, useMemo, useState } from 'react'

import { UserMessageCreateParams } from '@sendbird/chat/message'
// TODO: consider moving this to
// import Channel from '@sendbird/uikit-react/GroupChannel'
import { Channel } from '@sendbird/uikit-react'
import { useChannelContext } from '@sendbird/uikit-react/Channel/context'
import { Card, Col, Container, Row } from 'react-bootstrap'

import { sendPushNotifViaApi } from '../../api/api-lib'
import DisabledFeatureMessage from '../../components/Other/DisabledFeatureMessage'
import { useAppContext } from '../../libs/contextLib'
import {
  PatientProfileEvents,
  trackPatientProfileEvent,
} from '../../libs/freshpaint/patientProfileEvents'
import { Text } from '../../stories/BaseComponents'
import { UnreadMessageCountsContext } from '../../v2/messaging/UnreadMessageCounts'
import { PROVIDER_MESSAGE } from '../../v2/messaging/UnreadMessageCounts/constants'

import styles from '../_shared.module.scss'
import './Messaging.scss'
import '@sendbird/uikit-react/dist/index.css'

export type MessagingProps = {
  patient: {
    PatientId: string
    PatientName: string
    ChannelUrl: string
    ClinicId: string
  }
  providerId: string
}

export const Messaging = ({ patient, providerId }: MessagingProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isMarkedAsRead, setIsMarkedAsRead] = useState(false)
  const { appSettings } = useAppContext()
  const { markAsReadForPatient, markAsUnreadForPatient } = useContext(
    UnreadMessageCountsContext
  )

  const { scrollToMessage, allMessages } = useChannelContext()
  useEffect(() => {
    // By default, as soon as the messages have been seen, they will be unread.
    // this will reset the custom channel state. The user will need to select unread
    // again if they'd like the channel to continue to have an alert
    if (appSettings.secureMessaging && !isLoaded) {
      // WARNING: this is an async function but we're not awaiting (or handling) results/errors
      markAsReadForPatient(patient.ChannelUrl)
      setIsMarkedAsRead(true)
      setIsLoaded(true)
    }
  }, [
    appSettings.secureMessaging,
    patient.ChannelUrl,
    markAsReadForPatient,
    isLoaded,
  ])

  // Scroll to latest message upon change in allMessages
  useEffect(() => {
    if (!scrollToMessage || !allMessages.length) {
      return
    }
    const lastMessage = allMessages[allMessages.length - 1]
    scrollToMessage(lastMessage.createdAt, lastMessage.messageId)
  }, [allMessages, scrollToMessage])

  const handleToggle = async () => {
    // Usually use function notation for toggle,
    // but bootstrap doesn't seem to like it
    setIsMarkedAsRead(!isMarkedAsRead)
    if (isMarkedAsRead) {
      await markAsUnreadForPatient(patient.ChannelUrl)
      trackPatientProfileEvent(PatientProfileEvents.MARKED_AS_UNREAD, {
        patientId: patient.PatientId,
        clinicId: patient.ClinicId,
        providerId: providerId,
      })
    } else {
      await markAsReadForPatient(patient.ChannelUrl)
      trackPatientProfileEvent(PatientProfileEvents.MARKED_AS_READ, {
        patientId: patient.PatientId,
        clinicId: patient.ClinicId,
        providerId: providerId,
      })
    }
  }

  const isTestPatient = useMemo(() => {
    return patient.PatientId.toLowerCase().startsWith('test:')
  }, [patient.PatientId])

  if ((appSettings && !appSettings.secureMessaging) || isTestPatient) {
    return (
      <Card bsPrefix="summary-card">
        <DisabledFeatureMessage
          feature="secure messaging"
          isTestPatient={isTestPatient}
        />
      </Card>
    )
  }

  return (
    <>
      <Container id="chat-container" className="focused">
        <Row>
          <Col>
            <Text
              className={`${styles.discretionNote} ${styles.messagingNote}`}
            >
              Please note that patients are informed in the app that messages
              sent here may not be actively monitored and are not to be used for
              emergency communications.
            </Text>
            <Channel
              channelUrl={patient.ChannelUrl}
              onBeforeSendUserMessage={(text) => {
                sendPushNotifViaApi(patient.PatientId)
                const params: UserMessageCreateParams = {
                  message: text,
                  customType: PROVIDER_MESSAGE,
                }
                return params
              }}
              scrollBehavior="smooth"
            />
          </Col>
        </Row>
      </Container>
      <br />
      <div className={'info pretty p-icon p-toggle p-plain very-large'}>
        <input
          id="checkbox-mark-unread"
          onClick={handleToggle}
          type="checkbox"
          checked={!isMarkedAsRead}
        />
        <div id={'divMarkAsRead' + patient.PatientName} className="state p-on">
          <i className="icon fa fa-envelope-o" />
          &nbsp;
          <label id={'markAsRead' + patient.PatientName}>
            Mark messages from {patient.PatientName} as read
          </label>
        </div>
        <div
          id={'divMarkAsUnRead' + patient.PatientName}
          className="state p-off"
        >
          <i className="icon fa fa-envelope-open-o" />
          &nbsp;
          <label id={'markAsUnRead' + patient.PatientName}>
            Mark messages from {patient.PatientName} as unread
          </label>
        </div>
      </div>
    </>
  )
}
