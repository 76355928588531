import { useCallback, useEffect, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { JSONContent } from '@tiptap/react'
import { debounce } from 'lodash'
import { traverseEditorContent } from 'v2/notes/Components/RichTextEditor/EditorContent/EditorContent'

import { useFeatureFlags } from '../../../libs/featureFlags'
import {
  CUSTOM_TEMPLATE_BLOCK,
  NoteTemplate,
} from '../../../v2/notes/Components/Templates/types'
import { TemplateBlock } from '../../../v2/notes/api'
import { getNoteTemplateQueryKey } from '../../../v2/notes/hooks/templates/useGetNoteTemplate'
import { useUpdateNoteTemplate } from '../../../v2/notes/hooks/templates/useUpdateNoteTemplate'

export function useNoteTemplateAutosave(templateId: string) {
  const {
    updateNoteTemplate,
    isLoading: isSaving,
    isError,
  } = useUpdateNoteTemplate()
  const [pendingChanges, setPendingChanges] = useState<JSONContent | null>(null)
  const { autosaveDebounceNotev1Seconds } = useFeatureFlags()
  const queryClient = useQueryClient()

  const updateNoteTemplateQueryData = (update: Partial<NoteTemplate>) => {
    queryClient.setQueryData<NoteTemplate>(
      getNoteTemplateQueryKey(templateId),
      (cachedData) =>
        cachedData
          ? {
              ...cachedData,
              ...update,
            }
          : undefined
    )
  }

  const onChange = (contents: JSONContent) => {
    setPendingChanges(contents)
  }

  const doUpdate = async ({
    latestPendingChanges,
  }: {
    latestPendingChanges: JSONContent
  }) => {
    try {
      const blocks: TemplateBlock[] = []
      traverseEditorContent(latestPendingChanges, (n) => {
        if (n.type && CUSTOM_TEMPLATE_BLOCK === n.type) {
          blocks.push({
            type: n.attrs?.type,
            blockId: n.attrs?.id,
          })
        }
      })

      await updateNoteTemplate({
        templateId,
        body: {
          content: latestPendingChanges,
          blocks,
        },
      })

      // After the update has succeeded, set the local cache state
      // with the 'optimistic' state of the new data. Even though the updateNoteTemplate
      // call will invalidate the cache, we don't want to serve stale data while it's refetching
      // or else it can cause discrepancies in the editor view.
      updateNoteTemplateQueryData({
        content: latestPendingChanges,
      })
    } catch (e) {
      console.error('error updating note template: ', e)
    } finally {
      setPendingChanges(null)
    }
  }

  const debouncedUpdate = useCallback(
    debounce(
      async ({
        latestPendingChanges,
      }: {
        latestPendingChanges: JSONContent
      }) => {
        await doUpdate({ latestPendingChanges })
      },
      autosaveDebounceNotev1Seconds * 1000
    ),
    [autosaveDebounceNotev1Seconds]
  )

  useEffect(() => {
    if (pendingChanges) {
      debouncedUpdate({ latestPendingChanges: pendingChanges })
    }

    return () => debouncedUpdate.cancel()
  }, [pendingChanges, debouncedUpdate])

  return {
    isSaving,
    hasPendingChanges: !!pendingChanges,
    hasError: isError,
    onChange,
    forceSave: async () => {
      if (pendingChanges) {
        await doUpdate({ latestPendingChanges: pendingChanges })
      }
    },
  }
}
