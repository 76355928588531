import React, { useState } from 'react'

import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import ManualStatusUpdateModal from 'containers/Patient/Claims/ManualStatusUpdateModal'
import { CLAIM_STATUS_DISPLAY } from 'shared-types'

import { Button, Card, Dropdown } from '../../../../stories/BaseComponents'
import { ClaimStatusDisplayWithUpdate } from '../Components/ClaimStatusDisplayWithUpdate'
import { UpdateStatusChangeManually } from '../hooks'
import { ClaimData, ClaimFormStatus, ClaimStatus } from '../types'
import {
  formatDate,
  isClaimCancellable,
  isClaimPresubmit,
  isFormBusy,
  isFormSaving,
} from '../utils'

import styles from './ClaimHeader.module.scss'

export const MISSING_VALUE = '—'

export const getSubmittedDateDisplay = (
  claimStatus: ClaimStatus,
  submittedDate?: string | null
) => {
  if (isClaimPresubmit(claimStatus)) {
    return MISSING_VALUE
  } else {
    return submittedDate ? formatDate(submittedDate) : 'Unknown'
  }
}

export const testIds = {
  container: 'claim-header',
  backButton: 'claim-header-back-button',
  deleteButton: 'claim-header-delete-button',
  saveButton: 'claim-header-save-button',
  editClaimButton: 'claim-header-edit-claim-button',
  editPaymentsButton: 'claim-header-edit-claim-payments-button',
  cancelClaimButton: 'claim-header-cancel-claim-button',
  claimTypeValue: 'claim-header-claim-type',
  pcnValue: 'claim-header-patient-control-number',
  createdDateValue: 'claim-header-created-date',
  submittedDateValue: 'claim-header-submitted-date',
  claimStatusValue: 'claim-header-claim-status',
  manualClaimStatusUpdateModal: 'manual-claim-status-update-modal',
}
type ClaimHeaderProps = {
  claim: ClaimData
  onGoBack: () => void
  onDeleteClaim: () => void
  onSaveClaim: () => void
  onCancelClaim: () => void
  onEditPayments: () => void
  onUpdateStatusChangeManually: UpdateStatusChangeManually
  formStatus: ClaimFormStatus
  isLoadingManualStatusChange: boolean
}

const ClaimHeader: React.FC<ClaimHeaderProps> = ({
  claim,
  onGoBack,
  onDeleteClaim,
  onSaveClaim,
  onCancelClaim,
  onEditPayments,
  onUpdateStatusChangeManually,
  formStatus,
  isLoadingManualStatusChange,
}) => {
  const [shouldShowStatusUpdateModal, setShouldShowStatusUpdateModal] =
    useState(false)

  const claimStatus = claim.claimStatus
  const title = 'Insurance claim'
  const isDisabled = isFormBusy(formStatus)

  const getEditClaimOptions = () => {
    const options = [
      {
        text: 'Edit payment values',
        key: '1',
        onClick: onEditPayments,
        testId: testIds.editPaymentsButton,
        disabled: isDisabled,
      },
    ]
    if (claim.payerControlNumber && isClaimCancellable(claimStatus)) {
      options.push({
        text: 'Cancel/void claim',
        key: '2',
        onClick: onCancelClaim,
        testId: testIds.cancelClaimButton,
        disabled: isDisabled,
      })
    }
    return options
  }

  return (
    <Card testId={testIds.container} bodyStyle={{ paddingTop: 12 }}>
      <ManualStatusUpdateModal
        testId={testIds.manualClaimStatusUpdateModal}
        claimStatus={claimStatus}
        handleUpdateStatus={onUpdateStatusChangeManually}
        visible={shouldShowStatusUpdateModal}
        setVisible={setShouldShowStatusUpdateModal}
        // Not needed for Claims V2
        // eslint-disable-next-line
        setDisplayStatus={() => {}}
        patientId={claim?.patient?.id ?? undefined}
        claimId={claim?.claimId}
      />
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <Button
            testId={testIds.backButton}
            shape="circle"
            type="text"
            className={styles.backBtn}
            icon={<ArrowLeftOutlined className={styles.backIcon} />}
            onClick={onGoBack}
            disabled={isDisabled}
          />
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.headerRight}>
          {isClaimPresubmit(claimStatus) ? (
            <>
              <Button
                testId={testIds.deleteButton}
                onClick={onDeleteClaim}
                disabled={isDisabled}
              >
                Delete claim draft
              </Button>
              <Button
                testId={testIds.saveButton}
                onClick={onSaveClaim}
                loading={isFormSaving(formStatus)}
                disabled={isDisabled}
              >
                Save draft
              </Button>
            </>
          ) : (
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              items={getEditClaimOptions()}
            >
              <Button testId={testIds.editClaimButton}>
                Edit claim
                <DownOutlined
                  style={{ fontSize: 14, verticalAlign: 'middle' }}
                />
              </Button>
            </Dropdown>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Type</div>
          <div
            className={styles.fieldValue}
            data-testid={testIds.claimTypeValue}
          >
            {'Primary'}
          </div>
        </div>

        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Patient control number</div>
          <div className={styles.fieldValue} data-testid={testIds.pcnValue}>
            {claim.patientControlNumber || MISSING_VALUE}
          </div>
        </div>

        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Created date</div>
          <div
            className={styles.fieldValue}
            data-testid={testIds.createdDateValue}
          >
            {claim.createdAt ? formatDate(claim.createdAt) : MISSING_VALUE}
          </div>
        </div>

        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Submitted date</div>
          <div
            className={styles.fieldValue}
            data-testid={testIds.submittedDateValue}
          >
            {getSubmittedDateDisplay(
              claimStatus,
              claim.submittedInfo?.submittedDate
            )}
          </div>
        </div>

        <div className={styles.contentField}>
          <div className={styles.fieldLabel}>Status</div>
          <div
            className={styles.fieldValue}
            data-testid={testIds.claimStatusValue}
          >
            <ClaimStatusDisplayWithUpdate
              claimStatus={claim?.claimStatus}
              setShouldShowStatusUpdateModal={setShouldShowStatusUpdateModal}
              statusCategory={claim?.claimStatusUpdate?.statusCategory}
              displayStatus={
                claim?.claimStatusUpdate?.displayMessage as CLAIM_STATUS_DISPLAY
              }
              isLoadingManualStatusChange={isLoadingManualStatusChange}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ClaimHeader
