import { message } from 'antd'
import { RcFile } from 'antd/es/upload/interface'
import { Buffer } from 'buffer'

import { getS3FilePrivate } from '../../../libs/awsLib'

export function getBase64(img: RcFile | File, callback: any) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export function validateImageFileSize(file: File) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file')
  }
  return isJpgOrPng
}

export async function getPatientImageUrl(
  providerId: string,
  patientName: string | null
) {
  try {
    const filename = patientName?.split(' ').join('') ?? ''
    // We will be preventing the logging of errors if the image
    // is not found in S3 when attempting to retrieve it.
    //
    // Currently, the client assumes that all patients have a patient profile image associated with them
    // as this call is always made. When S3 does not have the corresponding image,
    // a 403 console error (in the client UI) is seen.
    //
    // Ideally, this call would only be made if the patient is known
    // to have an image associated with them, however the migration
    // path to this solution is not tenable at this time.
    const data = await getS3FilePrivate(filename, providerId, {}, false)
    if (!data) {
      // no file returned, return empty string
      return ''
    }
    const buf = Buffer.from(data)
    const base64Buf = buf.toString('base64')
    const url = 'data:image/jpeg;base64,' + base64Buf
    return url || ''
  } catch (e: unknown) {
    return ''
  }
}

export function dataURLtoFile(dataurl: string, filename: string) {
  const arr: string[] = dataurl.split(',')
  // @ts-ignore
  const mime: string = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}
