import { createContext, useContext } from 'react'

// typed from freshpaint/index.ts, should be better fleshed out from the BE
interface AppSettings {
  erx?: boolean
  journal?: boolean
  secureMessaging?: boolean
  community?: boolean
  superbillSubmission?: boolean
}

export interface AppContext {
  isAuthenticated: boolean
  setIsAuthenticating: (isAuthenticating: boolean) => void
  appSettings: AppSettings
  shouldReload: boolean
  clinicId: string
  providerId: string
}

export const AppContext = createContext<AppContext | null>(null)

export function useAppContext() {
  const appContext = useContext(AppContext)
  if (!appContext) {
    throw new Error('useAppContext has to be used within <AppContext.Provider>')
  }

  return appContext
}
