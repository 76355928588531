import React from 'react'

import { blue } from '@ant-design/colors'
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { ColumnType } from 'antd/lib/table'
import { QUERY_PARAMS } from 'libs/constants'
import { Link } from 'react-router-dom'
import {
  NoteTypes,
  ReverseUserFacingNoteTypes,
  UserFacingNoteTypes,
} from 'shared-types'
import getCognitoUser from 'shared/Helpers/getCognitoUser'
import { listNoteDetails } from 'v2/notes/api'

import { getClinicalNotesUrl } from '../../../containers/Patient/ClinicalNotes/utils'
import { SearchBox, Tag, Tooltip } from '../../BaseComponents'
import {
  CSVTableDataTypes,
  NotesReportData,
  ReportTableType,
} from '../../BaseComponents/tableData.types'
import {
  METRICS_HEADER_TOTAL_NOTES,
  METRICS_HEADER_UNSIGNED_NOTES,
} from '../constants'
// Replace with structured map of provider list
import { GraphBatchingConfigs, ReportType } from '../types'
import SpravatoNoteDaysToSignCell from './SpravatoNoteDaysToSignCell'

import themeStyles from '../../../_theme.module.scss'

export const defaultStringSorter = (a: string, b: string) => {
  return a.localeCompare(b)
}

export const defaultDateSorter = (a: Date, b: Date) => {
  return a.valueOf() - b.valueOf()
}

export const caseInsensitiveFilter = (
  value: string | number | boolean,
  record: string
): boolean => {
  return record
    .toString()
    .toLowerCase()
    .includes(value.toString().toLowerCase())
}

const PATIENT_COLUMN: ColumnType<NotesReportData> = {
  title: 'Patient name',
  dataIndex: ['patientName', 'publicId'],
  key: 'patientName',
  render: (_text, record) => (
    <Link
      style={{ color: blue.primary }}
      to={`/patient/clinical-notes?patientId=${record.patientId}&providerId=${record.providerId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {record.patientName}
    </Link>
  ),
  sorter: (a, b) => defaultStringSorter(a.patientName, b.patientName),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? blue.primary : undefined }} />
  ),
  filterDropdown: (args) => (
    <SearchBox
      dataIndex="patientName"
      placeholder="Search Patient Name"
      {...args}
    />
  ),
  onFilter: (value, record: NotesReportData) =>
    caseInsensitiveFilter(value, record.patientName),
}

const TYPE_COLUMN: ColumnType<NotesReportData> = {
  title: 'Type',
  dataIndex: 'type',
  key: 'type',
}

const TITLE_COLUMN: ColumnType<NotesReportData> = {
  title: 'Title',
  dataIndex: 'title',
  key: 'title',
  render: (_text, record) => (
    <Link
      style={{ color: blue.primary }}
      to={getClinicalNotesUrl({
        isV2: ReverseUserFacingNoteTypes[record.type] === NoteTypes.NOTE_V2,
        noteId: record.noteId,
        // TODO: add a helper for constructing URLs with params
        // https://osmind.atlassian.net/browse/PRAC-2117
        urlParams: `${QUERY_PARAMS.patientId}=${record.patientId}&${QUERY_PARAMS.providerId}=${record.providerId}`,
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      {record.title}
    </Link>
  ),
}

const DATE_COLUMN: ColumnType<NotesReportData> = {
  title: 'Date',
  dataIndex: 'date',
  key: 'date',
  sorter: (a, b) => defaultDateSorter(a.date, b.date),
  render: (date) => date.toLocaleDateString(),
}

const DAYS_TO_SIGN_COLUMN: ColumnType<NotesReportData> = {
  title: (
    <span>
      Days to sign
      <Tooltip
        title="Spravato REMS forms must be submitted within 7 days of the appointment. Forms are automatically submitted when notes are signed."
        // Place on bottom to avoid overlap with table column tooltips.
        placement={'bottom'}
      >
        <ExclamationCircleOutlined
          style={{ color: themeStyles.gray9, padding: '0 5px' }}
        />
      </Tooltip>
    </span>
  ),
  dataIndex: 'date',
  key: 'days',
  sorter: (a, b) => defaultDateSorter(a.date, b.date),
  render: (date) => <SpravatoNoteDaysToSignCell date={date} />,
}

const SIGNATURE_COLUMN: ColumnType<NotesReportData> = {
  title: 'Signature',
  dataIndex: 'isSigned',
  key: 'isSigned',
  render: (isSigned: boolean) => (
    <Tag color={isSigned ? 'green' : 'red'}>
      {isSigned ? 'Signed' : 'Unsigned'}
    </Tag>
  ),
}

export const NOTES_COLUMNS: ColumnsType<NotesReportData> = [
  PATIENT_COLUMN,
  {
    ...TYPE_COLUMN,
    sorter: (a, b) => defaultStringSorter(a.type, b.type),
    filters: Object.values(NoteTypes)
      .map((noteType) => ({
        text: UserFacingNoteTypes[noteType],
        // Since 'type' in data is the user-facing value, we need to match on that and not the raw enum value.
        value: UserFacingNoteTypes[noteType],
      }))
      // Put them in alphabetical order.
      .sort((a, b) => defaultStringSorter(a.text, b.text)),
    onFilter: (value, record) => caseInsensitiveFilter(value, record.type),
  },
  TITLE_COLUMN,
  {
    ...DATE_COLUMN,
    // Default newest -> oldest.
    defaultSortOrder: 'descend',
  },
  {
    ...SIGNATURE_COLUMN,
    filters: [
      { text: 'Signed', value: 'Signed' },
      { text: 'Unsigned', value: 'Unsigned' },
    ],
    onFilter: (value, record) =>
      record.isSigned ? value === 'Signed' : value === 'Unsigned',
  },
]

export const SPRAVATO_NOTES_COLUMNS: ColumnsType<NotesReportData> = [
  PATIENT_COLUMN,
  TYPE_COLUMN,
  TITLE_COLUMN,
  {
    ...DATE_COLUMN,
    // Default oldest -> newest.
    defaultSortOrder: 'ascend',
  },
  DAYS_TO_SIGN_COLUMN,
  SIGNATURE_COLUMN,
]

export type NotesReportFetchResponse = {
  items: MappedNoteItem[]
  totalCount: number
  unsignedCount: number
}

export interface MappedNoteItem {
  discriminator: ReportType.NOTES
  title: string
  noteId: string
  createdOn: string
  /**
   * The NoteType of the note.
   * @see NoteTypes
   */
  type: string
  isSigned: boolean
  /**
   * The only reason we need providerId in the data is for constructing
   * links to patient profile, since patient profile URLs require providerId in the
   * query params. If we can ever get rid of providerId in query params, we
   * can eliminate this dependency as well.
   */
  providerId: string
  patientId: string
  patientFirstName: string
  patientMiddleName?: string
  patientLastName: string
}

export default {
  fetch: async (
    startDate: string,
    endDate: string
  ): Promise<NotesReportFetchResponse> => {
    // 2023-11-06T15:30:00Z -> 2023-11-06
    const toDateOnly = (isoString: string) => {
      const date = new Date(isoString)
      return date.toISOString().split('T')[0]
    }
    const response = await listNoteDetails({
      includePatientInfo: true,
      startDate: toDateOnly(startDate),
      endDate: toDateOnly(endDate),
    })

    const provider = await getCognitoUser()

    // Map the notes to the format expected by the report components.
    return {
      items: response.data.map((n) => ({
        discriminator: ReportType.NOTES,
        title: n.title ?? '',
        noteId: n.noteId,
        createdOn: n.appointmentDate,
        type: UserFacingNoteTypes[n.noteType],
        isSigned: n.isSigned,
        providerId: provider.id,
        patientId: n.patientId,
        patientFirstName: n.patient?.firstName ?? '',
        patientMiddleName: n.patient?.middleName,
        patientLastName: n.patient?.lastName ?? '',
      })),
      totalCount: response.totalCount,
      unsignedCount: response.data.filter((n) => !n.isSigned).length,
    }
  },
  header: {
    description: 'Explore your practice’s notes.',
    link: {
      url: 'https://support.osmind.org/en/articles/5665898-reports',
      text: 'Learn more.',
    },
    metrics: [
      { title: METRICS_HEADER_TOTAL_NOTES, dataSource: 0 },
      { title: METRICS_HEADER_UNSIGNED_NOTES, dataSource: 0 },
    ],
    title: 'Notes',
  },
  graph: {},
  graphFilterConfigs: [],
  dataBatching: {
    options: [],
    fetchKeyAlias: {},
  } as GraphBatchingConfigs,
  table: {
    columns: NOTES_COLUMNS,
    csvHeaders: [
      { label: 'Patient name', key: 'patientName' },
      { label: 'Type', key: 'type' },
      { label: 'Title', key: 'title' },
      { label: 'Date', key: 'date' },
      { label: 'Signature', key: 'isSigned' },
    ],
    csvDataTransformer: (entry: CSVTableDataTypes): CSVTableDataTypes => {
      const noteEntry = entry as NotesReportData

      // Apply some transformations on the CSV before it's written.
      return {
        ...entry,
        isSigned: noteEntry.isSigned ? 'Signed' : 'Unsigned',
      } as unknown as CSVTableDataTypes
    },
  } as ReportTableType,
}
