import { Editor } from '@tiptap/core'
import { Bold } from '@tiptap/extension-bold'
import { BulletList } from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Focus from '@tiptap/extension-focus'
import Gapcursor from '@tiptap/extension-gapcursor'
import { HardBreak } from '@tiptap/extension-hard-break'
import { Heading } from '@tiptap/extension-heading'
import Highlight from '@tiptap/extension-highlight'
import History from '@tiptap/extension-history'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import { Italic } from '@tiptap/extension-italic'
import { ListItem } from '@tiptap/extension-list-item'
import ListKeymap from '@tiptap/extension-list-keymap'
import OrderedList from '@tiptap/extension-ordered-list'
import { Paragraph } from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
import { Transaction } from '@tiptap/pm/state'
import { Content, Extensions, JSONContent, useEditor } from '@tiptap/react'
import { isEqual } from 'lodash'

import {
  ALLOWED_HEADINGS,
  HISTORY_DEPTH,
  NOTE_EDITOR_PLACEHOLDER_TEXT,
} from '../../constants'

const SharedExtensions = [
  Document,
  Paragraph,
  Text,
  History.configure({
    depth: HISTORY_DEPTH,
  }),
  /**
   * Shows a cursor when dragging/dropping blocks to their new positions.
   */
  Dropcursor,
  /**
   * Adds a gap for the cursor in places that don’t allow regular selection.
   * For example, before a block at the beginning of a document.
   */
  Gapcursor,
  /**
   * Allows pressing shift + enter to force a line break.
   */
  HardBreak,
  /**
   * Allows typing three dashes or underscores (---) or (___ ) to make a
   * horizontal line for separation.
   */
  HorizontalRule,

  Heading.configure({
    levels: ALLOWED_HEADINGS,
  }),

  Bold,
  Italic,
  Underline,
  Highlight.configure({ multicolor: true }),
  /**
   * Base list extension required for bullet + ordered lists.
   */
  ListItem,
  BulletList.configure({
    keepMarks: true,
    keepAttributes: true,
  }),
  OrderedList,
  /**
   * TaskList + TaskItem are required for checklist functionality.
   */
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  /**
   * Modifies the default TipTap behavior for backspace/deletion at the start
   * of a list of items.
   * Ref: https://tiptap.dev/docs/editor/extensions/functionality/listkeymap
   */
  ListKeymap.configure({
    listTypes: [
      {
        itemName: 'listItem',
        wrapperNames: ['bulletList', 'orderedList'],
      },
      {
        itemName: 'taskItem',
        wrapperNames: ['taskList'],
      },
    ],
  }),

  Placeholder.configure({
    placeholder: NOTE_EDITOR_PLACEHOLDER_TEXT,
  }),

  /**
   * Used for block rendering. See has-focus CSS class.
   */
  Focus,
]

/**
 * TipTap utility to check of the content has changed.
 * Used to avoid saving the same unchanged content.
 */
const didEditorContentChange = (transaction: Transaction): boolean => {
  return !isEqual(transaction.before.content, transaction.doc.content)
}

interface useRichTextEditorProps {
  extraExtensions?: Extensions
  content: Content
  isEditable: boolean
  /**
   * Callback that's triggered when the contents of the editor has been modified.
   */
  onUpdate?: (content: JSONContent) => void
}

export const useRichTextEditor = ({
  extraExtensions,
  content,
  isEditable,
  onUpdate,
}: useRichTextEditorProps) => {
  const onBlurOrUpdate = ({
    editor,
    transaction,
  }: {
    editor: Editor
    transaction: Transaction
  }) => {
    if (didEditorContentChange(transaction) && onUpdate) {
      onUpdate(editor.getJSON())
    }
  }

  const editor = useEditor(
    {
      extensions: [
        ...SharedExtensions,
        ...(extraExtensions ? extraExtensions : []),
      ],
      editorProps: {
        attributes: {
          'data-testid': 'v2-editor-content',
        },
      },
      content,
      editable: isEditable,
      onBlur: onBlurOrUpdate,
      onUpdate: onBlurOrUpdate,
    },
    [isEditable]
  )

  return {
    editor,
  }
}
