import { API } from 'aws-amplify'

import { ClaimData } from '../containers/Patient/ClaimsV2/types'

export const createClaimFromPatientInfo = ({
  patientId,
  noteId,
}: {
  patientId: string
  noteId?: string
}): Promise<ClaimData> =>
  API.post('insurance', '/insurance/v2/claims', {
    body: {
      patientId,
      clinicalNoteId: noteId,
    },
  })

export const getClaimById = (claimId: string): Promise<ClaimData> =>
  API.get('insurance', `/insurance/v2/claims/${claimId}`, {})

export const deleteClaim = (claimId: string): Promise<string> =>
  API.del('insurance', `/insurance/v2/claims/${claimId}`, {})

// TODO: update "any" return type once CARE-2625 is completed
export const updateClaimMemo = ({
  claimId,
  memo,
}: {
  claimId: string
  memo: string | null
}): Promise<any> =>
  API.put('insurance', `/insurance/v2/claims/${claimId}/memo`, {
    body: { memo },
  })

// TODO: update "any" return type & no need to attach claimStatus once CARE-2625 is completed.
export const updateClaim = ({
  claimId,
  body,
}: {
  claimId: string
  body: Omit<ClaimData, 'claimStatusUpdate'>
}): Promise<any> =>
  API.put('insurance', `/insurance/v2/claims/${claimId}`, {
    body,
  })

export const submitClaim = ({
  body,
}: {
  body: Omit<ClaimData, 'claimStatusUpdate'>
}): Promise<any> =>
  API.post('insurance', '/insurance/v2/claim-submissions', {
    body,
  })

export const getProviders = () =>
  API.get('insurance', '/insurance/v2/providers', {})

export const getClaimsByPatientId = async (patientId: string) =>
  API.get('insurance', `/insurance/v2/claims/patients/${patientId}`, {})

export const adjudicateClaimManually = ({ claimId }: { claimId: string }) =>
  API.put('insurance', `/insurance/v2/claims/${claimId}/manual-adjudication`, {
    body: {},
  })

export const submitClaimManually = ({
  claimId,
  claimData,
}: {
  claimId: string
  claimData: ClaimData
}) =>
  API.put('insurance', `/insurance/v2/claims/${claimId}/manual-submission`, {
    body: claimData,
  })
