import React, {
  Dispatch,
  LegacyRef,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import { PresetStatusColorType } from 'antd/es/_util/colors'
import cx from 'classnames'
import { useCreateClaimAndNavigateThere } from 'containers/Patient/ClaimsV2/useCreateClaimAndNavigateThere'

import { NoteType } from '../../../../../hooks/useClinicalNoteFields/utils'
import { useClinicalNotesButtonActions } from '../../../../../hooks/useClinicalNotesButtonActions'
import { useDownloadClinicalNotePDF } from '../../../../../hooks/useDownloadClinicalNotePDF'
import { useGetClinicalNoteBackup } from '../../../../../hooks/useGetClinicalNoteBackup'
import { useFeatureFlags } from '../../../../../libs/featureFlags'
import { ClinicalNote, NoteTypes } from '../../../../../shared-types'
import {
  Badge,
  Button,
  Dropdown,
  Modal,
  Spinner,
  Tooltip,
  Typography,
} from '../../../../../stories/BaseComponents'
import { MenuItemType } from '../../../../../stories/BaseComponents/Dropdown'

import styles from './NoteHeaderV2.module.scss'

export interface NoteHeaderV2Props {
  headerRef?: LegacyRef<HTMLDivElement>
  clinicalNote?: ClinicalNote | null
  areActionButtonsDisabled?: boolean
  collapsedHeader?: boolean
  inViewMode?: boolean
  inCreationMode?: boolean
  isSaveButtonDisabled?: boolean
  isSaving?: boolean
  noteRefetchLoading?: boolean
  noteId?: string
  hasClaim?: boolean
  hasSuperbill?: boolean
  hasInvoice?: boolean
  fields?: NoteType
  patientId?: string
  providerId?: string
  isAllergiesBeingEdited?: boolean
  isFormValid?: boolean
  // this is used for autosave to display "unsaved changes" status
  hasAutosavePendingChanges?: boolean
  isNoteBackupChecked?: boolean
  isNoteAutoSaving?: boolean
  isNoteBeingCreated?: boolean
  noteAutosaveFailed?: boolean
  isNoteCreated?: boolean
  showSkeleton?: boolean
  handleSaveNote: () => Promise<void>
  handleClose?: () => void
  clickEditButton?: () => Promise<void>
  handleCloseEdit?: () => void
  updateCacheWithNav?: () => Promise<void>
  handleCopyToNewNote: () => Promise<void>
  goToNotesList: () => void
  setFields: (newFields: NoteType) => void
  setIsNoteBackupChecked: Dispatch<SetStateAction<boolean>>
  setBackupFound: Dispatch<SetStateAction<boolean>>
}

const { Text } = Typography
export const NoteHeaderV2: React.FC<NoteHeaderV2Props> = ({
  handleSaveNote,
  isSaveButtonDisabled,
  areActionButtonsDisabled,
  headerRef,
  collapsedHeader = false,
  clinicalNote,
  inViewMode,
  inCreationMode,
  isSaving,
  noteRefetchLoading,
  noteId = '',
  hasClaim = false,
  hasSuperbill = false,
  hasInvoice = false,
  fields,
  patientId = '',
  providerId = '',
  isAllergiesBeingEdited,
  isFormValid,
  hasAutosavePendingChanges,
  isNoteBackupChecked,
  isNoteAutoSaving,
  isNoteBeingCreated,
  noteAutosaveFailed,
  isNoteCreated,
  showSkeleton,
  clickEditButton,
  handleClose,
  handleCloseEdit,
  updateCacheWithNav,
  handleCopyToNewNote,
  setFields,
  setIsNoteBackupChecked,
  setBackupFound,
  goToNotesList,
}) => {
  const { claims } = useFeatureFlags()
  const [showDeleteWithSuperbillModal, setShowDeleteWithSuperbillModal] =
    useState(false)

  const { handlePrint, isDisabled, isPrinting } = useDownloadClinicalNotePDF(
    noteId,
    noteRefetchLoading
  )
  const { NoteBackupModal, checkNoteBackup } = useGetClinicalNoteBackup(
    patientId,
    setFields,
    setIsNoteBackupChecked,
    setBackupFound,
    fields?.NoteType ?? NoteTypes.CLINICAL_NOTE,
    noteId ?? '',
    clinicalNote?.lastBackupDate ?? ''
  )
  const {
    navigateToInvoice,
    navigateToClaim,
    DeleteNoteModalComponent,
    handleShowDeleteModal,
  } = useClinicalNotesButtonActions(
    hasInvoice,
    patientId,
    providerId,
    noteId,
    fields,
    updateCacheWithNav,
    handleClose
  )

  const { isCreating: isCreatingClaim, createClaimAndNavigateThere } =
    useCreateClaimAndNavigateThere({ patientId, providerId })

  const actionsMenu: MenuItemType[] = useMemo(() => {
    const options: MenuItemType[] = []
    // Billing options
    if (claims) {
      if (hasClaim) {
        options.push({
          text: 'View Claim',
          key: 'view_claim',
          onClick: navigateToClaim,
        })
      } else {
        options.push({
          text: isCreatingClaim ? <Spinner fontSize={10} /> : 'New Claim',
          key: 'new_claim',
          disabled: isCreatingClaim,
          /**
           * This will dismiss the menu as well
           */
          onClick: () => {
            createClaimAndNavigateThere({ noteId })
          },
        })
      }
    }
    if (hasInvoice) {
      options.push({
        text: 'View Invoice',
        key: 'view_invoice',
        onClick: () => navigateToInvoice(),
      })
    } else {
      options.push({
        text: 'New Invoice',
        key: 'new_invoice',
        onClick: () => navigateToInvoice(),
      })
    }
    if (hasSuperbill) {
      options.push({
        text: 'View Superbill',
        key: 'view_superbill',
        onClick: () => navigateToInvoice(!hasSuperbill),
      })
    } else {
      options.push({
        text: 'New Superbill',
        key: 'new_superbill',
        onClick: () => navigateToInvoice(!hasSuperbill),
      })
    }
    options.push({
      type: 'divider',
      text: '',
      key: '',
    })

    // Action options
    options.push(
      {
        text: 'Copy to new note',
        key: 'copy',
        onClick: () => {
          handleCopyToNewNote()
        },
        disabled: isSaving || noteRefetchLoading,
      },
      {
        text: isPrinting ? 'Downloading...' : 'Download as PDF',
        key: 'download',
        disabled: isDisabled,
        onClick: async () => handlePrint(),
      }
    )

    if (!clinicalNote?.Signatures?.length) {
      options.push(
        {
          type: 'divider',
          text: '',
          key: '',
        },
        {
          text: 'Delete note',
          key: 'delete',
          danger: true,
          onClick: () =>
            hasSuperbill
              ? setShowDeleteWithSuperbillModal(true)
              : handleShowDeleteModal(),
          disabled: noteRefetchLoading,
        }
      )
    }
    return options
  }, [
    noteId,
    hasSuperbill,
    noteRefetchLoading,
    isDisabled,
    isPrinting,
    isSaving,
    hasInvoice,
    hasClaim,
    handleCopyToNewNote,
  ])

  useEffect(() => {
    if (inCreationMode && fields?.NoteType) {
      checkNoteBackup()
    } else {
      if (!inViewMode && !isNoteBackupChecked) {
        checkNoteBackup()
      }
    }
  }, [fields?.NoteType, inCreationMode, inViewMode])

  const renderTitle = () => {
    if (showSkeleton) return
    if (inCreationMode && !fields?.Title) {
      return `New ${
        fields?.NoteType.includes('Note')
          ? fields?.NoteType.replace('Note', 'note')
          : fields?.NoteType
      }${fields?.NoteType.includes('Note') ? '' : ' note'}`
    }
    if (!inCreationMode && !fields?.CreatedOnDate) return ''
    if (fields?.Title) return fields?.Title
    return 'No Title'
  }

  const renderStatusIndicator = () => {
    let text = 'Saved'
    let status: PresetStatusColorType = 'success'

    if (noteAutosaveFailed) {
      text = 'Error saving'
      status = 'error'
    }
    if (isNoteAutoSaving || hasAutosavePendingChanges) {
      text = 'Saving...'
      status = 'default'
    }

    const noAutosaveYet =
      !isNoteCreated && !isNoteAutoSaving && !noteAutosaveFailed

    const isViewOnly = !inCreationMode && inViewMode

    const showStatus = !(noAutosaveYet || isViewOnly)

    return showStatus ? (
      <Badge className={styles.badge} status={status} text={text} />
    ) : null
  }

  const renderRightSideButtons = useMemo(() => {
    const downloadingPDFButton = (
      <Button loading className={styles.downloadButton}>
        Downloading PDF
      </Button>
    )
    const actionsButton = (
      <Dropdown
        overlayStyle={{ minWidth: 140 }}
        disabled={
          !isNoteCreated ||
          isNoteAutoSaving ||
          isNoteBeingCreated ||
          hasAutosavePendingChanges
        }
        trigger={['click']}
        items={actionsMenu}
      >
        <Button
          className={cx(styles.actionButton, {
            disabled:
              !isNoteCreated ||
              isNoteAutoSaving ||
              isNoteBeingCreated ||
              hasAutosavePendingChanges,
          })}
        >
          Actions
          <DownOutlined />
        </Button>
      </Dropdown>
    )
    const editNoteButton = (
      <Button
        type="default"
        onClick={clickEditButton}
        className={cx(styles.actionButton, {
          disabled: noteRefetchLoading || isAllergiesBeingEdited,
        })}
        disabled={noteRefetchLoading || isAllergiesBeingEdited}
      >
        {noteRefetchLoading ? 'Loading...' : 'Edit note'}
      </Button>
    )
    const buttons = []
    // Buttons when autosave is enabled
    if (inViewMode && !inCreationMode && !clinicalNote?.Signatures?.length) {
      buttons.push(editNoteButton)
    }
    if (isPrinting) {
      buttons.push(downloadingPDFButton)
    } else {
      buttons.push(actionsButton)
    }
    return buttons
  }, [
    inViewMode,
    inCreationMode,
    isSaveButtonDisabled,
    isFormValid,
    isNoteCreated,
    isPrinting,
    clinicalNote,
    noteRefetchLoading,
    isAllergiesBeingEdited,
    areActionButtonsDisabled,
    isAllergiesBeingEdited,
    handleSaveNote,
    handleCloseEdit,
    clickEditButton,
  ])

  const DeleteWithSuperbillModal = useMemo(() => {
    return (
      <Modal
        closable={false}
        className={styles.superbillModal}
        visible={showDeleteWithSuperbillModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setShowDeleteWithSuperbillModal(false)}
        onCancel={() => setShowDeleteWithSuperbillModal(false)}
      >
        <p className={styles.modalTitle}>Unable to delete note</p>
        <p>
          This note has an attached superbill and cannot be deleted. Please
          reach out to support if needed.
        </p>
      </Modal>
    )
  }, [showDeleteWithSuperbillModal])

  const renderBreadcumb = () => {
    const backBtnDisabled =
      hasAutosavePendingChanges ||
      isNoteAutoSaving ||
      (!inCreationMode && isNoteBeingCreated)

    return (
      <>
        <Tooltip
          title={backBtnDisabled ? 'Waiting for saving to complete' : ''}
          placement="bottomLeft"
        >
          <Button
            type="link"
            className={[
              styles.backToNotes,
              backBtnDisabled ? styles.backToNotesDisabled : '',
            ].join(' ')}
            onClick={goToNotesList}
            icon={<ArrowLeftOutlined />}
            disabled={backBtnDisabled}
          />{' '}
        </Tooltip>
        <Text className={styles.title}>{renderTitle()}</Text>
        {renderStatusIndicator()}
      </>
    )
  }

  return (
    <div
      className={cx(styles.notePageHeader, {
        [styles.collapsed]: collapsedHeader,
      })}
      ref={headerRef}
    >
      {DeleteNoteModalComponent}
      {NoteBackupModal}
      {DeleteWithSuperbillModal}
      {collapsedHeader ? (
        <div className={styles.collapsedBreadcrumb}>{renderBreadcumb()}</div>
      ) : (
        renderBreadcumb()
      )}
      <div className={styles.buttonsContainer}>{renderRightSideButtons}</div>
    </div>
  )
}
