import * as React from 'react'
import { useMemo } from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useHistory } from 'react-router-dom'
import { Spinner } from 'stories/BaseComponents'

import {
  calculateClaimAndPatientOutstanding,
  convertCentsToDollars,
  formatNumberStringToUsdString,
} from '../../../libs/billing'
import {
  CLAIM_STATUS,
  CLAIM_STATUS_DISPLAY,
  InsuranceClaim,
  StatusCategoryValue,
} from '../../../shared-types'
import { isClaimNotSubmitted } from '../Claims/InsuranceClaim-helper'
import ClaimStatusDisplay from '../ClaimsV2/Components/ClaimStatusDisplay'
import { useCreateClaimAndNavigateThere } from '../ClaimsV2/useCreateClaimAndNavigateThere'
import { buildClaimRoute, formatDate } from '../ClaimsV2/utils'

interface ClaimsTableProps {
  claims: Array<InsuranceClaim>
  patientId: string
  providerId: string
}

type ClaimsRow = {
  claimId: string
  patientControlNumber: string
  status: string | React.ReactElement
  statusCategory: StatusCategoryValue
  claimStatus: CLAIM_STATUS
  submittedAt: string
  appointmentDate: string
  totalAdjustmentAmountCents: number
  totalAmountCents: number | null
  patientPaidAmountCents: number
  totalPatientOutstanding: number
  totalClaimOutstanding: number
}

function sortNullableNumerics(a: number | null, b: number | null): number {
  if (a === null) {
    return -1
  }
  if (b === null) {
    return 1
  }
  return a - b
}

export const MISSING_VALUE = '—'

function getMoneyColumnNumericValue(
  moneyAmount: number | null,
  claimStatus: CLAIM_STATUS,
  billedAmount?: number | null
) {
  if (isClaimNotSubmitted(claimStatus)) {
    return null
  } else if (billedAmount === null) {
    return null
  } else if (moneyAmount === null) {
    return null
  } else {
    return moneyAmount
  }
}

function getMoneyColumnDisplayValue(
  moneyAmount: number | null,
  claimStatus: CLAIM_STATUS,
  billedAmount?: number | null
) {
  const numericValue = getMoneyColumnNumericValue(
    moneyAmount,
    claimStatus,
    billedAmount
  )
  if (numericValue === null) {
    return MISSING_VALUE
  }
  return formatNumberStringToUsdString(convertCentsToDollars(numericValue))
}

// how to type em-dash on MacOS: shift + option + -
const NON_DATE_SUBMITTED_AT_VALUES = [MISSING_VALUE, 'Unknown']
const NON_DATE_APPOINTMENT_DATE_VALUES = [MISSING_VALUE]

const toUnixTime = (input: string) => {
  switch (input) {
    case MISSING_VALUE:
      return 0
    case 'Unknown':
      return 1
    default:
      return new Date(input).getTime()
  }
}

export function formatClaimStatus(status: CLAIM_STATUS): CLAIM_STATUS_DISPLAY {
  return CLAIM_STATUS_DISPLAY[status] || CLAIM_STATUS_DISPLAY.UNKNOWN
}

const formatAppointmentDate = (isoString: string) => {
  const isoWithoutTZ = isoString.replace('Z', '')
  return formatDate(isoWithoutTZ)
}

function formatClaimsAsDataSource(claims: InsuranceClaim[]) {
  const claimDataSource = claims.map(
    (claimRow: InsuranceClaim, idx: number) => {
      const status = claimRow.claimStatus ?? CLAIM_STATUS.DRAFT
      const { patientOutstanding, claimOutstanding } =
        calculateClaimAndPatientOutstanding(claimRow)
      return {
        key: idx.toString(),
        status: claimRow.claimStatusUpdate.displayMessage,
        claimStatus: status,
        statusCategory: claimRow.claimStatusUpdate.statusCategory,
        claimId: claimRow.uuid,
        patientPaidAmountCents: claimRow.patientPaidAmountCents,
        totalClaimOutstanding: claimOutstanding,
        totalPatientOutstanding: patientOutstanding,
        patientControlNumber: claimRow.patientControlNumber,
        totalAmountCents: claimRow.billableEntity.totalAmountCents,
        totalAdjustmentAmountCents:
          claimRow.billableEntity.totalAdjustmentAmountCents,
        submittedAt: isClaimNotSubmitted(status)
          ? MISSING_VALUE
          : claimRow.submittedAt ?? 'Unknown',
        appointmentDate: claimRow.appointmentDate ?? MISSING_VALUE,
      }
    }
  )

  const submittedClaims = claimDataSource
    .filter((c) => !NON_DATE_SUBMITTED_AT_VALUES.includes(c.submittedAt))
    .sort(
      (objA, objB) =>
        Number(new Date(objB.submittedAt)) - Number(new Date(objA.submittedAt))
    )
  const nonSubmittedClaims = claimDataSource.filter(
    (c) => c.submittedAt === MISSING_VALUE
  )
  const untrackedSubmittedClaim = claimDataSource.filter(
    (c) => c.submittedAt === 'Unknown'
  )
  return submittedClaims.concat(untrackedSubmittedClaim, nonSubmittedClaims)
}

export default function ClaimsTable({
  claims,
  patientId,
  providerId,
}: ClaimsTableProps) {
  const history = useHistory()

  function navigateToClaim(
    claimId: string,
    patientId: string,
    providerId: string
  ) {
    const claimRoute = buildClaimRoute({
      patientId,
      providerId,
      claimId,
    })
    history.push(claimRoute)
  }

  const formattedClaimsData = useMemo(
    () => formatClaimsAsDataSource(claims),
    [claims]
  )

  const statusFilters = useMemo(() => {
    return Array.from(
      new Set(formattedClaimsData.map(({ status }) => status))
    ).map((status) => {
      return {
        value: status,
        text: status,
      }
    })
  }, [formattedClaimsData])

  const { isCreating: isCreatingClaim, createClaimAndNavigateThere } =
    useCreateClaimAndNavigateThere({ patientId, providerId })

  const columns: ColumnsType<ClaimsRow> = [
    {
      title: 'Patient Control #',
      dataIndex: 'patientControlNumber',
      key: 'patientControlNumber',
      width: 152,
      render: (patientControlNumber, row) => {
        // when would both of these happen?
        if (!patientControlNumber || !row.claimId) {
          return isCreatingClaim ? (
            <Spinner fontSize={10} />
          ) : (
            <a
              onClick={() => createClaimAndNavigateThere()}
              style={{ color: '#1890ff' }}
            >
              'Create Claim'
            </a>
          )
        }
        return (
          <a
            onClick={() => navigateToClaim(row.claimId, patientId, providerId)}
            style={{ color: '#1890ff' }}
          >
            {patientControlNumber}
          </a>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 156,
      onFilter: (value, record) => {
        // @ts-ignore
        return record.status === value
      },
      filters: statusFilters,
      render: (status: string, { statusCategory, claimId }) => {
        return (
          <ClaimStatusDisplay
            testId={`claim-status-cell-${claimId}`}
            displayMessage={status}
            statusCategory={statusCategory}
          />
        )
      },
    },
    {
      title: 'Appointment date',
      dataIndex: 'appointmentDate',
      key: 'appointmentDate',
      width: 156,
      sorter: (a: ClaimsRow, b: ClaimsRow) =>
        toUnixTime(a.appointmentDate) - toUnixTime(b.appointmentDate),
      sortDirections: ['ascend', 'descend'],
      render: (appointmentDate: string, { claimId }) => {
        const isNonDateValue =
          NON_DATE_APPOINTMENT_DATE_VALUES.includes(appointmentDate)
        return (
          <span data-testid={`claim-appointment-date-cell-${claimId}`}>
            {isNonDateValue
              ? appointmentDate
              : formatAppointmentDate(appointmentDate)}
          </span>
        )
      },
    },
    {
      title: 'Submitted date',
      dataIndex: 'submittedAt',
      key: 'submittedAt',
      width: 156,
      sorter: (a: ClaimsRow, b: ClaimsRow) =>
        toUnixTime(a.submittedAt) - toUnixTime(b.submittedAt),
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
      render: (submittedDate: string) => {
        if (NON_DATE_SUBMITTED_AT_VALUES.includes(submittedDate)) {
          return <span>{submittedDate}</span>
        }
        return <span>{formatDate(submittedDate)}</span>
      },
    },
    {
      title: 'Billed',
      dataIndex: 'totalAmountCents',
      key: 'billedAmount',
      sorter: (a: ClaimsRow, b: ClaimsRow) => {
        const valueA = getMoneyColumnNumericValue(
          a.totalAmountCents,
          a.claimStatus
        )
        const valueB = getMoneyColumnNumericValue(
          b.totalAmountCents,
          b.claimStatus
        )
        return sortNullableNumerics(valueA, valueB)
      },
      render: (totalAmountCents: number | null, { claimStatus }) => {
        return (
          <span data-testid="billed-amount-cell">
            {getMoneyColumnDisplayValue(totalAmountCents, claimStatus)}
          </span>
        )
      },
      width: 168,
    },
    {
      title: 'Patient outstanding',
      dataIndex: 'totalPatientOutstanding',
      key: 'totalPatientOutstanding',
      sorter: (a: ClaimsRow, b: ClaimsRow) => {
        const valueA = getMoneyColumnNumericValue(
          a.totalPatientOutstanding,
          a.claimStatus
        )
        const valueB = getMoneyColumnNumericValue(
          b.totalPatientOutstanding,
          b.claimStatus
        )
        return sortNullableNumerics(valueA, valueB)
      },
      render: (
        totalPatientOutstanding: number,
        { patientPaidAmountCents, claimStatus }
      ) => {
        if (
          !isClaimNotSubmitted(claimStatus) &&
          totalPatientOutstanding === 0 &&
          patientPaidAmountCents === 0
        ) {
          return <span data-testid="patient-outstanding-cell">Pending</span>
        }
        return (
          <span data-testid="patient-outstanding-cell">
            {getMoneyColumnDisplayValue(totalPatientOutstanding, claimStatus)}
          </span>
        )
      },
      width: 168,
    },
    {
      title: 'Claim outstanding',
      dataIndex: 'totalClaimOutstanding',
      key: 'totalClaimOutstanding',
      sorter: (a: ClaimsRow, b: ClaimsRow) => {
        const valueA = getMoneyColumnNumericValue(
          a.totalClaimOutstanding,
          a.claimStatus,
          a.totalAmountCents
        )
        const valueB = getMoneyColumnNumericValue(
          b.totalClaimOutstanding,
          b.claimStatus,
          a.totalAmountCents
        )
        return sortNullableNumerics(valueA, valueB)
      },
      render: (
        totalClaimOutstanding: number,
        { claimStatus, totalAmountCents }
      ) => {
        return (
          <span data-testid="claim-outstanding-cell">
            {getMoneyColumnDisplayValue(
              totalClaimOutstanding,
              claimStatus,
              totalAmountCents
            )}
          </span>
        )
      },
      width: 168,
    },
  ]

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1050 }}
        columns={columns}
        dataSource={formattedClaimsData}
        className="claims-table"
      />
      <a
        href="https://connectcenter.changehealthcare.com/account/login.fcc"
        target="_blank"
        rel="noreferrer"
      >
        Open Change ConnectCenter
      </a>
    </>
  )
}
