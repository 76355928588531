import React from 'react'

import { Card } from '../../../../stories/BaseComponents'
import Provider from '../Provider'
import { sectionContainerBodyStyle, sectionContainerStyle } from '../constants'
import { IsReadOnlyModeProp } from '../types'

import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-rendering-provider',
}

const RenderingProvider: React.FC<IsReadOnlyModeProp> = ({ readOnly }) => (
  <Card
    testId={testIds.container}
    style={sectionContainerStyle}
    bodyStyle={sectionContainerBodyStyle}
  >
    <div className={styles.section}>
      <div className={styles.header}>
        <span className={styles.title}>{'Rendering provider'}</span>
      </div>
      <Provider providerType="renderingProvider" readOnly={readOnly} />
    </div>
  </Card>
)

export default RenderingProvider
