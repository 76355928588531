import Icon from '@ant-design/icons'
import { Button } from 'antd'

import { generateCoreIdDisplayName } from '../../../../../../../hooks/usePatientProfile/utils'
import { useProfileUrlParams } from '../../../../../../../hooks/useProfileUrlParams'
import { useAppContext } from '../../../../../../../libs/contextLib'
import { AllergyHistory } from '../../../../../../../shared-types'
import { ExternalLink } from '../../../../Icons/ExternalLink'
import { DynamicBlockRenderOptions } from '../../../Core/Block/DynamicBlockTypes'
import { DynamicBlockHeader } from '../shared/TableHeader'
import { AllergiesBlockTable } from './AllergiesBlockTable'

export const AllergiesBlock = ({
  block,
  note,
  onBlockUpdate,
  isRefreshing,
}: DynamicBlockRenderOptions) => {
  const { appSettings } = useAppContext()
  const { urlParams } = useProfileUrlParams()

  const options = {
    isDrFirst: !!appSettings?.erx,
    urlParams,
  }

  const actionButtons = [
    <Button
      icon={<Icon component={() => <ExternalLink />} />}
      key={`${block.uuid}_add`}
      data-testid={`${block.uuid}_add`}
      onClick={() => {
        if (!options) return

        if (options.isDrFirst) {
          window.open(
            `/patient/medication?${options.urlParams}`,
            '_blank',
            'noreferrer'
          )
        } else {
          window.open(
            `/patient/profile?${options.urlParams}&patientProfile=allergies`,
            '_blank',
            'noreferrer'
          )
        }
      }}
    >
      {options.isDrFirst ? 'Edit allergies in Dr. First' : 'Edit allergies'}
    </Button>,
  ]

  const handleBlockRefresh = async () => {
    await onBlockUpdate({
      blockConfig: {
        options: {
          limit: Date.now(),
        },
      },
      blockUuid: block.uuid,
      noteUuid: note.uuid,
    })
  }

  return (
    <>
      <DynamicBlockHeader
        title={'Active allergies'}
        buttons={actionButtons}
        isSigned={!!note?.firstSignedAt}
        blockUuid={block.uuid}
        onBlockRefresh={handleBlockRefresh}
        blockType="ALLERGIES"
      />
      <div>
        <AllergiesBlockTable
          isSigned={!!note?.firstSignedAt}
          blockData={block.data ? (block.data as AllergyHistory[]) : undefined}
          patientName={
            note?.patient && generateCoreIdDisplayName(note?.patient)
          }
          isLoading={isRefreshing}
        />
      </div>
    </>
  )
}
