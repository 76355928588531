import React, { useMemo } from 'react'

import { RedoOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { useFormikContext } from 'formik'

import { Button, Card, Table } from '../../../../stories/BaseComponents'
import InlineField from '../InlineField'
import { sectionContainerBodyStyle, sectionContainerStyle } from '../constants'
import { useActiveDiagnoses } from '../hooks'
import { ClaimForm, ClaimFormDiagnosisRow, IsReadOnlyModeProp } from '../types'
import { getRefreshedDiagnoses } from '../utils'
import {
  expandOnSeparatorRow,
  getDiagnosisRows,
  getNewValuesOnDiagnosesChange,
  getOrderDisplay,
  getPatientConditionRelationActiveFields,
  isSeparatorRow,
  shrinkOnSeparatorRow,
  toggleInclude,
} from './Diagnosis-helper'

import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-diagnosis',
  table: 'claim-diagnosis-table',
  refreshButton: 'claim-diagnosis-refresh-button',
  actionButton: 'claim-diagnosis-row-action-button',
}

type DiagnosisProps = {
  patientId: string
} & IsReadOnlyModeProp

const Diagnosis: React.FC<DiagnosisProps> = ({ patientId, readOnly }) => {
  const { values, setValues } = useFormikContext<ClaimForm>()

  const {
    patientConditionRelation: { autoAccident },
    diagnoses,
  } = values

  const { refetch: refetchActiveDiagnoses, isRefetching } =
    useActiveDiagnoses(patientId)

  const handleRefresh = async () => {
    const { data: newActiveDiagnoses } = await refetchActiveDiagnoses()
    const newDiagnoses = getRefreshedDiagnoses(
      diagnoses,
      newActiveDiagnoses ?? []
    )
    setValues(getNewValuesOnDiagnosesChange(values, newDiagnoses))
  }

  const handleToggleInclude = (diagnosisId: string) => {
    const newDiagnoses = toggleInclude(diagnoses, diagnosisId)
    setValues(getNewValuesOnDiagnosesChange(values, newDiagnoses))
  }

  const rows = useMemo(() => getDiagnosisRows(diagnoses), [diagnoses])

  const columns: ColumnsType<ClaimFormDiagnosisRow> = [
    {
      title: 'Diagnosis #',
      dataIndex: 'order',
      fixed: 'left',
      render: (_, record, index) => getOrderDisplay(record, index),
      onCell: expandOnSeparatorRow,
    },
    {
      title: 'ICD Code',
      dataIndex: 'code',
      onCell: shrinkOnSeparatorRow,
    },
    {
      title: 'Diagnosis',
      dataIndex: 'description',
      onCell: shrinkOnSeparatorRow,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => {
        if (isSeparatorRow(record)) {
          return
        }
        const { isExcluded, diagnosisId } = record
        return (
          <Button
            type="link"
            danger={!isExcluded}
            style={{ paddingLeft: 0 }}
            onClick={() => handleToggleInclude(diagnosisId)}
            testId={`${testIds.actionButton}-${diagnosisId}`}
            disabled={readOnly}
          >
            {isExcluded ? 'Include in claim' : 'Exclude from claim'}
          </Button>
        )
      },
      onCell: shrinkOnSeparatorRow,
    },
  ]

  const patientConditionRelationActiveFields = useMemo(
    () => getPatientConditionRelationActiveFields(autoAccident),
    [autoAccident]
  )

  return (
    <Card
      testId={testIds.container}
      style={sectionContainerStyle}
      bodyStyle={sectionContainerBodyStyle}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>{'Diagnoses'}</span>
          {!readOnly && (
            <Button
              type="link"
              onClick={handleRefresh}
              testId={testIds.refreshButton}
            >
              <RedoOutlined />
              <span>Refresh patient diagnosis</span>
            </Button>
          )}
        </div>
        <Table
          showBoxContainer={false}
          testId={testIds.table}
          dataSource={rows}
          pagination={false}
          columns={columns}
          noToolsSection
          scroll={{ x: true }}
          loading={isRefetching}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>
            {`Is the patient's condition related to...`}
          </span>
        </div>
        <div>
          {patientConditionRelationActiveFields.map((el) => (
            <InlineField {...el} key={el.name} disabled={readOnly} />
          ))}
        </div>
      </div>
    </Card>
  )
}

export default Diagnosis
