import { datadogRum } from '@datadog/browser-rum'
import { useFeatureFlags } from 'libs/featureFlags'
import { DATADOG_ACTION } from 'v2/datadog/datadogActions'

export type ClaimsErrorLogForExternalMonitorParams = {
  patientId: string
  providerId: string
  claimId: string
  claimsVersion?: number
  message: string
  error?: unknown
}

/**
 * Sends log to 3rd party
 * - This could be expensive, therefore caller is recommended to conditionally call this based on a feature flag
 */
export const useClaimsErrorLogForExternalMonitor = () => {
  const { enableClaimsExternalMonitoringLogs } = useFeatureFlags()
  function logClaimsErrorToExternalMonitor({
    patientId,
    providerId,
    claimId,
    claimsVersion = 2,
    message,
    error,
  }: ClaimsErrorLogForExternalMonitorParams) {
    if (enableClaimsExternalMonitoringLogs)
      datadogRum.addAction(DATADOG_ACTION.OsmindClaims.ClaimsCreationError, {
        patientId,
        providerId,
        claimId,
        claimsVersion,
        message,
        error,
      })
  }

  return {
    logClaimsErrorToExternalMonitor,
  }
}
