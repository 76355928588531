import { useEffect, useRef, useState } from 'react'

import { Form } from 'antd'
import { get } from 'lodash'

import { FormSectionKey, OsmindIntakeFormTitle } from '../../../api/intakeForms'
import {
  ProviderSideIntakeFormEvents,
  SendIntakeFormEvent,
  trackIntakeFormEvent,
} from '../../../libs/freshpaint/intakeFormEvents'
import { ProviderHomePageData } from '../../../shared-types'
import { Modal as AntDModal } from '../../BaseComponents'
import { CustomIntakeFormItems } from './CustomIntakeCheckBoxes'
import { DefaultIntakeFormItems } from './DefaultIntakeCheckboxes'
import IntakeForms, { IntakeFormHandler } from './IntakeForms'

import styles from './SendIntakeFormsModal.module.scss'

interface Props {
  patientEmail: string
  patientName: string
  publicId: string
  providerData: Pick<ProviderHomePageData, 'hellosignTemplates'>
  show: boolean
  onSubmit: () => void
  setShow: (show: boolean) => void
  hasDefaultIntakeForm?: boolean
}

interface FormFields {
  [DefaultIntakeFormItems.formSectionsSwitch]: boolean
  [DefaultIntakeFormItems.formSections]: FormSectionKey[]
  [CustomIntakeFormItems.formCustomOneWayIntakes]: string[]
  [CustomIntakeFormItems.formCustomTwoWayIntakes]: string[]
}

export default function SendIntakeFormsModal({
  patientEmail,
  patientName,
  publicId,
  providerData,
  show,
  setShow,
  onSubmit,
  hasDefaultIntakeForm,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [updateHasDefaultIntakeForm, setUpdateHasDefaultIntakeForm] =
    useState(false)
  const intakeFormRef = useRef<IntakeFormHandler>(null)
  const [form] = Form.useForm<FormFields>()
  const formSectionsSwitch = Form.useWatch(
    DefaultIntakeFormItems.formSectionsSwitch,
    form
  )
  const formCustomOneWayIntakes = Form.useWatch(
    CustomIntakeFormItems.formCustomOneWayIntakes,
    form
  )
  const formCustomTwoWayIntakes = Form.useWatch(
    CustomIntakeFormItems.formCustomTwoWayIntakes,
    form
  )

  const canSendForms =
    formSectionsSwitch ||
    formCustomOneWayIntakes?.length ||
    formCustomTwoWayIntakes?.length

  useEffect(() => {
    if (form && hasDefaultIntakeForm) {
      if (show) {
        setUpdateHasDefaultIntakeForm(true)
      } else {
        setUpdateHasDefaultIntakeForm(false)
      }
    }
  }, [show])

  const resetFields = () => {
    form.setFields([
      { name: CustomIntakeFormItems.formCustomOneWayIntakes, value: [] },
      { name: CustomIntakeFormItems.formCustomTwoWayIntakes, value: [] },
    ])
  }

  async function sendForms() {
    setLoading(true)
    if (intakeFormRef.current) {
      let sendPatientIntakeFormsFrespaintEvent: SendIntakeFormEvent = {
        osmindIntakeId: '',
        osmindIntakeName: '',
        osmindIntakeFormSections: [],
        hellosignFormNames: [],
        sentFormTypes: [],
      }

      await intakeFormRef.current.sendDefaultIntakeForms(
        publicId,
        patientName,
        (res) => {
          const { osmindIntakeId, osmindIntakeName, osmindIntakeFormSections } =
            res
          sendPatientIntakeFormsFrespaintEvent = {
            ...sendPatientIntakeFormsFrespaintEvent,
            osmindIntakeId,
            osmindIntakeName,
            osmindIntakeFormSections,
            sentFormTypes: [OsmindIntakeFormTitle],
          }
        }
      )

      const practiceName = get(providerData, 'clinicData.PracticeName', '')
      const providerEmail = get(providerData, 'clinicData.ProviderEmail', '')

      await intakeFormRef.current.sendCustomIntakeForms(
        patientEmail,
        patientName,
        providerEmail,
        practiceName,
        publicId,
        (res) => {
          sendPatientIntakeFormsFrespaintEvent.hellosignFormNames = res
          if (sendPatientIntakeFormsFrespaintEvent.hellosignFormNames.length) {
            sendPatientIntakeFormsFrespaintEvent.sentFormTypes.push('HelloSign')
          }
        }
      )

      trackIntakeFormEvent(
        ProviderSideIntakeFormEvents.SENT_FORM,
        sendPatientIntakeFormsFrespaintEvent
      )
      onSubmit()
      setShow(false)
      resetFields()
    }
    setLoading(false)
  }

  return (
    <AntDModal
      title={`Send forms to ${patientName}`}
      visible={show}
      bodyStyle={{ height: 1100 }}
      onCancel={() => {
        if (loading) {
          return
        }
        setShow(false)
        resetFields()
      }}
      wrapClassName="fixed-height"
      okText="Send Forms"
      onOk={sendForms}
      confirmLoading={loading}
      closable={!loading}
      isOkayDisabled={!canSendForms}
    >
      <Form form={form} layout="vertical" disabled={loading}>
        <div className={styles.lightBlueCallout}>
          Patients can access their forms from the Osmind app. Incomplete forms
          are automatically archived after 30 days.
        </div>
        <IntakeForms
          form={form}
          providerData={providerData}
          ref={intakeFormRef}
          hasDefaultIntakeForm={updateHasDefaultIntakeForm}
        />
      </Form>
    </AntDModal>
  )
}
