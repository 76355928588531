import { Key, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Card, Table, TableColumnsType, TableProps } from 'antd'
import { useHistory } from 'react-router-dom'

import { getNoteTemplateUrl } from '../../containers/Patient/ClinicalNotes/utils'
import {
  NoteTemplateEvent,
  trackNoteTemplateEvent,
} from '../../libs/freshpaint/notesTemplatesEvents'
import { NoteTemplateWithoutContent } from '../../v2/notes/Components/Templates/types'
import { ListNoteTemplatesSortParam } from '../../v2/notes/api'
import { NOTE_TEMPLATES_QUERY_KEY } from '../../v2/notes/constants'
import { useListNoteTemplates } from '../../v2/notes/hooks/templates/useListNoteTemplates'
import { Button } from '../BaseComponents'
import { NoteTemplateCreateOrEditDialog } from './NoteTemplateCreateOrEditDialog'
import { NoteTemplateTableRowActions } from './NoteTemplateTableRowActions'

import sharedStyles from '../../containers/_shared.module.scss'
import styles from './NoteTemplateSettings.module.scss'

export const DEFAULT_PAGE_SIZE = 20

type NoteTemplateTableData = NoteTemplateWithoutContent & {
  key: Key
}

type NoteTemplateSettingsProps = {
  providerId: string
  clinicId: string
}

export function NoteTemplateSettings({
  providerId,
  clinicId,
}: NoteTemplateSettingsProps) {
  const queryClient = useQueryClient()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  const [sorts, setSorts] = useState<ListNoteTemplatesSortParam[] | undefined>([
    {
      field: 'name',
      order: 'asc',
    },
  ])
  const { isLoading, response, isError } = useListNoteTemplates({
    currentPage,
    pageSize: DEFAULT_PAGE_SIZE,
    sorts,
  })
  const [isCreationDialogVisible, setIsCreationDialogVisible] = useState(false)

  function toTemplateEditor(templateId: string) {
    history.push(getNoteTemplateUrl({ templateId }))
  }

  function handleTemplateCreated(templateId: string) {
    setIsCreationDialogVisible(false)
    toTemplateEditor(templateId)
    trackNoteTemplateEvent(NoteTemplateEvent.SETUP_NOTE_TEMPLATE_NAME, {
      clinicId,
      providerId,
    })
  }

  function handleTemplateNameEdited() {
    // For now, we're just invalidating the current list view page and refetching.
    // This could be optimized to directly update the single template in cache, since we
    // only edited 1 template.
    queryClient.invalidateQueries([NOTE_TEMPLATES_QUERY_KEY])
  }

  function handleTemplateEdited(templateId: string) {
    toTemplateEditor(templateId)
  }

  function handleTemplateDeleted(_templateId: string) {
    const newTotalCount = (response?.totalCount || 1) - 1
    const newPage = Math.max(Math.ceil(newTotalCount / DEFAULT_PAGE_SIZE), 1)
    if (currentPage > newPage) {
      // Refetch will happen automatically since we're changing pages.
      setCurrentPage(newPage)
    } else {
      // Deletion keeps us on the same page - just invalidate queries to refetch.
      queryClient.invalidateQueries([NOTE_TEMPLATES_QUERY_KEY])
    }
  }

  const columns: TableColumnsType<NoteTemplateTableData> = [
    {
      title: 'Template name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (record: NoteTemplateTableData) => (
        <NoteTemplateTableRowActions
          templateId={record.uuid}
          templateName={record.name}
          templateDescription={record.description}
          onTemplateNameEdited={() => handleTemplateNameEdited()}
          onTemplateEdited={() => handleTemplateEdited(record.uuid)}
          onTemplateDeleted={() => handleTemplateDeleted(record.uuid)}
        />
      ),
    },
  ]

  const handleTableChange: TableProps<NoteTemplateTableData>['onChange'] = (
    pagination,
    _filters,
    sorter
  ) => {
    setCurrentPage(pagination.current ?? 0)

    if (sorter && !Array.isArray(sorter) && sorter.order) {
      setSorts([
        {
          field: 'name',
          order: sorter.order === 'ascend' ? 'asc' : 'desc',
        },
      ])
    } else {
      setSorts(undefined)
    }
  }
  const handleNewNoteTemplateClicked = () => {
    trackNoteTemplateEvent(NoteTemplateEvent.NEW_NOTE_TEMPLATE, {
      clinicId,
      providerId,
    })
    setIsCreationDialogVisible(true)
  }

  return (
    <div className={sharedStyles.scroll}>
      <div className={sharedStyles.spacedContainer}>
        <Card bordered={false}>
          <div className="headerWrapper headerWrapper_row">
            <span className="headerWrapper_title">Note templates</span>
            <div className={styles.tableHeaderContainerRight}>
              <Button
                data-testid={`NoteTemplateSettings--NewTemplate`}
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleNewNoteTemplateClicked}
              >
                New template
              </Button>
            </div>
          </div>
          <div className={styles.supportArticleContainer}>
            <span>
              Create and configure custom note templates to generate new notes
              from.
            </span>
          </div>
          <Table
            columns={columns}
            dataSource={
              isLoading
                ? []
                : response?.data.map((n) => ({
                    key: n.uuid,
                    uuid: n.uuid,
                    name: n.name,
                    description: n.description,
                  }))
            }
            pagination={{
              pageSize: DEFAULT_PAGE_SIZE,
              showSizeChanger: false,
              total: response?.totalCount,
            }}
            locale={{
              emptyText: isError
                ? 'Error loading data. Refresh and try again.'
                : 'Your custom templates will show up here. Create your first by clicking on “New template” at the top.',
            }}
            onChange={handleTableChange}
            loading={isLoading}
          />
          <NoteTemplateCreateOrEditDialog
            mode={'create'}
            visible={isCreationDialogVisible}
            onTemplateCreated={handleTemplateCreated}
            onCancel={() => setIsCreationDialogVisible(false)}
          />
        </Card>
      </div>
    </div>
  )
}
