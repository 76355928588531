import { useCallback, useMemo, useState } from 'react'

import { MISSING_CLAIM_ID_ERROR_MESSAGE } from 'containers/Patient/ClaimsV2/ErrorHandling/Errors'
import { buildClaimRoute } from 'containers/Patient/ClaimsV2/utils'
import { useFeatureFlags } from 'libs/featureFlags'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { deleteClinicalNote } from '../../api/api-lib'
import { onError } from '../../libs/errorLib'
import { notification } from '../../libs/notificationLib'
import {
  NotificationType,
  generateNotification,
} from '../../stories/BaseComponents/Notification'
import { routeParameters } from '../../stories/Invoice/InvoiceForm'
import { NoteType } from '../useClinicalNoteFields/utils'

export const useClinicalNotesButtonActions = (
  hasInvoice: boolean,
  patientId: string,
  providerId: string,
  noteId: string,
  fields?: NoteType,
  updateCacheWithNav?: () => Promise<void>,
  handleClose?: () => void
) => {
  const { claims } = useFeatureFlags()
  const history = useHistory()
  const [modalShow, setModalShow] = useState(false)

  const handleModalClose = () => {
    setModalShow(false)
  }

  const handleShowDeleteModal = () => {
    setModalShow(true)
  }

  const handleDeleteClinicalNote = useCallback(async () => {
    try {
      if (!noteId) {
        notification(
          'This note does not appear to exist. Please check the notes list and try again.',
          NotificationType.ERROR
        )
        return
      }
      await deleteClinicalNote({
        PatientId: patientId,
        NoteId: noteId,
      })
      updateCacheWithNav && updateCacheWithNav()
      notification(
        'You have successfully deleted a clinical note. Please wait a moment to see your changes.',
        NotificationType.SUCCESS
      )
    } catch (e) {
      onError(
        e,
        500,
        'There was an internal error processing your request. Please inform your administrator.'
      )
    } finally {
      handleClose && handleClose()
      handleModalClose()
    }
  }, [patientId, noteId, updateCacheWithNav, handleClose, handleModalClose])

  const DeleteNoteModalComponent = useMemo(() => {
    return (
      <Modal
        show={modalShow}
        onHide={handleModalClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="delete-modal vertical-align-center front-modal"
        backdropClassName="backdrop"
        dialogClassName="react-bs-legacy-delete-modal"
        centered
      >
        <Modal.Header closeButton className="modal-full-header">
          <Modal.Title id="contained-modal-title-vcenter">
            <i
              className="fa fa-exclamation-circle fa-lg delete-icon"
              aria-hidden="true"
            />
            &nbsp; Are you sure?&nbsp;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this note? This action cannot be
            undone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleModalClose}
            variant="secondary"
            className="cancel-btn"
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteClinicalNote} className="danger-btn">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }, [modalShow])

  const buildInvoiceRoute = (
    patientId: string,
    options: Record<string, string | boolean | undefined>
  ) => {
    return Object.keys(options).reduce((acc, key) => {
      if (!options[key]) {
        return acc
      }
      return `${acc}&${key}=${options[key]}`
    }, `/invoice?patientId=${patientId}`)
  }

  const navigateToInvoice = useCallback(
    (createSuperbill?: boolean) => {
      const options = {
        [routeParameters.invoiceUuid]: hasInvoice && fields?.invoice.uuid,
        [routeParameters.noteId]: !hasInvoice && noteId,
        [routeParameters.createSuperbill]: createSuperbill,
      }

      history.push(buildInvoiceRoute(patientId, options))
    },
    [noteId, fields?.invoice]
  )

  const navigateToClaim = useCallback(() => {
    // do nothing is claims feature is not available
    if (!claims) {
      return
    }

    const claimId =
      fields?.InsuranceClaim[fields?.InsuranceClaim.length - 1].uuid

    if (claimId?.length) {
      const claimRoute = buildClaimRoute({ claimId, patientId, providerId })
      history.push(claimRoute)
    } else {
      generateNotification(MISSING_CLAIM_ID_ERROR_MESSAGE)
    }
  }, [noteId, fields?.InsuranceClaim])

  return {
    navigateToInvoice,
    /**
     * This will only navigate to an existing claim by Claim ID
     * - It previously might have been used for creating a Claim and then navigating to it
     */
    navigateToClaim,
    DeleteNoteModalComponent,
    handleShowDeleteModal,
    modalShow,
  }
}
