import React, { useCallback, useMemo, useState } from 'react'

import { Form } from 'antd'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

import { activateNewPractice } from '../../api/api-lib'
import useQueryString from '../../hooks/useQueryString'
import { useAppContext } from '../../libs/contextLib'
import { setFreshpaintUserAndGroupProperties } from '../../libs/freshpaint'
import { notification } from '../../libs/notificationLib'
import SignupPracticeCard, {
  FormValues,
} from '../../stories/Authentication/SignupPracticeCard'
import { Address } from '../../stories/BaseComponents/AddressAutocomplete'

const ActivatePractice: React.FC = () => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const { setIsAuthenticating } = useAppContext()
  const [form] = Form.useForm()
  const query = useQueryString()
  const history = useHistory()

  const initialValues: FormValues = useMemo((): FormValues => {
    return {
      confirmPassword: '',
      password: '',

      practiceName: '',
      firstName: '',
      middleName: '',
      lastName: '',
      phoneNumber: '',

      qikCode: '',
      npiNumber: '',
      practiceNpiNumber: '',
      ein: '',

      credential: '',
      specialty: '',
      practiceAddress: {
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Zipcode: '',
      },
    }
  }, [])

  const activationId = query.get('activationId')
  if (!activationId) {
    history.push('/login')
  }

  const handleAddressChange = useCallback((values: Address) => {
    const updateItem = form.getFieldsValue() as FormValues
    if (updateItem.practiceAddress) {
      updateItem.practiceAddress.Address1 = values?.Address1 ?? ''
      updateItem.practiceAddress.Address2 = values?.Address2 ?? ''
      updateItem.practiceAddress.City = values?.City ?? ''
      updateItem.practiceAddress.State = values?.State ?? ''
      updateItem.practiceAddress.Zipcode = values?.Zipcode ?? ''
      form.setFieldsValue(updateItem)
    }
  }, [])

  const handleSubmit = async (values: FormValues) => {
    setSubmitting(true)
    try {
      const activationResponse = await activateNewPractice({
        Password: values.password,

        PracticeName: values.practiceName,
        FirstName: values.firstName,
        MiddleName: values.middleName,
        LastName: values.lastName,
        Phone: values.phoneNumber,

        QikCode: values.qikCode,
        NpiNumber: values.npiNumber,
        EIN: values.ein,
        PracticeNpiNumber: values.practiceNpiNumber,

        Credential: values.credential,
        Specialty: values.specialty,

        ProviderPracticeAddress: values.practiceAddress.Address1,
        ProviderPracticeAddressExtn: values.practiceAddress.Address2,
        ProviderPracticeCity: values.practiceAddress.City,
        ProviderPracticeState: values.practiceAddress.State,
        ProviderPracticeZip: values.practiceAddress.Zipcode,
        ActivationId: activationId,
      })
      notification('You have successfully activated your account.', 'success')
      const newUserEmail = activationResponse.email
      await Auth.signIn(newUserEmail.toLowerCase(), values.password)
      history.push('/')
      setIsAuthenticating(true)
      await setFreshpaintUserAndGroupProperties()
    } catch (e: any) {
      const errorResponse = e?.response?.data
      if (errorResponse?.error === 'ActivationLinkExpired') {
        notification(
          'You activation link is expired. Please ask your onboarding contact to resend the invitation.',
          'error'
        )
      } else if (errorResponse?.error === 'UserAlreadyExists') {
        notification(
          'Account could not be activated because an active account with this email already exists. Please log in to the existing account.',
          'error'
        )
      } else {
        notification(
          'Account activation failed. Please contact admin for assistance.',
          'error'
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <SignupPracticeCard
      form={form}
      handleAddressChange={handleAddressChange}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isLoading={isSubmitting}
    />
  )
}

export default ActivatePractice
