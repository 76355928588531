import { NodeSelection } from '@tiptap/pm/state'
import { Editor, FocusPosition, Range } from '@tiptap/react'

export const determineInsertLocation = (editor: Editor): number | Range => {
  // if user has selected a node, insert after that node
  const isNode = !!(editor.state?.selection as NodeSelection)?.node
  if (isNode) {
    return editor.state.selection.to
  }

  // if user has selected a range of text, replace that selection
  const isRange =
    editor.state?.selection?.$to?.pos - editor.state?.selection?.$from?.pos > 1

  if (isRange) {
    return {
      from: editor.state.selection.$from.pos,
      to: editor.state.selection.$to.pos,
    }
  }

  // otherwise, insert at the cursor position
  return editor.state.selection.$head.pos
}

export const insertBlockInEditor = (
  editor: Editor | undefined,
  block: string
) => {
  if (!editor) {
    console.error('Trying to insert content into a nonexistent editor!')
    return
  }

  const location = determineInsertLocation(editor)

  // When adding a block, we want to focus on the next position after the block.
  const focusLocation = (
    Number.isInteger(location)
      ? // Current location + 1 to select the block + 1 to select the next position.
        (location as number) + 2
      : // Cursor position immediately after the end of the previous content.
        (location as Range).to + 1
  ) as FocusPosition

  // Separating commands. When chained, the addition of the block
  // will be highlighted after addition, as compared to placing
  // the cursor after the inserted block
  editor.commands.insertContentAt(location, block)
  editor.commands.focus(focusLocation)
}
