import { PATIENT_CONDITION_RELATION } from '../field-constants'
import {
  BoolOptionValue,
  ClaimForm,
  ClaimFormDiagnosis,
  ClaimFormDiagnosisRow,
  ClaimFormDiagnosisSeparator,
} from '../types'
import { removeInvalidDxPointersFromLineItems } from '../utils'

export const SEPARATOR_ROW: ClaimFormDiagnosisSeparator = {
  title: 'Excluded from claim',
}

export const EXCLUDED_DIAGNOSIS_ORDER_DISPLAY = '—'

export const getDiagnosisRows = (
  diagnoses: ClaimFormDiagnosis[]
): ClaimFormDiagnosisRow[] => {
  const includedSection = diagnoses.filter((d) => !d.isExcluded)

  const excludedSection = diagnoses.filter((d) => d.isExcluded)

  if (excludedSection.length > 0) {
    return [...includedSection, SEPARATOR_ROW, ...excludedSection]
  } else {
    return includedSection
  }
}

export const isSeparatorRow = (
  row: ClaimFormDiagnosisRow
): row is ClaimFormDiagnosisSeparator => {
  return (row as ClaimFormDiagnosisSeparator).title !== undefined
}

export const getOrderDisplay = (
  record: ClaimFormDiagnosisRow,
  index: number
) => {
  if (isSeparatorRow(record)) {
    return record.title
  }
  return record.isExcluded ? EXCLUDED_DIAGNOSIS_ORDER_DISPLAY : index + 1
}

export const expandOnSeparatorRow = (record: ClaimFormDiagnosisRow) => ({
  colSpan: isSeparatorRow(record) ? 4 : 1, // takes the whole row
})

export const shrinkOnSeparatorRow = (record: ClaimFormDiagnosisRow) => ({
  colSpan: isSeparatorRow(record) ? 0 : 1,
})

export const toggleInclude = (
  diagnoses: ClaimFormDiagnosis[],
  selectedDiagnosisId: string
) => {
  const newDiagnoses = diagnoses.map((d) => {
    const newDiagnosis = { ...d }
    if (d.diagnosisId === selectedDiagnosisId) {
      newDiagnosis.isExcluded = !d.isExcluded
    }
    return newDiagnosis
  })

  return newDiagnoses
}

export const getPatientConditionRelationActiveFields = (
  autoAccident: BoolOptionValue | null
) => {
  if (autoAccident === 'yes') {
    return PATIENT_CONDITION_RELATION
  } else {
    return PATIENT_CONDITION_RELATION.filter(
      (field) => field.name !== 'patientConditionRelation.accidentState'
    )
  }
}

export const getNewValuesOnDiagnosesChange = (
  values: ClaimForm,
  newDiagnoses: ClaimFormDiagnosis[]
): ClaimForm => ({
  ...values,
  diagnoses: newDiagnoses,
  lineItems: removeInvalidDxPointersFromLineItems(
    values.lineItems,
    newDiagnoses
  ),
})
