export const ErrorIcon = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 4.5C18.6047 4.5 4.5 18.6047 4.5 36C4.5 53.3953 18.6047 67.5 36 67.5C53.3953 67.5 67.5 53.3953 67.5 36C67.5 18.6047 53.3953 4.5 36 4.5ZM47.6297 47.9672L42.9891 47.9461L36 39.6141L29.018 47.9391L24.3703 47.9602C24.0609 47.9602 23.8078 47.7141 23.8078 47.3977C23.8078 47.2641 23.857 47.1375 23.9414 47.032L33.0891 36.1336L23.9414 25.2422C23.8564 25.1391 23.8093 25.0101 23.8078 24.8766C23.8078 24.5672 24.0609 24.3141 24.3703 24.3141L29.018 24.3352L36 32.6672L42.982 24.3422L47.6227 24.3211C47.932 24.3211 48.1852 24.5672 48.1852 24.8836C48.1852 25.0172 48.1359 25.1438 48.0516 25.2492L38.918 36.1406L48.0586 47.0391C48.143 47.1445 48.1922 47.2711 48.1922 47.4047C48.1922 47.7141 47.9391 47.9672 47.6297 47.9672Z"
        fill="#F5222D"
      />
    </svg>
  )
}
