import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { cloneDeep } from 'lodash'

import { setPaymentInfo, setValueOrigin } from '../../../api/insuranceClaims'
import { useStripeAccountStatus } from '../../../hooks/useBillingInfo'
import { QueryKeys } from '../../../hooks/useInsuranceClaims'
import { calculateClaimAndPatientOutstanding } from '../../../libs/billing'
import {
  CLAIM_STATUS,
  InsuranceClaim,
  PaymentAttempt,
  RefundAttempt,
} from '../../../shared-types'
import { restrictDecimalOnKeyPressInputEvent } from '../../../shared/Helpers/utils'
import {
  Button,
  Col,
  Divider,
  InputNumber,
  Row,
  Text,
  Tooltip,
} from '../../../stories/BaseComponents'
import { normalizeTransactions } from '../../../stories/Invoice/ChargeBreakdown/ChargeBreakdown'
import {
  TransactionCollapse,
  TransactionType,
} from '../../../stories/Invoice/ChargeBreakdown/TransactionCollapse'
import PaymentModals from '../Billing/PaymentModals'
import { ClaimData, ClaimStatus } from '../ClaimsV2/types'
import {
  InsurancePaymentOriginType,
  InsurancePaymentType,
  PatientResponsibilityType,
  PaymentInfoType,
  WriteOffType,
} from './Payment-helper'

import styles from './InsuranceClaim.module.scss'

export interface PaymentsProps {
  testId?: string
  claimId: string
  claim: InsuranceClaim | ClaimData
  paymentInfo: PaymentInfoType
  refetch: Function
  refetchClaim: Function
  isLoading: boolean
  companyToSubmitTo: string | null
  editPayments: boolean
  setEditPayments: Function
  claimStatus: CLAIM_STATUS | ClaimStatus
  patientId: string
  payments: PaymentAttempt[]
  refunds: RefundAttempt[]
}

export function getLatestUpdatedDate(dates: string[]): string | null {
  if (dates.length === 0) {
    return null
  }

  let latestDate: Date | null = null

  for (const dateString of dates) {
    const currentDate = new Date(dateString)

    if (!isNaN(currentDate.getTime())) {
      // If it's a valid date, check if it's later than the current latestDate
      if (latestDate === null || currentDate > latestDate) {
        latestDate = currentDate
      }
    }
  }

  return latestDate ? latestDate.toISOString() : null
}

/**
 * Formats a cent value for display. If the number is negative, it's displayed with parentheses instead of a minus sign.
 *
 * @param {number | string} the money number to format in cents.
 * @returns {string} The formatted number. If the input number is negative, the output is a string with the absolute value of the number surrounded by parentheses. If the input number is positive or zero, the output is the same as the input number.
 */
export function formatAsDollar(number: number | string) {
  const num = typeof number === 'string' ? parseFloat(number) : number
  if (isNaN(num)) {
    return `$0.00`
  }
  const dollarString = `$${Math.abs(num / 100).toFixed(2)}`
  return num < 0 ? `(${dollarString})` : dollarString
}

function isUpdatedPatientValuesTooltipEnabled(
  createdAtERA: string | null,
  insurancePaymentCreatedAt: string | null,
  patientResponsibilityCreatedAt: string | null,
  writeOffCreatedAt: string | null
) {
  //If some of the insurance payment, patient responsibility or write off are created after the ERA date, show the tooltip
  if (!createdAtERA) {
    return false
  }

  const dates = [
    insurancePaymentCreatedAt,
    patientResponsibilityCreatedAt,
    writeOffCreatedAt,
  ]

  //remove all null values
  const latestUpdatedDate = getLatestUpdatedDate(
    dates.filter((date) => date !== null) as []
  )

  if (!latestUpdatedDate) {
    return false
  }

  const latestUpdatedDateObject = new Date(latestUpdatedDate)
  const createdAtERAObject = new Date(createdAtERA)

  return latestUpdatedDateObject < createdAtERAObject
}

export const MISSING_VALUE = '—'

const Payments: React.FC<PaymentsProps> = ({
  testId,
  claim,
  paymentInfo,
  refetch,
  refetchClaim,
  claimId,
  isLoading,
  companyToSubmitTo,
  claimStatus,
  editPayments,
  setEditPayments,
  patientId,
  payments,
  refunds,
}) => {
  const [isPaymentERAactive, setIsPaymentERAactive] = useState<boolean>(false)
  const [isResponsibilityERAactive, setIsResponsibilityERAactive] =
    useState<boolean>(false)
  const [writeOffWidth, setWriteOffWidth] = useState<number>(142)
  const [insurancePaymentWidth, setInsurancePaymentWidth] =
    useState<number>(142)
  const [patientResponsibilityWidth, setPatientResponsibilityWidth] =
    useState<number>(142)
  const [total, setTotal] = useState<number>(0)
  const [totalPatientOutstanding, setTotalPatientOutstanding] =
    useState<number>(0)
  const [totalClaimOutstanding, setTotalClaimOutstanding] = useState<number>(0)
  const [insurancePayment, setInsurancePayment] =
    useState<InsurancePaymentType | null>(null)
  const [shouldShowInsurancePayment, setShouldShowInsurancePayment] =
    useState<boolean>(false)
  const [patientResponsibility, setPatientResponsibility] =
    useState<PatientResponsibilityType | null>(null)
  const [shouldShowPatientResponsibility, setShouldShowPatientResponsibility] =
    useState<boolean>(false)
  const [
    loadingSaveOriginInsurancePayment,
    setLoadingSaveOriginInsurancePayment,
  ] = useState<boolean>(false)
  const [isAddPaymentModalLoading, setIsAddPaymentModalLoading] =
    useState(false)
  const [loadingSaveOriginManualPayment, setLoadingSaveOriginManualPayment] =
    useState<boolean>(false)
  const [writeOff, setWriteOff] = useState<WriteOffType | null>(null)
  const [loadingPaymentsChanges, setLoadingPaymentsChanges] =
    useState<boolean>(false)
  const [disableEdit, setDisableEdit] = useState<boolean>(true)
  const [notPaidByInsurance, setNotPaidByInsurance] = useState<number>(0)
  const [patientResponsibilityToShow, setPatientResponsibilityToShow] =
    useState<number>(0)

  const [isSavingAndPaying, setIsSavingAndPaying] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const {
    isLoading: stripeAccountStatusIsLoading,
    data: stripeAccountStatusData,
  } = useStripeAccountStatus()

  const queryClient = useQueryClient()

  useEffect(() => {
    setDisableEdit(!editPayments)
  }, [editPayments])

  useEffect(() => {
    // setting the amount not paid by insurance and the amount paid by insurance depending on the ERA selection
    if (
      insurancePayment?.amountOfCents ||
      insurancePayment?.amountOfCents === 0 ||
      insurancePayment?.amountOfCentsERA ||
      insurancePayment?.amountOfCentsERA === 0
    ) {
      let res
      if (insurancePayment?.isERAPaymentActive) {
        res = total - (insurancePayment?.amountOfCentsERA || 0)
      } else {
        res = total - (insurancePayment?.amountOfCents || 0)
      }
      setNotPaidByInsurance(res ?? 0)
      setIsPaymentERAactive(insurancePayment?.isERAPaymentActive ?? false)
    }
  }, [
    total,
    insurancePayment?.isERAPaymentActive,
    insurancePayment?.amountOfCentsERA,
    insurancePayment?.amountOfCents,
  ])

  useEffect(() => {
    // setting the amount not paid by insurance and the amount paid by insurance depending on the ERA selection
    if (
      patientResponsibility?.amountOfCents ||
      patientResponsibility?.amountOfCents === 0 ||
      patientResponsibility?.amountOfCentsERA ||
      patientResponsibility?.amountOfCentsERA === 0
    ) {
      const res = patientResponsibility?.isERAPatientResponsibilityActive
        ? patientResponsibility?.amountOfCentsERA
        : patientResponsibility?.amountOfCents
      setPatientResponsibilityToShow(res)
    }
    setIsResponsibilityERAactive(
      patientResponsibility?.isERAPatientResponsibilityActive || false
    )
  }, [
    patientResponsibility?.amountOfCents,
    patientResponsibility?.amountOfCentsERA,
    patientResponsibility?.isERAPatientResponsibilityActive,
  ])

  const normalizedPayments = useMemo(
    () =>
      normalizeTransactions(claimId, payments, TransactionType.PAYMENT).filter(
        (payment) => payment.amountCents !== 0
      ),
    [payments]
  )
  const normalizedRefunds = useMemo(
    () =>
      normalizeTransactions(claimId, refunds, TransactionType.REFUND).filter(
        (payment) => payment.amountCents !== 0
      ),
    [refunds]
  )

  function setWriteOffWidthFunction(value: number) {
    if (writeOff?.amountOfCents) {
      const numberLenght = (Math.log(value) * Math.LOG10E + 1) | 0
      if (numberLenght < 6) {
        setWriteOffWidth(137)
      } else if (numberLenght > 5) {
        setWriteOffWidth(137 + (numberLenght - 5) * 7)
      }
    } else {
      setWriteOffWidth(137)
    }
  }

  function setInsurancePaymentWidthFunc(value: number) {
    if (insurancePayment?.amountOfCents) {
      const numberLenght = (Math.log(value) * Math.LOG10E + 1) | 0
      if (numberLenght < 6) {
        setInsurancePaymentWidth(137)
      } else if (numberLenght > 5) {
        setInsurancePaymentWidth(137 + (numberLenght - 5) * 7)
      }
    } else {
      setInsurancePaymentWidth(137)
    }
  }

  function setPatientResponsibilityWidthFunc(value: number) {
    if (value) {
      const numberLenght = (Math.log(value) * Math.LOG10E + 1) | 0
      if (numberLenght < 6) {
        setPatientResponsibilityWidth(137)
      } else if (numberLenght > 5) {
        setPatientResponsibilityWidth(137 + (numberLenght - 5) * 7)
      }
    } else {
      setPatientResponsibilityWidth(137)
    }
  }

  function populatePaymentInfo() {
    //TODO: Get rid of call to the paymentInfo endpoint
    setInsurancePayment(paymentInfo?.insurancePayment || null)
    if (paymentInfo?.insurancePayment?.amountOfCents) {
      setInsurancePaymentWidthFunc(paymentInfo.insurancePayment.amountOfCents)
    }
    setPatientResponsibility(paymentInfo.patientResponsibility || null)
    if (paymentInfo?.patientResponsibility?.amountOfCents) {
      setPatientResponsibilityWidthFunc(
        paymentInfo.patientResponsibility.amountOfCents
      )
    }
    setWriteOff(paymentInfo.writeOff || null)
    if (paymentInfo?.writeOff?.amountOfCents) {
      setWriteOffWidthFunction(paymentInfo.writeOff.amountOfCents)
    }
  }

  const createAndShowPaymentsModal = async () => {
    setIsSavingAndPaying(true)
    setShowPaymentModal(true)
    setIsSavingAndPaying(false)
  }

  const isClaimPaid =
    (claim.patientResponsibilityAmountCents ?? 0) -
      (claim.patientPaidAmountCents ?? 0) ===
      0 && (claim.patientPaidAmountCents ?? 0) !== 0

  const areButtonsDisabled =
    stripeAccountStatusIsLoading || isAddPaymentModalLoading

  const stopLoadingButton = useCallback(
    () => setTimeout(() => setIsAddPaymentModalLoading(false), 500),
    []
  )

  useEffect(() => {
    if (!isLoading && claim) {
      // calculating the outstanding amount
      const { patientOutstanding, claimOutstanding } =
        calculateClaimAndPatientOutstanding(claim)
      setTotalPatientOutstanding(patientOutstanding)
      setTotalClaimOutstanding(claimOutstanding)
      setTotal(claim.billableEntity?.totalAmountCents || 0)
    }
  }, [claim, isLoading])

  useEffect(() => {
    if (!isLoading && paymentInfo) {
      populatePaymentInfo()
    }
  }, [isLoading, paymentInfo])

  const pendingSpan = <span className={styles.pending}>Pending</span>
  const boldPendingSpan = <span className={styles.boldPending}>Pending</span>

  function formatDecimal(newValue: any) {
    if (!newValue && newValue !== 0) {
      return 0
    }

    const newValueString = newValue.toString()

    if (newValueString.includes('.')) {
      const decimals = newValueString.split('.')[1]
      if (decimals.length > 2) {
        return Number(newValueString.split('.')[0] + '.' + decimals.slice(0, 2))
      }
    }

    return newValue
  }

  function getAmountCents(
    isEraActive: boolean | undefined,
    payment: InsurancePaymentType | PatientResponsibilityType | null
  ) {
    return isEraActive
      ? payment?.amountOfCentsERA ?? 0
      : payment?.amountOfCents ?? 0
  }

  /**
   * Determines the origin of an insurance payment.
   *
   * @param {boolean} isPaymentInsurance - Indicates whether the switch is for an insurance payment of patient responsibility.
   * @returns {object} An object containing:
   * - isManual: A boolean indicating whether the payment is a manual entry or from an ERA.
   * - isPaymentInsurance: The original isPaymentInsurance parameter.
   * - insurancePaidAmountCents: The new amount of the payment in cents.
   * - patientResponsibilityAmountCents: The new amount of the patient's responsibility in cents.
   * - Note: If the user is switching payment insurance we do not want to update the patient responsibility,
   * - hence all the conditionals with isPaymentInsurance
   */
  function insurancePaymentOriginData(isPaymentInsurance: boolean) {
    const isManual = isPaymentInsurance
      ? !insurancePayment?.isERAPaymentActive
      : !patientResponsibility?.isERAPatientResponsibilityActive

    const patientResponsibilityAmountCents = getAmountCents(
      isPaymentInsurance
        ? patientResponsibility?.isERAPatientResponsibilityActive
        : !patientResponsibility?.isERAPatientResponsibilityActive,
      patientResponsibility
    )

    const insurancePaidAmountCents = getAmountCents(
      isPaymentInsurance
        ? !insurancePayment?.isERAPaymentActive
        : insurancePayment?.isERAPaymentActive,
      insurancePayment
    )

    return {
      isManual,
      isPaymentInsurance,
      insurancePaidAmountCents,
      patientResponsibilityAmountCents,
    }
  }

  function handleWriteOffChange(newValue: any) {
    const valueInCents = Number((formatDecimal(newValue) * 100).toFixed(2))

    if (!writeOff) {
      setWriteOff({
        amountOfCents: Number(valueInCents),
        createdAt: new Date().toISOString(),
      })
    } else {
      const writeOffNewValue = cloneDeep(writeOff)
      writeOffNewValue.amountOfCents = valueInCents
      setWriteOff(writeOffNewValue)
    }
    setWriteOffWidthFunction(valueInCents)
  }

  function handleInsurancePaymentChange(newValue: any) {
    const valueInCents = Number((formatDecimal(newValue) * 100).toFixed(2))

    if (!insurancePayment) {
      setInsurancePayment({
        amountOfCents: valueInCents,
        createdAt: new Date().toISOString(),
        amountOfCentsERA: 0,
        isERAPaymentActive: false,
      })
    } else {
      const insurancePaymentNewValue = cloneDeep(insurancePayment)
      insurancePaymentNewValue.amountOfCents = valueInCents
      setInsurancePayment(insurancePaymentNewValue)
    }
    setInsurancePaymentWidthFunc(valueInCents)
  }

  function handlePatientResponsibilityChange(newValue: any) {
    const valueInCents = Number((formatDecimal(newValue) * 100).toFixed(2))

    if (!patientResponsibility) {
      setPatientResponsibility({
        amountOfCents: valueInCents,
        createdDate: new Date().toISOString(),
        amountOfCentsERA: 0,
        isERAPatientResponsibilityActive: false,
      })
    } else {
      const patientResponsibilityNewValue = cloneDeep(patientResponsibility)
      patientResponsibilityNewValue.amountOfCents = valueInCents
      setPatientResponsibility(patientResponsibilityNewValue)
    }
    setPatientResponsibilityWidthFunc(valueInCents)
  }

  function discardChanges() {
    populatePaymentInfo()
    setEditPayments(!editPayments)
    setLoadingPaymentsChanges(false)
  }

  async function handleSavePaymentsChanges() {
    setLoadingPaymentsChanges(true)
    try {
      await setPaymentInfo(
        claimId,
        patientId,
        writeOff?.amountOfCents,
        patientResponsibility?.amountOfCents,
        insurancePayment?.amountOfCents
      )
    } catch (e) {
      console.error(e)
    } finally {
      setEditPayments(!editPayments)
      setLoadingPaymentsChanges(false)
      refetch()
      queryClient.invalidateQueries([QueryKeys.INSURANCE_CLAIMS, patientId])
      refetchClaim()
    }
  }

  async function setInsurancePaymentOrigin(data: InsurancePaymentOriginType) {
    const {
      isPaymentInsurance,
      isManual,
      insurancePaidAmountCents,
      patientResponsibilityAmountCents,
    } = data
    isPaymentInsurance
      ? setLoadingSaveOriginInsurancePayment(true)
      : setLoadingSaveOriginManualPayment(true)
    try {
      await setValueOrigin(
        isPaymentInsurance
          ? {
              claimId,
              isERAPaymentActive: isManual,
              insurancePaidAmountCents,
              patientResponsibilityAmountCents,
              patientId,
            }
          : {
              claimId,
              isERAPatientResponsibilityActive: isManual,
              insurancePaidAmountCents,
              patientResponsibilityAmountCents,
              patientId,
            }
      )
    } catch (e) {
      console.error(e)
    } finally {
      refetch()
      refetchClaim()
      isPaymentInsurance
        ? setLoadingSaveOriginInsurancePayment(false)
        : setLoadingSaveOriginManualPayment(false)
    }
  }

  const isMissingBilledAmount = claim.billableEntity?.totalAmountCents === null

  return (
    <div className={styles.paymentContainer} data-testid={testId}>
      <div className={styles.paymentHeader}>
        <Text header="h4">Payment</Text>
        <span className={styles.lastRefreshed}>
          {`${paymentInfo?.createdAtERA ? 'ERA received' : 'Last refreshed'}: ${
            paymentInfo?.createdAtERA
              ? format(new Date(paymentInfo?.createdAtERA), 'MM/dd/yyyy')
              : format(new Date(), 'MM/dd/yyyy')
          }`}
        </span>
      </div>
      <Text
        header="h5"
        className={styles.totalOutstanding}
        test-id="total-claim-outstanding"
      >
        Total claim outstanding:{' '}
        {isMissingBilledAmount
          ? 'Unknown'
          : formatAsDollar(totalClaimOutstanding)}
      </Text>
      <Divider type="horizontal" style={{ marginTop: 15, marginBottom: 15 }} />
      <Row className={styles.mainRow}>
        <Col className={styles.minWidthPayment}>
          <Text header="h4">Insurance</Text>
          <div className={styles.marginVertical}>
            <Text className={styles.regularText}>{companyToSubmitTo}</Text>
          </div>
          <div className={styles.regularSpaceBetween}>
            <div className={styles.billedAmountLabel}>
              <Text className={styles.regularLeftText}>Billed</Text>
              {claim.isMissingSubmission && (
                <Tooltip
                  title={
                    isMissingBilledAmount
                      ? 'The billed amount is unavailable as it was not provided in the ERA'
                      : 'This amount is based on the ERA received'
                  }
                  placement="top"
                  overlayStyle={{ width: 200 }}
                >
                  <InfoCircleOutlined className={styles.tooltipIcon} />
                </Tooltip>
              )}
            </div>
            <span
              className={styles.fontSizeSmall}
              data-testid={'claim-payments-billed-amount'}
            >
              {isMissingBilledAmount ? MISSING_VALUE : formatAsDollar(total)}
            </span>
          </div>
          <div className={styles.middleSpaceBetween}>
            {!insurancePayment ? (
              'Payment'
            ) : (
              <div
                data-testid="insurance-payment-switch-dropdown-button"
                onClick={() =>
                  setShouldShowInsurancePayment(!shouldShowInsurancePayment)
                }
              >
                {`Insurance payment - ${
                  insurancePayment?.isERAPaymentActive &&
                  paymentInfo?.createdAtERA
                    ? 'ERA'
                    : 'Manual entry ' +
                      (insurancePayment.createdAt
                        ? '(' +
                          format(
                            new Date(insurancePayment.createdAt),
                            'MM/dd/yyyy'
                          ) +
                          ')'
                        : '')
                }`}{' '}
                {paymentInfo?.createdAtERA &&
                  (!shouldShowInsurancePayment ? (
                    <DownOutlined className={styles.cursorHover} />
                  ) : (
                    <UpOutlined className={styles.cursorHover} />
                  ))}
              </div>
            )}
            <span className={styles.fontSizeSmall}>
              {isPaymentERAactive || disableEdit ? (
                !insurancePayment ? (
                  pendingSpan
                ) : (
                  formatAsDollar(
                    insurancePayment?.isERAPaymentActive
                      ? insurancePayment.amountOfCentsERA
                      : insurancePayment.amountOfCents
                  )
                )
              ) : (
                <InputNumber
                  className={styles.usdInput}
                  disabled={disableEdit}
                  testId="insurance-payment-input"
                  style={{ width: insurancePaymentWidth, marginRight: 70 }}
                  addonBefore="USD"
                  onChange={handleInsurancePaymentChange}
                  onKeyPress={restrictDecimalOnKeyPressInputEvent}
                  controls={false}
                  tabIndex={1}
                  precision={2}
                  step={0.1}
                  prefix="$"
                  value={
                    !insurancePayment?.amountOfCents &&
                    insurancePayment?.amountOfCents !== 0
                      ? ''
                      : `${insurancePayment.amountOfCents / 100}`
                  }
                />
              )}
            </span>
          </div>
          {paymentInfo?.createdAtERA &&
            insurancePayment &&
            shouldShowInsurancePayment && (
              <div className={styles.insurancePaymentDetailsBox}>
                <div className={styles.insurancePaymentDetails}>
                  <Text className={styles.greyFontSmallSize}>
                    {`Insurance payment - ${
                      !insurancePayment?.isERAPaymentActive
                        ? 'ERA'
                        : 'Manual entry ' +
                          (insurancePayment?.createdAt
                            ? '(' +
                              format(
                                new Date(insurancePayment?.createdAt),
                                'MM/dd/yyyy'
                              ) +
                              ')'
                            : '')
                    }`}
                  </Text>
                  {!isPaymentERAactive || disableEdit ? (
                    <Text className={styles.greyFontSmallSizeDecolored}>
                      {`($${(
                        (!insurancePayment?.isERAPaymentActive
                          ? insurancePayment?.amountOfCentsERA
                          : insurancePayment?.amountOfCents) / 100
                      ).toFixed(2)})`}
                    </Text>
                  ) : (
                    <InputNumber
                      className={styles.usdInput}
                      disabled={disableEdit}
                      testId="insurance-payment-input-drop-down"
                      style={{ width: insurancePaymentWidth }}
                      addonBefore="USD"
                      onChange={handleInsurancePaymentChange}
                      onKeyPress={restrictDecimalOnKeyPressInputEvent}
                      controls={false}
                      tabIndex={1}
                      precision={2}
                      step={0.1}
                      prefix="$"
                      value={
                        !insurancePayment?.amountOfCents &&
                        insurancePayment?.amountOfCents !== 0
                          ? ''
                          : `${insurancePayment.amountOfCents / 100}`
                      }
                    />
                  )}
                </div>
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <Button
                    type="link"
                    data-testid="switch-insurance-payment-button"
                    loading={loadingSaveOriginInsurancePayment}
                    style={{ color: '#1890FF', padding: 0 }}
                    tabIndex={1}
                    onClick={() =>
                      setInsurancePaymentOrigin(
                        insurancePaymentOriginData(true)
                      )
                    }
                  >
                    {`Use ${
                      !insurancePayment?.isERAPaymentActive
                        ? 'ERA'
                        : 'manual entry ' +
                          (insurancePayment?.createdAt
                            ? '(' +
                              format(
                                new Date(insurancePayment?.createdAt),
                                'MM/dd/yyyy'
                              ) +
                              ')'
                            : '')
                    }`}
                  </Button>
                </div>
                {/* <Text className={styles.cursorHover} style={{ color: '#FF4D4F'}}>Delete manual entry</Text> */}
              </div>
            )}
          <div className={styles.boldSpaceBetween}>
            <Text className={styles.boldLeftText}>Not paid by insurance</Text>
            <span className={styles.fontSizeMiddle}>
              {!insurancePayment
                ? boldPendingSpan
                : `${
                    isMissingBilledAmount
                      ? MISSING_VALUE
                      : formatAsDollar(notPaidByInsurance)
                  }`}
            </span>
          </div>

          <Divider
            type="horizontal"
            style={{ marginTop: 15, marginBottom: 15 }}
          />

          <Text header="h4">Patient</Text>
          <div className={styles.regularSpaceBetween} style={{ fontSize: 14 }}>
            {!patientResponsibility ? (
              'Patient responsibility'
            ) : (
              <div
                data-testid="patient-responsibility-switch-dropdown-button"
                onClick={() =>
                  setShouldShowPatientResponsibility(
                    !shouldShowPatientResponsibility
                  )
                }
              >
                {`Patient responsibility - ${
                  patientResponsibility?.isERAPatientResponsibilityActive &&
                  paymentInfo?.createdAtERA
                    ? 'ERA'
                    : 'Manual entry ' +
                      (patientResponsibility?.createdDate
                        ? '(' +
                          format(
                            new Date(patientResponsibility?.createdDate),
                            'MM/dd/yyyy'
                          ) +
                          ')'
                        : '')
                }`}{' '}
                {paymentInfo.createdAtERA &&
                  (!shouldShowPatientResponsibility ? (
                    <DownOutlined className={styles.cursorHover} />
                  ) : (
                    <UpOutlined className={styles.cursorHover} />
                  ))}
              </div>
            )}
            <span className={styles.fontSizeSmall}>
              {isResponsibilityERAactive || disableEdit ? (
                !patientResponsibility ? (
                  pendingSpan
                ) : (
                  `$${(patientResponsibilityToShow / 100).toFixed(2)}`
                )
              ) : (
                <InputNumber
                  data-testid="patient-responsibility-input"
                  className={styles.usdInput}
                  disabled={disableEdit}
                  style={{ width: patientResponsibilityWidth }}
                  addonBefore="USD"
                  tabIndex={2}
                  precision={2}
                  controls={false}
                  prefix="$"
                  onKeyPress={restrictDecimalOnKeyPressInputEvent}
                  step={0.1}
                  onChange={handlePatientResponsibilityChange}
                  value={
                    !patientResponsibility?.amountOfCents &&
                    patientResponsibility?.amountOfCents !== 0
                      ? ''
                      : `${patientResponsibility.amountOfCents / 100}`
                  }
                />
              )}
            </span>
          </div>
          {paymentInfo?.createdAtERA &&
            patientResponsibility &&
            shouldShowPatientResponsibility && (
              <div className={styles.insurancePaymentDetailsBox}>
                <div className={styles.insurancePaymentDetails}>
                  <Text className={styles.greyFontSmallSize}>
                    {`Patient responsibility - ${
                      !patientResponsibility?.isERAPatientResponsibilityActive
                        ? 'ERA'
                        : 'Manual entry ' +
                          (patientResponsibility?.createdDate
                            ? '(' +
                              format(
                                new Date(patientResponsibility?.createdDate),
                                'MM/dd/yyyy'
                              ) +
                              ')'
                            : '')
                    }`}
                  </Text>
                  {!isResponsibilityERAactive || disableEdit ? (
                    <Text className={styles.greyFontSmallSizeDecolored}>
                      {`- $${(
                        (!patientResponsibility?.isERAPatientResponsibilityActive
                          ? patientResponsibility?.amountOfCentsERA
                          : patientResponsibility?.amountOfCents) / 100
                      ).toFixed(2)}`}
                    </Text>
                  ) : (
                    <InputNumber
                      data-testid="patient-responsibility-input-drop-down"
                      className={styles.usdInput}
                      disabled={disableEdit}
                      style={{ width: patientResponsibilityWidth }}
                      addonBefore="USD"
                      tabIndex={2}
                      precision={2}
                      controls={false}
                      prefix="$"
                      onKeyPress={restrictDecimalOnKeyPressInputEvent}
                      step={0.1}
                      onChange={handlePatientResponsibilityChange}
                      value={
                        !patientResponsibility?.amountOfCents &&
                        patientResponsibility?.amountOfCents !== 0
                          ? ''
                          : `${patientResponsibility.amountOfCents / 100}`
                      }
                    />
                  )}
                </div>
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <Button
                    type="link"
                    loading={loadingSaveOriginManualPayment}
                    style={{ color: '#1890FF', padding: 0 }}
                    onClick={() =>
                      setInsurancePaymentOrigin(
                        insurancePaymentOriginData(false)
                      )
                    }
                  >
                    {`Use ${
                      !patientResponsibility?.isERAPatientResponsibilityActive
                        ? 'ERA'
                        : 'manual entry ' +
                          (patientResponsibility?.createdDate
                            ? '(' +
                              format(
                                new Date(patientResponsibility?.createdDate),
                                'MM/dd/yyyy'
                              ) +
                              ')'
                            : '')
                    }`}
                  </Button>
                </div>
                {/* <Text className={styles.cursorHover} style={{ color: '#FF4D4F'}}>Delete manual entry</Text> */}
              </div>
            )}
          <div className={styles.middleSpaceBetweenBaseline}>
            {normalizedPayments.length > 0 && (
              <>
                <div className={styles.transactionCollapse}>
                  <TransactionCollapse
                    transactions={normalizedPayments}
                    type={TransactionType.PAYMENT}
                    fromClaim={true}
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.middleSpaceBetweenBaseline}>
            {normalizedRefunds.length > 0 && (
              <>
                <div className={styles.transactionCollapse}>
                  <TransactionCollapse
                    transactions={normalizedRefunds}
                    type={TransactionType.REFUND}
                    fromClaim={true}
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.boldSpaceBetween}>
            <Text className={styles.boldLeftText}>Patient outstanding</Text>
            <span
              data-testid="claim-payments-patient-outstanding"
              className={styles.fontSizeMiddle}
            >
              {!claim?.patientResponsibilityAmountCents ||
              (claim?.patientResponsibilityAmountCents === 0 &&
                !paymentInfo?.createdAtERA)
                ? boldPendingSpan
                : formatAsDollar(totalPatientOutstanding)}
            </span>
          </div>
          {claimStatus !== 'DRAFT' && editPayments && (
            <div className={styles.alignRightButtons}>
              <Button
                testId="claim-payment-save-manual-adjustment-btn"
                style={{ marginLeft: 8 }}
                type="primary"
                color="#262626"
                onClick={() => handleSavePaymentsChanges()}
                loading={loadingPaymentsChanges}
                tabIndex={4}
              >
                Save
              </Button>
              <Button
                testId="discard-button"
                type="default"
                color="#262626"
                onClick={() => discardChanges()}
                tabIndex={5}
              >
                Discard changes
              </Button>
            </div>
          )}
          {!isClaimPaid && (
            <Button
              type={'primary'}
              disabled={areButtonsDisabled}
              loading={isSavingAndPaying}
              onClick={createAndShowPaymentsModal}
              style={{ marginTop: 24, marginBottom: 10 }}
            >
              {' '}
              <span>New payment</span>
            </Button>
          )}
        </Col>
        <Col className={styles.middleDividerCol}>
          <Divider type="vertical" className={styles.dividerComponent} />
        </Col>
        <Col className={styles.halfWidth}>
          <Text header="h4">Write off</Text>
          <div className={styles.regularSpaceBetween}>
            <Text className={styles.regularLeftText}>
              Not paid by insurance
            </Text>
            <span className={styles.fontSizeSmall}>
              {!insurancePayment
                ? boldPendingSpan
                : isMissingBilledAmount
                ? MISSING_VALUE
                : formatAsDollar(notPaidByInsurance)}
            </span>
          </div>
          <div className={styles.middleSpaceBetween}>
            <Text className={styles.regularLeftText}>
              Patient responsibility
            </Text>
            <span className={styles.fontSizeSmall}>
              {!patientResponsibility
                ? pendingSpan
                : `($${(patientResponsibilityToShow / 100).toFixed(2)})`}
            </span>
          </div>
          <div className={styles.boldSpaceBetween}>
            <Text className={styles.boldLeftText}>Suggested write off</Text>
            <span className={styles.fontSizeMiddle}>
              {!insurancePayment || !patientResponsibility
                ? boldPendingSpan
                : isMissingBilledAmount
                ? 'Unknown'
                : formatAsDollar(
                    notPaidByInsurance - patientResponsibilityToShow
                  )}
            </span>
          </div>

          <Divider
            type="horizontal"
            style={{ marginTop: 15, marginBottom: 15 }}
          />

          <div className={styles.regularSpaceBetween}>
            <Text className={styles.regularLeftText}>Write off amount</Text>
            <InputNumber
              className={styles.usdInput}
              data-testid="write-off-input"
              disabled={disableEdit}
              style={{ width: writeOffWidth }}
              addonBefore="USD"
              tabIndex={2}
              onKeyPress={restrictDecimalOnKeyPressInputEvent}
              controls={false}
              precision={2}
              prefix="$"
              step={0.1}
              value={
                !writeOff?.amountOfCents && writeOff?.amountOfCents !== 0
                  ? ''
                  : `${writeOff?.amountOfCents / 100}`
              }
              onChange={handleWriteOffChange}
            />
          </div>
          {isUpdatedPatientValuesTooltipEnabled(
            paymentInfo?.createdAtERA,
            paymentInfo?.insurancePayment?.createdAt,
            paymentInfo?.patientResponsibility?.createdDate,
            paymentInfo?.writeOff?.createdAt
          ) && (
            <div className={styles.tooltipContainer}>
              <InfoCircleOutlined
                style={{
                  color: '#FAAD14',
                  height: 24,
                  width: 24,
                  marginTop: 4,
                }}
              />
              <div>
                <div>
                  <Text className={styles.tooltipTextTitle}>
                    Updated payment values
                  </Text>
                </div>
                <div>
                  <Text className={styles.tooltipText}>
                    The values for this claim have been updated. Please update
                    your write off amount accordingly.
                  </Text>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {!paymentInfo?.createdAtERA &&
        claimStatus !== CLAIM_STATUS.MANUALLY_SUBMITTED && (
          <div className={styles.tooltipContainer}>
            <InfoCircleOutlined
              style={{
                color: '#FAAD14',
                height: 24,
                width: 24,
                marginTop: 4,
              }}
            />
            <div>
              <div>
                <Text className={styles.tooltipTextTitle}>
                  This claim is still pending
                </Text>
              </div>
              <div>
                <Text className={styles.tooltipText}>
                  To ensure payment accuracy, consider waiting until the claim
                  comes back adjudicated, or manually enter the payment values
                  before charging the patient for this claim.
                </Text>
              </div>
            </div>
          </div>
        )}
      <PaymentModals
        patientId={patientId}
        isOpen={showPaymentModal}
        onClose={() => {
          refetchClaim()
          setShowPaymentModal(false)
        }}
        stripeAccountStatus={stripeAccountStatusData?.status}
        preSelectedClaimUuid={claimId}
        stopModalLoading={isSavingAndPaying ? stopLoadingButton : null}
        fromClaim={true}
        fromGlobalView={false}
      />
    </div>
  )
}

export default Payments
