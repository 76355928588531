import { useQuery } from '@tanstack/react-query'

import { fetchNoteBlock } from '../../api'
import { NOTE_BLOCK_QUERY_KEY } from '../../constants'

export const getNoteBlockQueryKey = (
  noteUuid: string | undefined,
  blockUuid: string | undefined
) => {
  return [NOTE_BLOCK_QUERY_KEY, noteUuid, blockUuid]
}

/**
 * The duration that the data will be kept in cache.
 * This is basically the window of time a user has to "undo" a deleted block.
 * If a block is deleted and undo is not triggered within this duration, the block will
 * go into a temporary error state until retry logic succeeds or the note is refreshed.
 */
const QUERY_STALE_TIME_IN_MS = 5 * 60 * 1000

/**
 * The refetch interval for the query to keep our cache up to date.
 * NOTE: This value should be less than QUERY_STALE_TIME_IN_MS, such that our cache is always populated.
 */
const QUERY_REFETCH_INTERVAL_IN_MS = 4 * 60 * 1000

export const useGetNoteBlock = (args: {
  noteUuid: string | undefined
  blockUuid: string | undefined
}) => {
  const { noteUuid, blockUuid } = args
  const queryKey = getNoteBlockQueryKey(noteUuid, blockUuid)
  return useQuery(
    queryKey,
    () => {
      if (!noteUuid || !blockUuid) {
        return undefined
      }
      return fetchNoteBlock({ noteUuid, blockUuid })
    },
    {
      enabled: !!(noteUuid && blockUuid),

      // Override the default retry delay.
      // By default, useQuery will retry: 0s, 1s, 2s, and 3s seconds after the failure.
      // Apply a linear backoff here to spread our retries over a longer period of time.
      // In some edge cases with undo/redo, we may have blocks fail to load until the autosave
      // logic has a chance to execute. Linear backoff gives us a better chance to recover gracefully here.
      retryDelay: (attempt) =>
        // Attempt is base 0, so this gives retries at 2s, 4s, 6s, 8s intervals.
        (attempt + 1) * 2000,

      // We allow refetch on window focus (when not in error state), because we want to keep the cache up to date
      // when we have the opportunity to. Given that we allow stale data to exist (via staleTime),
      // the blocks won't reload on window focus unless the data in cache is stale/expired, so the
      // UX loading state does not appear too often.
      refetchOnWindowFocus: (query) => query.state.status !== 'error',

      // To properly support undo/redo operations with blocks within the editor, we allow stale data
      // to exist in the cache. If a block is deleted and then an undo operation is triggered, this allows
      // us to re-use the block from the cache without having to do an API call. During the next note autosave
      // operation, any previously deleted blocks will be resurrected, which will allow API calls to succeed again.
      staleTime: QUERY_STALE_TIME_IN_MS,
      // This defaults to 5 minutes in React Query. We explicitly specify it since it's best practice to have
      // this value be >= staleTime. Unused data in the cache older than cacheTime will be evicted.
      cacheTime: QUERY_STALE_TIME_IN_MS,
      // Periodically refetch block data. This allows us to keep the cache up to date, such that it will be
      // available in the case of an undo/redo operation.
      refetchInterval: QUERY_REFETCH_INTERVAL_IN_MS,
      refetchIntervalInBackground: false,
    }
  )
}
