import React from 'react'

import { Spinner } from 'stories/BaseComponents'

import { StatusCategoryValue } from '../../../../shared-types'

import styles from './ClaimStatusDisplay.module.scss'

export const testIds = {
  spinner: 'claim-status-display--spinner',
}

type ClaimStatusDisplayProps = {
  displayMessage: string
  statusCategory: StatusCategoryValue
  testId: string
  isLoading?: boolean
}

function getClaimStatusColorStyle(status: StatusCategoryValue) {
  switch (status) {
    case 'NO_ACTION': {
      return styles.statusDotBlue
    }
    case 'DONE': {
      return styles.statusDotGreen
    }
    case 'INFO':
    case 'WARNING': {
      return styles.statusDotOrange
    }
    case 'ACTION_REQUIRED': {
      return styles.statusDotRed
    }
    case 'DRAFT': {
      return styles.statusDotGray
    }
  }
}

const ClaimStatusDisplay: React.FC<ClaimStatusDisplayProps> = ({
  displayMessage,
  statusCategory,
  testId,
  isLoading = false,
}) => {
  const dotStyle = getClaimStatusColorStyle(statusCategory)
  return (
    <div className={styles.statusContainer} data-testid={testId}>
      {isLoading ? (
        <div data-testid={testIds.spinner} className={styles.loadingSpinner}>
          <Spinner fontSize={20} />
        </div>
      ) : (
        <div className={dotStyle} />
      )}
      {displayMessage}
    </div>
  )
}

export default ClaimStatusDisplay
