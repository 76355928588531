// NOTE: Only using react-bootstrap as this is an attempt to refactor
// the size of Documents. To be removed in the react-bootstrap removal process.
import { Button, Table } from 'react-bootstrap'

import { IntakeForm } from '../../../../api/intakeForms'
import FileCard from '../../../../components/Other/FileInformation'
import { SignatureRequest } from '../../Documents'
import { IntakeRows } from './IntakeRows'

export type SignatureRequestsTableProps = {
  patientId: string
  patientPublicId: string | undefined
  signatureRequests: SignatureRequest[]
  showLocalTime: (timestamp: string | Date) => string
  onClickReviewImport: (request: SignatureRequest) => void
  onClickImport: (request: SignatureRequest) => void
  providerId: string
  handleApiChange: () => void
  onResendIntakeInvite: ({ intakeFormId }: { intakeFormId: string }) => void
  osmindIntakeForms?: IntakeForm[]
  intakeFormsLoading: boolean
  resendingForm: boolean
}

export const SignatureRequestsTable = ({
  patientId,
  patientPublicId,
  signatureRequests,
  showLocalTime,
  onClickReviewImport,
  handleApiChange,
  onClickImport,
  providerId,
  onResendIntakeInvite,
  osmindIntakeForms,
  intakeFormsLoading,
  resendingForm,
}: SignatureRequestsTableProps) => {
  return (
    <Table>
      <thead>
        <tr>
          <th className="table-header">Template Name</th>
          <th className="table-header sent-at">First Sent At</th>
          <th className="table-header signature-status">Status</th>
          <th />
        </tr>
      </thead>
      {!signatureRequests?.length && !osmindIntakeForms?.length ? (
        <tbody>
          <tr>
            <td>No signatures requested yet</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          <IntakeRows
            onResendIntakeInvite={onResendIntakeInvite}
            intakeFormsLoading={intakeFormsLoading}
            intakeForms={osmindIntakeForms}
            patientPublicId={patientPublicId}
            resendingForm={resendingForm}
            showLocalTime={showLocalTime}
          />
          {signatureRequests.map((request, index) => {
            const {
              downloadData,
              TemplateName: name,
              SentAt: sentAt,
              SignatureStatus: status,
              isCompleted,
            } = request
            const isFinalizing =
              status === 'reviewed' || status === 'imported' || isCompleted
            const fileContainer = downloadData ?? {
              documentReference: `SYSTEM_DEFAULT_JOTFORM#${patientId}`,
            }
            return (
              <tr key={`sigrequests-${index}`}>
                <td>{name}</td>
                <td>{showLocalTime(sentAt)}</td>
                <td>
                  {status}
                  {isFinalizing && (
                    <FileCard
                      fileContainer={fileContainer}
                      patientId={patientId}
                      handleApiChange={handleApiChange}
                      providerId={providerId}
                      isIntakeForm
                    />
                  )}
                </td>
                <td>
                  {status === 'reviewed' && (
                    <Button onClick={() => onClickImport(request)}>
                      Import
                    </Button>
                  )}
                  {status === 'requires review' && (
                    <Button onClick={() => onClickReviewImport(request)}>
                      Review and Import
                    </Button>
                  )}
                  {status === 'imported' && 'Imported to Patient Profile'}
                  {status !== 'imported' &&
                    status !== 'reviewed' &&
                    status !== 'requires review' && <span />}
                </td>
              </tr>
            )
          })}
        </tbody>
      )}
    </Table>
  )
}
