export enum NoteTemplateEvent {
  MANAGE_TEMPLATES = 'Note List: Manage Templates',
  NEW_NOTE_TEMPLATE = 'Settings: New Note Template',
  SETUP_NOTE_TEMPLATE_NAME = 'Settings: Set up Note Template Name',
  INSERT_BLOCKS_INTO_NOTE_TEMPLATE = 'Settings: Insert Blocks into Note Template',
  SELECTING_CUSTOM_NOTE_TEMPLATE = 'Note List: Selecting a Custom Note Template',
}

export const trackNoteTemplateEvent = (
  eventName: NoteTemplateEvent,
  eventDetails: {
    clinicId: string
    providerId: string
  }
) => {
  window?.freshpaint?.track(eventName, eventDetails)
}

export const trackNoteTemplateCreateEvent = ({
  clinicId,
  patientId,
  providerId,
  templateId,
}: {
  clinicId: string
  providerId: string
  templateId: string
  patientId: string
}) => {
  window?.freshpaint?.track(NoteTemplateEvent.SELECTING_CUSTOM_NOTE_TEMPLATE, {
    clinicId,
    patientId,
    providerId,
    templateId,
  })
}
