import { lazy, useEffect, useState } from 'react'

import { ClaimsErrorBoundary } from 'containers/Patient/ClaimsV2/Components/ClaimsErrorBoundary'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { HomepageData } from './components/Accordions/SharePatientProgress/attachmentLib'
import { EmailNotificationsToggle } from './components/Forms/EmailNotificationsToggle'
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute'
import UnauthenticatedOnlyRoute from './components/Routes/UnauthenticatedOnlyRoute'
import UnauthenticatedRoute from './components/Routes/UnauthenticatedRoute'
import AppointmentSettings from './components/Scheduling/AppointmentSettings'
import { globalConfig } from './config/config'
import ActivatePractice from './containers/Authentication/ActivatePractice'
import ActivateUser from './containers/Authentication/ActivateUser'
import ChangePassword from './containers/Authentication/ChangePassword'
import Locations from './containers/Authentication/Locations'
import Login from './containers/Authentication/Login'
import { PracticeSettings } from './containers/Authentication/PracticeSettings'
import ResetPassword from './containers/Authentication/ResetPassword'
import BillingTemplates from './containers/Billing/BillingTemplates'
import CustomBillingCode from './containers/Billing/CustomBillingCodes'
import PaymentsOnboarding from './containers/Billing/PaymentsOnboarding'
import PaymentsOnboardingComplete from './containers/Billing/PaymentsOnboardingComplete'
import EmailTemplate from './containers/EmailTemplate/EmailTemplate'
import NotAllowedWhileLoggedIn from './containers/Error/NotAllowedWhileLoggedIn'
import NotFound from './containers/Error/NotFound'
import Intake from './containers/Intake'
import MaintenancePage from './containers/MaintenancePage'
import Billing from './containers/Patient/Billing/Billing'
import ClaimsV1 from './containers/Patient/ClaimsV1'
import ClaimsV2 from './containers/Patient/ClaimsV2'
import ViewEditNotePage from './containers/Patient/ClinicalNotes/ViewEditNotePage'
import { getNoteTemplateSettingsUrl } from './containers/Patient/ClinicalNotes/utils'
import Documents from './containers/Patient/Documents'
import LabOrderFinalStatus from './containers/Patient/LabOrderFinalStatus'
import Labs from './containers/Patient/Labs'
import Medication from './containers/Patient/Medication'
import PatientClinicalNotes from './containers/Patient/PatientClinicalNotes'
import PatientJournal from './containers/Patient/PatientJournal'
import PatientMessages from './containers/Patient/PatientMessages'
import PatientProfile from './containers/Patient/PatientProfile'
import PatientProgress from './containers/Patient/PatientProgress'
import PatientSettingsPage from './containers/Patient/PatientSettingsPage'
import SettingsPersonal from './containers/Provider/SettingsPersonal'
import SurveySettings from './containers/Provider/SurveySettings'
import { AvailabilitySettings } from './containers/Provider/availability/AvailabilitySettings'
import { useAppReload } from './hooks/useAppReload'
import { useAppContext } from './libs/contextLib'
import { useFeatureFlags } from './libs/featureFlags'
import Sentry from './libs/sentry'
import { ProviderHomePageData, Teammate } from './shared-types'
import getCognitoUser from './shared/Helpers/getCognitoUser'
import AdvancedMD from './stories/AdvancedMD'
import PageSuspense from './stories/BaseComponents/PageSuspense'
import { ClinicBillingSummary } from './stories/ClinicBillingSummary'
import { InvoiceForm } from './stories/Invoice/InvoiceForm'
import { NoteTemplateEditorPage } from './stories/NoteTemplateSettings/NoteTemplateEditorPage'
import { NoteTemplateSettings } from './stories/NoteTemplateSettings/NoteTemplateSettings'
import PatientIntake from './stories/PatientIntake/PatientIntake'
import { PatientIntakeExpired } from './stories/PatientIntake/sections/PatientIntakeExpired'
import PatientIntakeSuccess from './stories/PatientIntake/sections/PatientIntakeSuccess'
import { Home } from './stories/Patients/Home'
import TeamSettings from './stories/ProviderSettings/TeamSettings'
import ReportLabs from './stories/Reports/ReportLabs'
import { ReportType } from './stories/Reports/types'
import CalendarSettings from './stories/Scheduling/CalendarSettings'
import SchedulingWrapper from './stories/Scheduling/SchedulingWrapper'
import TimezoneSettings from './stories/Scheduling/TimezoneSettings'
import Erx from './v2/erx/views'
import { Editor as NotesV2Editor } from './v2/notes/views/Editor/Editor'

const ReportPage = lazy(() => import('./stories/Reports/ReportPage'))

export default function Routes({
  homepageData,
  loggedInUserCognitoId,
  setHealthGorillaUserNameApp,
  erxMessagesCount,
  erxNotificationCount,
  erxReportsCount,
  teammates,
  updateProviderData,
}: {
  homepageData: ProviderHomePageData | null
  loggedInUserCognitoId: string
  setHealthGorillaUserNameApp: (username: string) => void
  erxMessagesCount: number
  erxNotificationCount: number
  erxReportsCount: number
  updateProviderData: (data: ProviderHomePageData) => void
  teammates: Teammate[]
}) {
  const config = globalConfig.get()
  const [userId, setUserId] = useState('')
  /**
   * cognito id/provider id of the logged in user
   */
  const [appUserId, setAppUserId] = useState<string | null>(null) // workaround to problem that userId gets overwritten. e.g. 'offlineContext_cognitoIdentityId'
  // this has to stay in to not break patient.js
  const [healthGorillaUserName, setHealthGorillaUserName] = useState('')
  const { isAuthenticated, shouldReload } = useAppContext()
  const { pathname } = useLocation()

  const { advancedmd, claims, claimCreationV2 } = useFeatureFlags()

  function configureFreshpaint() {
    window?.freshpaint?.addEventProperties({
      production: config.isProd,
      devEnvironment: config.ENV,
    })
    window?.freshpaint?.page()
  }

  function setSentryIdentity(providerId: string) {
    Sentry.setUser({ id: providerId })
  }

  useEffect(() => {
    ;(async () => {
      configureFreshpaint()

      if (!isAuthenticated) {
        return
      }

      try {
        const provider = await getCognitoUser()
        setSentryIdentity(provider.id)
        if (!provider) {
          console.error('User is not confirmed, provider is null')
        }
        setAppUserId(provider.id)
        setUserId(homepageData?.providerId ?? '') // localstack sets to 'offlineContext_cognitoIdentityId'
        setHealthGorillaUserName(homepageData?.healthGorillaUserName ?? '')
        setHealthGorillaUserNameApp(homepageData?.healthGorillaUserName ?? '')
      } catch (e) {
        console.error('error gathering provider ID for sendbird', e)
      }
    })()
  }, [isAuthenticated])

  useAppReload({
    shouldReload,
    currentPathname: pathname,
  })

  return (
    <PageSuspense>
      <Switch>
        <AuthenticatedRoute exact path="/">
          <Home
            homePageData={
              homepageData
                ? {
                    loggedInProviderId: homepageData.loggedInProviderId,
                    providerId: homepageData.providerId,
                    clinicData: homepageData.clinicData,
                    hellosignTemplates: homepageData.hellosignTemplates,
                  }
                : {
                    loggedInProviderId: '',
                    providerId: '',
                    clinicData: {
                      PracticeName: '',
                      ProviderEmail: '',
                      ProviderPhone: '',
                      ProviderPracticeAddress: '',
                      ProviderPracticeCity: '',
                      ProviderPracticeAddressExtn: '',
                      ProviderPracticeState: '',
                      ProviderPracticeZipcode: '',
                    },
                    hellosignTemplates: [],
                  }
            }
            teammates={teammates}
          />
        </AuthenticatedRoute>
        <Route exact path="/maintenance">
          <MaintenancePage />
        </Route>
        <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/scheduling">
          <SchedulingWrapper clinicId={appUserId ?? ''} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/erx">
          <Erx
            erxMessagesCount={erxMessagesCount}
            erxReportsCount={erxReportsCount}
            erxNotificationCount={erxNotificationCount}
          />
        </AuthenticatedRoute>
        <UnauthenticatedRoute exact path="/login/reset">
          <ResetPassword />
        </UnauthenticatedRoute>
        <UnauthenticatedOnlyRoute exact path="/activate">
          <ActivateUser />
        </UnauthenticatedOnlyRoute>
        <UnauthenticatedOnlyRoute exact path="/activate/practice">
          <ActivatePractice />
        </UnauthenticatedOnlyRoute>
        <UnauthenticatedRoute exact path="/form/success" forceRedirect={false}>
          <PatientIntakeSuccess />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/form/expired" forceRedirect={false}>
          <PatientIntakeExpired />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/form/:formId" forceRedirect={false}>
          <PatientIntake />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/settings">
          <Redirect to="/settings/personal" />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/team">
          <TeamSettings
            masterProviderId={userId ?? ''}
            loggedInUserCognitoId={loggedInUserCognitoId}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/personal">
          <SettingsPersonal UserId={appUserId ?? ''} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/availability">
          <AvailabilitySettings providerId={appUserId ?? ''} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/password">
          <ChangePassword />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/location">
          <Locations />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path={getNoteTemplateSettingsUrl()}>
          <NoteTemplateSettings
            providerId={appUserId ?? ''}
            clinicId={homepageData?.providerId ?? ''}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          path={`${getNoteTemplateSettingsUrl()}/:templateId`}
        >
          <NoteTemplateEditorPage
            providerId={appUserId ?? ''}
            clinicId={homepageData?.providerId ?? ''}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/practice-settings">
          <PracticeSettings
            providerData={homepageData}
            updateProviderData={updateProviderData}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/survey">
          <SurveySettings
            surveyData={homepageData?.surveyData}
            defaultSurveySettings={homepageData?.surveySettings}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/scheduling">
          <CalendarSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/timezone">
          <TimezoneSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/appointment-settings">
          <AppointmentSettings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/email-template">
          <EmailTemplate />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/email-notifications">
          <EmailNotificationsToggle
            providerEmail={homepageData?.providerEmail ?? ''}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/intake">
          <Intake
            homepageData={
              homepageData
                ? {
                    emailData: homepageData.emailData,
                    hellosign: homepageData.hellosign,
                    providerId: homepageData.providerId,
                    hellosignTemplates: homepageData.hellosignTemplates,
                    ProviderEmail: homepageData.providerEmail,
                    surveyData: homepageData.surveyData,
                  }
                : {
                    emailData: [] as HomepageData['emailData'],
                    hellosign: [] as HomepageData['hellosign'],
                    providerId: '',
                  }
            }
            updateProviderData={updateProviderData}
          />
        </AuthenticatedRoute>
        <Route exact path="/settings/billing">
          <Redirect to="/settings/billing-templates" />
        </Route>
        <AuthenticatedRoute exact path="/settings/billing-templates">
          <BillingTemplates />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/billing-custom">
          <CustomBillingCode />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/payments">
          <PaymentsOnboarding />
        </AuthenticatedRoute>
        <Route exact path="/settings/payments/success">
          <PaymentsOnboardingComplete />
        </Route>
        {advancedmd && (
          <AuthenticatedRoute exact path="/settings/advanced-md">
            <AdvancedMD />
          </AuthenticatedRoute>
        )}
        <AuthenticatedRoute exact path="/patient/progress">
          <PatientProgress healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/profile">
          <PatientProfile healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/billing/clinic">
          <ClinicBillingSummary providerId={userId ?? ''} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/invoice">
          <InvoiceForm primaryProviderId={userId ?? ''} userId={userId ?? ''} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/documents">
          <Documents
            primaryProviderId={userId ?? ''}
            healthGorillaUserName={healthGorillaUserName}
          />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path="/patient/clinical-notes/v2/:noteId">
          <NotesV2Editor healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path="/patient/clinical-notes">
          <PatientClinicalNotes healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/clinical-notes/:noteId">
          <ViewEditNotePage healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path="/patient/medication">
          <Medication healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/journal">
          <PatientJournal healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/messages">
          <PatientMessages
            providerId={appUserId ?? ''}
            healthGorillaUserName={healthGorillaUserName}
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/settings">
          <PatientSettingsPage healthGorillaUserName={healthGorillaUserName} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/patient/billing">
          <Billing
            providerId={userId ?? ''}
            healthGorillaUserName={healthGorillaUserName}
          />
        </AuthenticatedRoute>
        {claims && (
          <AuthenticatedRoute exact path="/patient/billing/claim">
            <ClaimsErrorBoundary>
              {claimCreationV2 ? (
                /** This should be renamed to ClaimsV2: follow up ticket */
                <ClaimsV2
                  healthGorillaUserName={healthGorillaUserName}
                  practiceData={{
                    ...(homepageData ? homepageData.clinicData : {}),
                  }}
                />
              ) : (
                /** This should be renamed to ClaimsV1: follow up ticket */
                <ClaimsV1
                  healthGorillaUserName={healthGorillaUserName}
                  practiceData={{
                    ...(homepageData ? homepageData.clinicData : {}),
                  }}
                />
              )}
            </ClaimsErrorBoundary>
          </AuthenticatedRoute>
        )}
        {claims && (
          /**
           * Preserving the old route in case it is stored in other systems as the Claim link
           */
          <AuthenticatedRoute exact path="/patient/billing/claim-create">
            <Redirect to="/patient/billing/claim" />
          </AuthenticatedRoute>
        )}
        <AuthenticatedRoute exact path="/reports/notes">
          <ReportPage reportType={ReportType.NOTES} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/reports/appointments">
          <ReportPage reportType={ReportType.APPOINTMENTS} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/reports/surveys">
          <ReportPage reportType={ReportType.SURVEYS} />
        </AuthenticatedRoute>
        {healthGorillaUserName && (
          <>
            <AuthenticatedRoute exact path="/reports/labs">
              <ReportLabs providerId={userId ?? ''} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/patient/labs">
              <Labs healthGorillaUserName={healthGorillaUserName} />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/laborder/final">
              <LabOrderFinalStatus />
            </AuthenticatedRoute>
          </>
        )}
        <Route exact path="/error/logout">
          <NotAllowedWhileLoggedIn />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </PageSuspense>
  )
}
