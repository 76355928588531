import React, { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { cloneDeep } from 'lodash'

import SkeletonLoadingTransition from '../../../components/Animation/SkeletonLoadingTransition'
import { useChangePayers } from '../../../hooks/usePatientProfile'
import {
  InsuranceHolder,
  PatientInsurance,
} from '../../../hooks/usePatientProfile/shared-types'
import { getInsurancePayerOptions } from '../../../libs/utils'
import Fields, { FieldGroupProps } from '../../../stories/PatientProfile/Fields'
import {
  handlePrimaryInsuranceUpdate,
  populatePrimaryInsuranceInfo,
} from './InsuranceClaim-helper'
import {
  InsuranceHolderRelationshipEnum,
  InsuranceHolderRelationshipOptions,
  InsuranceSubscriberItems,
  primaryInsuranceComponents,
} from './constants'

import styles from './InsuranceClaim.module.scss'

export interface PrimaryInsuranceClaimProps {
  loading?: boolean
  testId?: string
  disabled?: boolean
  primaryInsurance: null | PatientInsurance | undefined
  setPrimaryInsurance: Function
  primaryHolder: null | InsuranceHolder | undefined
  setPrimaryHolder: Function
}

const Loading = () => (
  <div className={styles.claimSkeletonContainer}>
    <Skeleton
      active
      title={{ width: 85 }}
      paragraph={{
        rows: 3,
        width: '100%',
        className: styles.skeletonParagraph,
      }}
    />
  </div>
)

const PrimaryInsuranceClaim: React.FC<PrimaryInsuranceClaimProps> = ({
  testId,
  loading,
  disabled = false,
  primaryInsurance,
  setPrimaryInsurance,
  primaryHolder,
  setPrimaryHolder,
}) => {
  const { data: changeList, isFetching: loadingChangeList } = useChangePayers()

  const [sameAddressAsPatient, setSameAddressAsPatient] =
    useState<boolean>(true)

  const [primary, setPrimary] = useState<FieldGroupProps[]>(
    primaryInsuranceComponents
  )

  useEffect(() => {
    if (loading || loadingChangeList || !changeList) return
    const changeOptions: {
      value: string
      label: string
      disabled?: boolean
    }[] = getInsurancePayerOptions(changeList)
    const orgNameArray: string[] = changeList.map((el) => el.organizationName)
    let itemsCopy: any = cloneDeep(primaryInsuranceComponents)
    if (!primaryInsurance) {
      itemsCopy[0].items[0].options = changeOptions
      setPrimary(itemsCopy)
      return
    }
    if (
      primaryInsurance.insuranceName &&
      !orgNameArray.includes(primaryInsurance.insuranceName)
    ) {
      itemsCopy[0].items[0].isWrongValue = true
      changeOptions.unshift({
        value: primaryInsurance.insuranceName,
        label: primaryInsurance.insuranceName,
        disabled: true,
      })
    }
    if (
      primaryInsurance.isPrimaryInsuranceHolder ||
      !primaryHolder?.relationship
    ) {
      const selfRelation = itemsCopy.slice(0, 1)
      itemsCopy = selfRelation
    } else if (
      primaryHolder &&
      primaryHolder.relationship &&
      itemsCopy[1].items.length === 1
    ) {
      itemsCopy = cloneDeep(primaryInsuranceComponents)
    }

    const relationshipOptions = cloneDeep(InsuranceHolderRelationshipOptions)
    if (
      primaryHolder?.relationship &&
      !Object.values(InsuranceHolderRelationshipEnum).includes(
        primaryHolder.relationship
      )
    ) {
      // itemsCopy[0].items[6] is the object that handles the relationship display
      itemsCopy[0].items[6].isWrongValue = true
      relationshipOptions.unshift({
        value: primaryHolder.relationship,
        label: primaryHolder.relationship,
        disabled: true,
      })
      itemsCopy[0].items[6].options = relationshipOptions
    } else {
      itemsCopy[0].items[6].isWrongValue = false
      itemsCopy[0].items[6].options = relationshipOptions
    }

    if (
      primaryHolder &&
      !primaryHolder.address1 &&
      sameAddressAsPatient &&
      itemsCopy[1]
    ) {
      const isSameAddressPatient = itemsCopy[1].items.slice(0, 7)
      itemsCopy[1].items = isSameAddressPatient
    } else if (!sameAddressAsPatient && primaryHolder) {
      itemsCopy[1].items = cloneDeep(InsuranceSubscriberItems)
    }
    populatePrimaryInsuranceInfo(
      itemsCopy,
      primaryInsurance,
      primaryHolder,
      sameAddressAsPatient
    )
    itemsCopy[0].items[0].options = changeOptions
    setPrimary(itemsCopy)
  }, [
    loading,
    primaryInsurance,
    primaryHolder,
    sameAddressAsPatient,
    loadingChangeList,
  ])

  // To be tested when adding saving tests to components
  /* istanbul ignore next */
  const handleSave = (
    newValue: any,
    id: string,
    _groupName: string,
    _label?: string
  ) => {
    const newVal: any =
      typeof newValue === 'string' ? newValue.trim() : newValue

    const { primaryInsuranceItem, shouldSave } = handlePrimaryInsuranceUpdate(
      newVal,
      id,
      primaryInsurance,
      primaryHolder,
      setSameAddressAsPatient,

      changeList ?? []
    )
    if (shouldSave) {
      setPrimaryInsurance(primaryInsuranceItem.patientInsurance)
      setPrimaryHolder(primaryInsuranceItem.insuranceHolder)
    }
  }

  return (
    <SkeletonLoadingTransition
      isLoading={loading || loadingChangeList}
      skeletonComponent={
        <>
          <Loading />
          <Loading />
        </>
      }
      loadedComponent={
        <Fields
          testId={testId}
          items={primary}
          compact={false}
          handleSave={handleSave}
          isClaim
          disableAll={disabled}
        />
      }
    />
  )
}

export default PrimaryInsuranceClaim
