import React from 'react'

import { ClaimStatusUpdate } from '../../../../shared-types'
import { CollapsibleCard } from '../../../../stories/BaseComponents'
import ClaimStatusDisplay from '../Components/ClaimStatusDisplay'

import styles from './ClaimStatusDetails.module.scss'

type ClaimStatusDetailsProps = {
  claimStatusUpdate: ClaimStatusUpdate
}

type StatusDetailsRowProps = {
  label: string
  value: string
  testId: string
}

const StatusDetailsRow: React.FC<StatusDetailsRowProps> = ({
  label,
  value,
  testId,
}) => {
  return (
    <div data-testid={testId} className={styles.infoRow}>
      <div className={styles.rowLabel}>{label}</div>
      <div className={styles.rowValue}>{value}</div>
    </div>
  )
}

function displayDate(dateString: string) {
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(dateString.replace('Z', '')))
}

export const testIds = {
  container: 'claim-status-details-container',
  collapseIcon: 'claim-status-details-collapse-icon',
  statusDisplay: 'claim-status-details-status-display',
  infoDisplay: 'claim-status-details-info-display',
  timeRow: 'claim-status-details-time-row',
  categoryRow: 'claim-status-details-category-row',
  statusCodeRow: 'claim-status-details-status-code-row',
  entityIdRow: 'claim-status-details-entity-id-row',
}

const ClaimStatusDetails: React.FC<ClaimStatusDetailsProps> = ({
  claimStatusUpdate: {
    displayMessage,
    statusCategory,
    statusCodeValue,
    categoryCode,
    categoryCodeValue,
    entityIdentifierCode,
    entityIdentifierCodeValue,
    transactionTime,
  },
}) => {
  // statusCode, categoryCode, and their values are always all present or all absent
  const shouldDisplay =
    !!statusCodeValue && !!categoryCode && !!categoryCodeValue

  const defaultOpened = !['Settled', 'Adjudicated'].includes(displayMessage)

  return shouldDisplay ? (
    <CollapsibleCard
      title="Status details"
      isDefaultOpened={defaultOpened}
      testId={testIds.container}
      iconTestId={testIds.collapseIcon}
      style={{ marginTop: 24 }}
      bodyStyle={{ paddingBottom: 16 }}
    >
      <div className={styles.header}>
        <div
          className={styles.statusDisplay}
          data-testid={testIds.statusDisplay}
        >
          <div>Status</div>
          <ClaimStatusDisplay
            displayMessage={displayMessage}
            statusCategory={statusCategory}
            testId={`${testIds.container}-status-display`}
          />
        </div>
      </div>
      <div className={styles.infoContainer} data-testid={testIds.infoDisplay}>
        <div className={styles.infoCol}>
          {transactionTime && (
            <StatusDetailsRow
              label={'Last update received'}
              value={displayDate(transactionTime)}
              testId={testIds.timeRow}
            />
          )}
          {categoryCode && categoryCodeValue && (
            <StatusDetailsRow
              label={'Category code'}
              value={`${categoryCode} - ${categoryCodeValue}`}
              testId={testIds.categoryRow}
            />
          )}
        </div>
        <div className={styles.infoDivider}></div>
        <div className={styles.infoCol}>
          {statusCodeValue && (
            <StatusDetailsRow
              label={'Status code'}
              value={statusCodeValue}
              testId={testIds.statusCodeRow}
            />
          )}
          {entityIdentifierCode && entityIdentifierCodeValue && (
            <StatusDetailsRow
              label={'Entity code'}
              value={`${entityIdentifierCode} - ${entityIdentifierCodeValue}`}
              testId={testIds.entityIdRow}
            />
          )}
        </div>
      </div>
    </CollapsibleCard>
  ) : null
}

export default ClaimStatusDetails
