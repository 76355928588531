import { AlertProps } from 'antd'

import {
  BillableEntity,
  BillingCodesTypes,
  CLAIM_STATUS,
  ClaimStatusUpdate,
} from '../../../shared-types'

// FE data model
export type NdcItem = {
  key: string
  uuid?: string
  billingCodeId: number | null
  idQualifier: NdcIdQualifier | null
  ndc: string | null
  ndcLabel: string | null
  numberOfUnits: number | null
  unitType: UnitMeasurementType | null
  prescriptionNumber: string | null
}

// BE data model
export type InsuranceClaimNdc = {
  uuid?: string
  billingCodeId: number | null
  ndcIdQualifier: NdcIdQualifier | null
  ndcEntered: string | null
  ndcLabel: string | null
  numberOfUnits: number | null
  unitMeasurementType: UnitMeasurementType | null
  prescriptionNumber: string | null
}

export enum NdcIdQualifier {
  NDC_IN_5_4_2_FORMAT = 'NDC_IN_5_4_2_FORMAT',
  EAN_UCC_13 = 'EAN_UCC_13',
  EAN_UCC_8 = 'EAN_UCC_8',
  HEALTH_INDUSTRY_BAR_CODE_SUPPLIER_LABELING = 'HEALTH_INDUSTRY_BAR_CODE_SUPPLIER_LABELING',
  CUSTOMER_ORDER_NUMBER = 'CUSTOMER_ORDER_NUMBER',
  GTIN_14 = 'GTIN_14',
  UCC_12 = 'UCC_12',
}

export enum UnitMeasurementType {
  INTERNATIONAL_UNIT = 'INTERNATIONAL_UNIT',
  GRAM = 'GRAM',
  MILLIGRAM = 'MILLIGRAM',
  MILLILITER = 'MILLILITER',
  UNIT = 'UNIT',
}

export type CreateClaimBaseParameter = {
  patientId: string
  noteId?: string
}

// TODO: split and update various claim data type (#CARE-2254)
export type ClaimData = {
  patient?: Patient
  payerControlNumber?: string
  submittedInfo?: Submitted
  primaryInsurance?: PatientInsurance
  secondaryInsurance?: PatientInsurance
  selectedInsuranceName?: string | null
  referringProvider?: Provider
  renderingProvider?: Provider
  supervisingProvider?: Provider
  claimStatus: ClaimStatus
  patientControlNumber: string // required
  claimMemo?: string | null
  payerName?: string
  note?: Note
  signature?: Signature
  transactionSetNumber?: number
  claimFrequencyCode?: string
  diagnoses?: InsuranceClaimDiagnosis[]
  lineItems?: LineItem[]
  ndcs?: InsuranceClaimNdc[]
  billingType?: BillingType
  billing?: Billing
  patientConditionRelation?: PatientConditionRelation
  companyToSubmitTo?: string
  claimId: string
  controlNumber?: string
  authNumber?: string | null
  canceledAt?: string
  createdAt?: string
  billableEntity?: BillableEntity
  patientPaidAmountCents?: number
  patientResponsibilityAmountCents?: number
  insurancePaidAmountCents?: number
  writeOffAmountCents?: number
  isMissingSubmission?: boolean
  claimStatusUpdate: ClaimStatusUpdate
  payerAddress: PayerAddress
}

export type Patient = {
  id?: string | null
  firstName?: string | null
  lastName?: string | null
  dateOfBirth?: string | null
  address?: AddressUpperCase | null
  legalSex?: Sex | null
}

export type Submitted = {
  submittedDate?: string | null
  controlNumber?: string | null
}

export type PayerAddress = {
  isIncluded?: boolean | null
  address?: Address | null
}

export type PatientInsurance = {
  isIncluded?: boolean | null
  patientInsuranceId?: number | null
  isInformationReleaseAuthorized?: boolean | null
  isPaymentAuthorized?: boolean | null
  subscriberAddress?: Address | null
  subscriberDateOfBirth?: string | null
  subscriberEmail?: string | null
  subscriberFirstName?: string | null
  subscriberMiddleName?: string | null
  subscriberLastName?: string | null
  subscriberPhoneNumber?: string | null
  subscriberRelationship?: string | null
  subscriberLegalSex?: Sex | null
  subscriberId?: string | null
  isSubscriber?: boolean | null
  name?: string | null
  memberId?: string | null
  groupId?: string | null
  companyTradingPartnerServiceId?: string | null
  claimFilingCode?: string | null
  insuranceTypeCode?: string | null
}

export type Provider = {
  providerId?: string | null
  providerNpi?: string | null
  providerTaxonomyCode?: string | null
  providerFirstName?: string | null
  providerLastName?: string | null
  providerEin?: string | null
  providerSsn?: string | null
  encryptedFirstFiveSSN?: string | null
  ssnLastFour?: string | null
  providerSSNfirstFive?: string | null
  providerSSNlastFour?: string | null
  taxType?: TAX_TYPE | null
  isIncluded?: boolean | null
}

export type Note = {
  noteId?: string | null
  locationId?: string | null
  locationName?: string | null
  title?: string | null
  posCode?: string | null
  posVersion?: string | null
  practiceId?: number | null
  practiceName?: string | null
  practiceNpi?: string | null
  endDate?: string | null
  Signatures?: NoteSignature[]
  signed?: boolean | null
  startDate?: string | null
  address?: Address | null
}

export type NoteSignature = {
  CreatedAt: string
  SignedBy: string
  SignedByProviderId?: string
}

export type InsuranceClaimDiagnosis = {
  id?: number
  diagnosisId: string
  order: number
  code: string
  description?: string
  isDeleted?: boolean
  isExcluded: boolean
  diagnosisDate: string | null
}

export type LineItem = {
  billingCode: {
    code: string
    version: BillingCodesTypes
    description: string
    ownerId: string
    id: number
  }
  modifiers: string[]
  units: number
  unitChargeAmountCents: number
  totalAdjustmentAmountCents: number
  dxPointers: number[]
  uuid?: string
}

export type Billing = {
  name?: string | null
  firstName?: string | null
  locationName?: string | null
  lastName?: string | null
  ein?: string | null
  npi?: string | null
  providerId?: string | null
  taxType?: TAX_TYPE | null
  locationId?: string | null
  ssn?: string | null
  phoneNumber?: string | null
  encryptedFirstFiveSSN?: string | null
  ssnLastFour?: string | null
  ssnFirstFive?: string | null
  address?: Address | null
  type?: BillingType | null
  email?: string | null
  taxonomyCode?: string | null
}

export type Signature = {
  providerId?: string | null
  date?: string | null
  providerFirstName?: string
  providerLastName?: string
  title?: string
  providerEmail?: string
  providerNpi?: string
  providerTaxonomyCode?: string
  providerEin?: string
  providerSsn?: string
  phoneNumber?: string
}

export type PatientConditionRelation = {
  autoAccident?: boolean | null
  otherAccident?: boolean | null
  accidentState?: string | null
  employmentAccident?: boolean | null
}

export type Address = {
  city?: string | null
  state?: string | null
  zipCode?: string | null
  addressLine2?: string | null
  addressLine1?: string | null
  country?: string | null
}

export type AddressUpperCase = {
  City?: string | null
  State?: string | null
  ZipCode?: string | null
  AddressLine2?: string | null
  AddressLine1?: string | null
  Country?: string | null
}
// Address object in locations
export type LocationAddress = {
  AddressLine1: string
  AddressLine2: string
  City: string
  State: string
  Zipcode: string
}

export enum Sex {
  F = 'F',
  M = 'M',
  X = 'X',
  Unknown = 'Unknown',
}

export enum TAX_TYPE {
  SSN = 'SSN',
  EIN = 'EIN',
}

export type BillingType = 'Provider' | 'Practice'

export enum ClaimStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PAID = 'PAID',
  INGESTION_ERROR = 'INGESTION_ERROR',
  CHANGE_ERROR = 'CHANGE_ERROR',
  PAYMENT_PROCESSED = 'PAYMENT_PROCESSED',
  DB_DELETED = 'DB_DELETED',
  CANCELED = 'CANCELED',
  ADJUDICATED = 'ADJUDICATED',
  MANUALLY_ADJUDICATED = 'MANUALLY_ADJUDICATED',
  MANUALLY_SUBMITTED = 'MANUALLY_SUBMITTED',
}

/**
 * The Types requires dor the ManualClaimStatusUpdateModal
 * - Remove the V1 types once we can deprecate V1
 *
 * ASSUMPTION: the ClaimStatus.<key> === CLAIM_STATUS.<key>
 */
export type ClaimStatusForManualStatusUpdateModal = ClaimStatus | CLAIM_STATUS

// form
export type AddressInput = {
  Address1: string | null
  Address2: string | null
  City: string | null
  State: string | null
  Zipcode: string | null
}

export type BoolOptionValue = 'yes' | 'no'

export type ClaimFormProvider = {
  isIncluded?: boolean | null
  providerId: string | null
  taxType: TAX_TYPE | null
  providerEin: string | null
  providerSsn: string | null
  providerSsnFirstFive?: string | null
  providerSsnLastFour?: string | null
  providerNpi: string | null
  providerTaxonomyCode: string | null
}

export type ClaimFormDiagnosis = {
  diagnosisId: string
  code: string
  description?: string
  isExcluded: boolean
  diagnosisDate: string | null
}

export type ClaimFormDiagnosisSeparator = { title: string }
export type ClaimFormDiagnosisRow =
  | ClaimFormDiagnosis
  | ClaimFormDiagnosisSeparator

export type ClaimFormLineItem = LineItem & { billingTemplateId?: number }
export type ClaimFormDiagnosisWithOrder = ClaimFormDiagnosis & { order: number }

/**
 * Schema for UI data
 */
export type ClaimForm = {
  claimMemo: string | null
  patient: {
    firstName: string | null
    lastName: string | null
    dateOfBirth: string | null
    address: AddressInput | null
    address2: string | null
    legalSex: Sex | null
  }
  primaryInsurance: {
    name: string | null
    claimFilingCode: string | null
    memberId: string | null
    groupId: string | null
    informationReleaseAuthorized: BoolOptionValue | null
    paymentAuthorized: BoolOptionValue | null
    subscriberRelationship: string | null
    subscriberFirstName: string | null
    subscriberLastName: string | null
    subscriberDateOfBirth: string | null
    subscriberLegalSex: Sex | null
    subscriberEmail: string | null
    subscriberPhoneNumber: string | null
    subscriberAddressSameAsPatient: BoolOptionValue | null
    subscriberAddress: AddressInput | null
    subscriberAddress2: string | null
  }
  selectedInsuranceName: string | null
  payerAddress: {
    isIncluded: boolean | null
    address: AddressInput | null
    address2: string | null
  }
  secondaryInsurance: {
    isIncluded: boolean | null
    name: string | null
    claimFilingCode: string | null
    insuranceTypeCode: string | null
    memberId: string | null
    groupId: string | null
    informationReleaseAuthorized: BoolOptionValue | null
    paymentAuthorized: BoolOptionValue | null
    subscriberRelationship: string | null
    subscriberFirstName: string | null
    subscriberLastName: string | null
    subscriberDateOfBirth: string | null
  }
  referringProvider: {
    isIncluded: boolean | null
    providerFirstName: string | null
    providerLastName: string | null
    providerNpi: string | null
    providerEin: string | null
  }
  note: {
    noteId: string | null
    startDate: string | null
    endDate: string | null
    practiceName: string | null
    locationId: string | null
    address: AddressInput | null
    address2: string | null
    posCode: string | null
    practiceNpi: string | null
  }
  renderingProvider: ClaimFormProvider
  supervisingProvider: ClaimFormProvider
  billing: {
    type: BillingType | null
    name: string | null
    providerId: string | null
    locationId: string | null
    address: AddressInput | null
    address2: string | null
    phoneNumber: string | null
    taxType: TAX_TYPE | null
    ein: string | null
    ssn: string | null
    ssnFirstFive?: string | null
    ssnLastFour?: string | null
    npi: string | null
    taxonomyCode: string | null
  }
  patientConditionRelation: {
    employmentAccident: BoolOptionValue | null
    autoAccident: BoolOptionValue | null
    accidentState: string | null
    otherAccident: BoolOptionValue | null
  }
  diagnoses: ClaimFormDiagnosis[]
  authNumber: string | null
  lineItems: ClaimFormLineItem[]
  ndcs: NdcItem[]
  signature: {
    providerId: string | null
  }
}

export type ClaimFormNestedSectionKeys = Exclude<
  keyof ClaimForm,
  'claimMemo' | 'selectedInsuranceName' | 'authNumber'
>

export type ClaimFormStatus =
  | null
  | 'INITIAL_LOADING'
  | 'SAVING_CLAIM'
  | 'DELETING_CLAIM'
  | 'CANCELLING_CLAIM'
  | 'SAVING_CLAIM_MEMO'
  | 'SUBMITTING_CLAIM'
  | 'MANUALLY_UPDATING_CLAIM_STATUS'

export interface ClaimProvider {
  name: string
  phone: string | null
  billingNPI: string | null
  ein: string | null
  ssnFirstFive: string | null
  ssnLastFour: string | null
  useEINorSSN: TAX_TYPE | null
  email: string
  cognitoId: string
  signatureFont: string | null
}

export type Option = {
  label: string
  value: string
}

export type InlineFieldProps = {
  name: string
  type: string
  label?: string
  placeholder?: string
  options?: { value: string; label: string }[]
  disabled?: boolean
  isRequired?: boolean
  /**
   * Returns truthy (error message) if there is an error, otherwise falsy
   */
  validator?: (val: any) => string | undefined
  formatter?: (val: string) => string
  isFetching?: boolean // fetching remote data in select component
  onChange?: (val: any, name: string) => void
}

export type SectionToggleProps = {
  name: string
  label: string
  disabled?: boolean
}

type BillingInfoFieldLabel =
  | { label: string }
  | { label: { Practice: string; Provider: string } }
export type BillingInfoFieldProps = Omit<InlineFieldProps, 'label'> &
  BillingInfoFieldLabel

export type ClaimLocation = {
  LocationId: string
  LocationName: string
  BillingNPI: string
  Ein: string
  PhoneNumber: string
  Pos: string
  AddressLine1: string
  AddressLine2: string
  City: string
  State: string
  Zipcode: string
}

// Error returned from CHC
export type ChcError = {
  description: string
}
// Error from zod validation
export type ZodError = {
  code: string
  expected: string
  received: string
  path: string[]
  message: string
}
// Error from Joi validation
export type JoiError = {
  message: string
  path: (string | number)[]
  type: string
  context?: {
    [index: string]: any
    key?: string
    label?: string
    value?: any
  }
}
export type ErrorList = ChcError[] | ZodError[] | JoiError[]
// Error response from claim save and claim submit
export type ErrorResponse = {
  title?: string
  error: string
  errors?: ErrorList
  detail?: string
  type?: string
}
export type ClaimErrorOptions = {
  title?: string
  description?: string
  type?: AlertProps['type']
}
export type IsReadOnlyModeProp = {
  readOnly?: boolean
}
