import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { getClaimsByPatientId } from '../../api/insuranceClaimsV2'
import { InsuranceClaim } from '../../shared-types'

export enum QueryKeys {
  INSURANCE_CLAIMS = 'INSURANCE_CLAIMS',
}

export const useGetClaimsByPatientId = (
  patientId: string,
  claimsFeatureFlag: boolean
): UseQueryResult<InsuranceClaim[]> => {
  return useQuery<InsuranceClaim[]>([QueryKeys.INSURANCE_CLAIMS, patientId], {
    queryFn: async () => getClaimsByPatientId(patientId),
    staleTime: 10000, // data considered "fresh" for 10 seconds
    retry: false,
    enabled: claimsFeatureFlag,
  })
}
