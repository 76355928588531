import React, { JSX } from 'react'

import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react'
import { Alert } from 'antd'

import { notification } from '../../../../../libs/notificationLib'
import { useGetNoteBlock } from '../../../hooks/blocks/useGetNoteBlock'
import { useUpdateNoteBlock } from '../../../hooks/blocks/useUpdateNoteBlock'
import { Note } from '../../../types'
import {
  DYNAMIC_ALLERGIES_BLOCK,
  DYNAMIC_DIAGNOSIS_BLOCK,
  DYNAMIC_MEDICATIONS_BLOCK,
  DynamicNoteBlock,
  UpdateDynamicBlockOptions,
} from '../Core/Block/DynamicBlockTypes'
import { DragHandle } from '../Core/DragHandle/DragHandle'
import { AllergiesBlock } from './types/allergies/AllergiesBlock'
import { DiagnosesBlock } from './types/diagnoses/DiagnosesBlock'
import { MedicationsBlock } from './types/medications/MedicationsBlock'

import styles from './DynamicBlockRenderer.module.scss'

/**
 * Returns a dynamic block component based on the block type.
 *
 * @param {object} args - The arguments.
 * @param {DynamicNoteBlock} args.block - The dynamic block.
 * @param {Note} args.note - The note.
 * @param {(opts: UpdateDynamicBlockOptions) => void} args.updateNoteBlock - The function to update the dynamic block.
 * @returns {JSX.Element | null} - The dynamic block component.
 */
export const getDynamicBlockData = (args: {
  block?: DynamicNoteBlock
  note: Note
  updateNoteBlock: (opts: UpdateDynamicBlockOptions) => Promise<void>
  isBlockFetching: boolean
}): JSX.Element | null => {
  const { block, note, updateNoteBlock, isBlockFetching } = args
  if (!block) {
    return null
  }

  switch (block.blockType) {
    case DYNAMIC_MEDICATIONS_BLOCK:
      return (
        <MedicationsBlock
          block={block}
          note={note}
          onBlockUpdate={updateNoteBlock}
          isRefreshing={isBlockFetching}
        />
      )
    case DYNAMIC_ALLERGIES_BLOCK:
      return (
        <AllergiesBlock
          block={block}
          note={note}
          onBlockUpdate={updateNoteBlock}
          isRefreshing={isBlockFetching}
        />
      )
    case DYNAMIC_DIAGNOSIS_BLOCK:
      return (
        <DiagnosesBlock
          block={block}
          note={note}
          onBlockUpdate={updateNoteBlock}
          isRefreshing={isBlockFetching}
        />
      )
    default:
      return null
  }
}

export const DynamicBlockRouter = ({
  props,
  note,
}: {
  props: NodeViewRendererProps
  note: Note
}) => {
  const { id: blockUuid } = props.node.attrs

  const {
    data: block,
    isLoading: isBlockLoading,
    isFetching,
    isError,
    isStale,
  } = useGetNoteBlock({
    noteUuid: note.uuid,
    blockUuid,
  })

  const { updateNoteBlock, isLoading: isUpdating } = useUpdateNoteBlock()

  const handleUpdateNoteBlock = async (opts: UpdateDynamicBlockOptions) => {
    if (!block) return
    await updateNoteBlock(opts)
    notification(
      `Active ${
        block.blockType.charAt(0).toUpperCase() +
        block.blockType.slice(1).toLowerCase()
      } table has been refreshed`,
      'success'
    )
    return
  }

  if (isError) {
    return (
      <NodeViewWrapper className="structured-block">
        <Alert
          message="Unable to load patient info table."
          description="Please try deleting and re-inserting from the Insert menu, or reloading the note."
          type="error"
          showIcon
          className={styles.fullWidthAlert}
        />
      </NodeViewWrapper>
    )
  }

  if ((isBlockLoading && !isError) || !block) {
    return (
      <NodeViewWrapper className="structured-block">Loading...</NodeViewWrapper>
    )
  }
  const content = getDynamicBlockData({
    block,
    note,
    updateNoteBlock: handleUpdateNoteBlock,
    /**
     * We only want to show the fetching 'loading' spinner if:
     * - The cache data is invalid/stale and an active fetch is happening.
     *    - If valid data exists in the cache, we don't need the show a loading state even when refetching,
     *      because the existing data can be displayed as-is.
     * - Or, an explicit update/refresh is happening.
     */
    isBlockFetching: (isStale && isFetching) || isUpdating,
  })

  const isSigned = !!note?.firstSignedAt

  return (
    <NodeViewWrapper
      className={!isSigned ? 'structured-block' : 'signed-structured-block'}
      key={block.uuid}
    >
      {!isSigned && <DragHandle />}
      <div className={styles.container}>{content}</div>
    </NodeViewWrapper>
  )
}
