import { ArrowRightOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { useProfileUrlParams } from '../../../../hooks/useProfileUrlParams'
import { useAppContext } from '../../../../libs/contextLib'
import { ExternalLink } from '../Icons/ExternalLink'
import { ActiveAllergiesBlock } from './ActiveAllergiesBlock'
import { ActiveDiagnosisBlock } from './ActiveDiagnosisBlock'
import { ActiveMedicationsBlock } from './ActiveMedicationsBlock/ActiveMedicationsBlock'
import { BlockConfig, BlockPermissions } from './types'

/**
 * Legacy block configurations.
 * We need to keep these to maintain compatibility with any pre-existing notes
 * that contain legacy blocks in them, until we can do a migration.
 */
export const blockConfig: BlockConfig[] = [
  {
    name: 'TestBlock',
    label: 'Test block',
    tag: 'test-block',
    actionIcon: <ArrowRightOutlined />,
    actionLabel: () => 'Contextual Action',
    onActionClick: () => console.log('Contextual action clicked'),
    header: 'Test header',
    content: () => <div>Test Block Content</div>,
    permissions: BlockPermissions.PREPROD,
  },
  {
    name: 'ActiveAllergies',
    label: 'Active allergies',
    tag: 'active-allergies',
    actionIcon: <ExternalLink />,
    actionLabel: ({ options }) =>
      options?.isDrFirst ? 'Edit allergies in Dr. First' : 'Edit allergies',
    onActionClick: ({ options }) => {
      if (!options) return

      if (options.isDrFirst) {
        window.open(
          `/patient/medication?${options.urlParams}`,
          '_blank',
          'noreferrer'
        )
      } else {
        window.open(
          `/patient/profile?${options.urlParams}&patientProfile=allergies`,
          '_blank',
          'noreferrer'
        )
      }
    },
    header: 'Active allergies',
    content: (options: any, isSigned?: boolean) => (
      <ActiveAllergiesBlock isSigned={isSigned} />
    ),
    permissions: BlockPermissions.FEATURE_FLAG,
    useOptions: () => {
      const { appSettings } = useAppContext()

      const { urlParams } = useProfileUrlParams()

      return {
        isDrFirst: !!appSettings?.erx,
        urlParams,
      }
    },
  },
  {
    name: 'ActiveMedications',
    label: 'Active medications',
    tag: 'active-medications',
    actionIcon: <ExternalLink />,
    actionLabel: ({ options }) =>
      options?.isDrFirst ? 'Edit medications in Dr. First' : 'Edit medications',
    onActionClick: ({ options }) => {
      if (!options) return
      window.open(
        `/patient/medication?${options.urlParams}`,
        '_blank',
        'noreferrer'
      )
    },
    header: 'Active medications',
    content: (options?: Record<string, any>, isSigned?: boolean) => (
      <ActiveMedicationsBlock options={options} isSigned={isSigned} />
    ),
    permissions: BlockPermissions.FEATURE_FLAG,
    useOptions: () => {
      const { appSettings } = useAppContext()

      const { urlParams } = useProfileUrlParams()

      return {
        isDrFirst: !!appSettings?.erx,
        urlParams,
      }
    },
  },
  {
    name: 'ActiveDiagnoses',
    label: 'Active diagnoses',
    tag: 'active-diagnoses',
    actionIcon: <PlusCircleOutlined />,
    actionLabel: () => 'Add active diagnosis',
    onActionClick: ({ options }) => {
      if (!options) return

      window.open(
        `/patient/profile?${options.urlParams}&patientProfile=diagnoses`,
        '_blank',
        'noreferrer'
      )
    },
    header: 'Active diagnoses',
    content: (options: any, isSigned?: boolean) => (
      <ActiveDiagnosisBlock isSigned={isSigned} />
    ),
    permissions: BlockPermissions.FEATURE_FLAG,
    useOptions: () => {
      const { urlParams } = useProfileUrlParams()

      return {
        urlParams,
      }
    },
  },
]
