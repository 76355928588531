import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { FORM_SUCCESS_URL } from 'stories/PatientIntake/PatientIntake'

import { IntakeForm, getIncompleteIntakeForm } from '../../api/intakeForms'

interface UsePatientFormState {
  error: string | null
  loading: boolean
  data:
    | (IntakeForm & {
        provider: {
          ProviderFirstName: string
          ProviderLastName: string
          ProviderCredential?: string
        }
      })
    | null
}

export default function useIntakeForm({
  formId,
  isViewedFromApp,
}: {
  formId: string
  isViewedFromApp: boolean
}): UsePatientFormState {
  const history = useHistory()
  const [error, setError] = useState<null | string>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<
    | null
    | (IntakeForm & {
        provider: {
          ProviderFirstName: string
          ProviderLastName: string
          ProviderCredential?: string
        }
      })
  >(null)

  useEffect(() => {
    setLoading(true)

    const fetchForm = async () => {
      try {
        const incompleteForm = await getIncompleteIntakeForm(formId)

        if (
          new Date().getTime() >= new Date(incompleteForm.expiredAt).getTime()
        ) {
          history.push('/form/expired')
          return
        }

        setData(incompleteForm)
      } catch (err) {
        const errorMessage = err.response?.data?.error ?? undefined

        if (errorMessage === 'This form is no longer accessible') {
          const redirectUrl = isViewedFromApp
            ? `${FORM_SUCCESS_URL}?source=app`
            : FORM_SUCCESS_URL
          history.push(redirectUrl)
        } else {
          console.error(errorMessage ?? err)
          setError(
            errorMessage ??
              'Unable to access this form. Please contact an Osmind support rep.'
          )
        }
      } finally {
        setLoading(false)
      }
    }

    fetchForm()
  }, [formId])

  return {
    error,
    loading,
    data,
  }
}
