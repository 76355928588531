import { API } from 'aws-amplify'

import PaymentLockManager from '../services/PaymentLockManager'

export const createClaimFromPatientInfo = async ({
  patientId,
  noteId,
}: {
  patientId: string
  noteId: string | undefined
}) => {
  return API.post(
    'insurance',
    `/insurance/patient/${patientId}/${noteId || 'empty'}/claim`,
    {}
  )
}

export const postCancelClaim = async (
  claimId: string,
  patientId: string,
  body: any
) => {
  return PaymentLockManager.withLock(patientId, 'postCancelClaim', async () => {
    return API.post('insurance', `/insurance/claim/${claimId}`, {
      body,
    })
  })
}

export const updateClaim = async (
  patientId: string,
  claimId: string,
  body: any
) => {
  return API.put(
    'insurance',
    `/insurance/patient/${patientId}/claim/${claimId}/update`,
    {
      body,
    }
  )
}

export const updateClaimMemo = ({
  claimId,
  patientId,
  body,
}: {
  claimId: string
  patientId: string
  body: { claimMemo: string | null }
}) => {
  return API.put(
    'insurance',
    `/insurance/patient/${patientId}/claim/${claimId}/updateMemo`,
    {
      body,
    }
  )
}

export const submitClaimManually = ({
  claimId,
  patientId,
  body,
}: {
  claimId: string
  patientId: string
  body: Record<string, unknown>
}) => {
  return API.put(
    'insurance',
    `/insurance/patient/${patientId}/claim/${claimId}/submitClaimManually`,
    {
      body,
    }
  )
}

export const adjudicateClaimManually = ({
  claimId,
  patientId,
}: {
  claimId: string
  patientId: string
}) => {
  return API.put(
    'insurance',
    `/insurance/patient/${patientId}/claim/${claimId}/adjudicateClaimManually`,
    {}
  )
}

export const submitClaim = async (patientId: string, body: any) => {
  return API.post('insurance', `/insurance/patient/${patientId}/claim/submit`, {
    body,
  })
}

// TODO: this route should not have a /get at the end of it
export const getClaimById = async (
  patientId: string,
  insuranceClaimId: string
) => {
  return API.get(
    'insurance',
    `/insurance/patient/${patientId}/claim/${insuranceClaimId}/get`,
    {}
  )
}
/**
 * @deprecated use getClaimsByPatientId in src/api/insuranceClaimsV2.ts
 */
export const getClaimsByPatientId = async (patientId: string) => {
  return API.get('insurance', `/insurance/patient/${patientId}/claims`, {})
}

export const getPaymentInfo = async (claimId: string) => {
  return API.get('insurance', `/insurance/payment/${claimId}`, {})
}

export const setPaymentInfo = async (
  claimId: string,
  patientId: string,
  writeOffAmountCents?: number,
  patientResponsibilityAmountCents?: number,
  paymentAmountOfCents?: number
) => {
  const getValueOrDefault = (value: Number | undefined) =>
    value === 0 || value ? value : undefined

  //If its 0 or a number we need to send 0 or the number else if its undefined or null we need to send undefined
  const body = {
    claimId,
    writeOffAmountCents: getValueOrDefault(writeOffAmountCents),
    patientResponsibilityAmountCents: getValueOrDefault(
      patientResponsibilityAmountCents
    ),
    paymentAmountOfCents: getValueOrDefault(paymentAmountOfCents),
  }
  return PaymentLockManager.withLock(patientId, 'setPaymentInfo', async () => {
    return API.post('insurance', `/insurance/payment`, {
      body,
    })
  })
}

export const setValueOrigin = async (body: any) => {
  return PaymentLockManager.withLock(
    body.patientId,
    'setValueOrigin',
    async () => {
      return API.post('insurance', `/insurance/payment/era`, {
        body,
      })
    }
  )
}

export const deleteClaim = async ({
  patientId,
  claimId,
}: {
  patientId: string
  claimId: string
}) => {
  return PaymentLockManager.withLock(patientId, 'deleteClaim', async () => {
    return API.del(
      'insurance',
      `/insurance/patient/${patientId}/claim/${claimId}/delete`,
      {}
    )
  })
}
