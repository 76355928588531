import React from 'react'

import { useCreateClaimAndNavigateThere } from 'containers/Patient/ClaimsV2/useCreateClaimAndNavigateThere'
import { Button, Spinner } from 'stories/BaseComponents'

export type CreateNewClaimButtonProps = {
  patientId: string
  providerId: string
  noteId?: string
}

export const testIds = {
  createNewClaimButton: 'create-new-claim-button',
  createNewClaimButtonSpinner: 'create-new-claim-button--spinner',
}

/**
 * Creates a new Claim for Claims V1/V2
 * - Button disables once clicked, will show a spinner until resolution
 *
 * NOTE: This is only used in ClaimsV1, but being preserved in ClaimsV2 because we'd use a similar setup for testing useCreateClaimAndNavigateThere
 * - noteId is only used for testing purposes (for `useCreateClaimAndNavigateThere` functional tests)
 */
export const CreateNewClaimButton: React.FC<
  React.PropsWithChildren<CreateNewClaimButtonProps>
> = ({ patientId, providerId, noteId, children, ...rest }) => {
  const { createClaimAndNavigateThere, isCreating } =
    useCreateClaimAndNavigateThere({ patientId, providerId })

  const _createClaimAndNavigateThere = () =>
    createClaimAndNavigateThere({ noteId })

  return (
    <Button
      testId={testIds.createNewClaimButton}
      disabled={isCreating}
      onClick={_createClaimAndNavigateThere}
      {...rest}
    >
      {isCreating ? (
        <Spinner
          fontSize={10}
          data-testid={testIds.createNewClaimButtonSpinner}
        />
      ) : (
        children
      )}
    </Button>
  )
}
