import {
  Editor,
  JSONContent,
  EditorContent as TipTapEditorContent,
} from '@tiptap/react'

import { Skeleton } from '../../../../../stories/BaseComponents'

import styles from './EditorContent.module.scss'

/**
 * Helper method for traversing TipTap editor JSON content.
 * @param node The root node of the document.
 * @param cb A callback to trigger on each node in the content.
 */
export const traverseEditorContent = (
  node: JSONContent,
  cb: (node: JSONContent) => void
) => {
  cb(node)
  node.content?.forEach((childNode) => {
    traverseEditorContent(childNode, cb)
  })
}

interface EditorContentProps {
  isLoading: boolean
  editor: Editor | null
}

export function EditorContent({ isLoading, editor }: EditorContentProps) {
  // Give the editor focus if the user clicks in the padding
  // area at the end of the editor.
  const handleBottomOfEditorClick = () => {
    if (editor && !editor.isFocused) {
      editor.commands.focus('end')
    }
  }

  return (
    <div className={styles.contentWrapper}>
      {isLoading && (
        <Skeleton
          title={{
            className: styles.skeletonBlock,
          }}
          paragraph={{
            rows: 3,
            className: styles.skeletonBlock,
          }}
        />
      )}
      {!isLoading && (
        <>
          <TipTapEditorContent className={styles.editor} editor={editor} />
          <div
            data-testid={'EditorContent-trailingPadding'}
            className={styles.trailingPadding}
            onClick={handleBottomOfEditorClick}
            // Hide from screen readers, or else sonarcloud flags this:
            // "Visible, non-interactive elements with click handlers must have at least one keyboard listener."
            aria-hidden="true"
          />
        </>
      )}
    </div>
  )
}
