import React from 'react'

import {
  BoldOutlined,
  CheckSquareOutlined,
  HighlightOutlined,
  ItalicOutlined,
  OrderedListOutlined,
  PlusOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { Editor } from '@tiptap/react'

import { DropdownSelector, DropdownSelectorItem } from './DropdownSelector'
import { HeadingDropdown } from './HeadingDropdown'
import { ToggleSection } from './ToggleSection'
import RedoIcon from './icons/RedoIcon'
import UndoIcon from './icons/UndoIcon'

import styles from './EditorToolbar.module.scss'

const Separator = () => <div className={styles.separator} />

interface EditorToolbarProps {
  editor?: Editor
  isLoading: boolean
  disableInsertMenu?: boolean
  insertMenuItems?: DropdownSelectorItem[]
}

export const EditorToolbar = ({
  editor,
  isLoading,
  disableInsertMenu,
  insertMenuItems,
}: EditorToolbarProps) => {
  return (
    <>
      <div className={styles.toolbar}>
        <ToggleSection
          items={[
            {
              name: 'undo',
              icon: (
                <UndoIcon width={'1em'} height={'1em'} color="currentColor" />
              ),
              command: () => editor?.chain().focus().undo(),
            },
            {
              name: 'redo',
              icon: (
                <RedoIcon width={'1em'} height={'1em'} color="currentColor" />
              ),
              command: () => editor?.chain().focus().redo(),
            },
          ]}
          editor={editor}
          isLoading={isLoading}
        />

        <Separator />

        <HeadingDropdown editor={editor} isLoading={isLoading} />

        <Separator />

        <ToggleSection
          items={[
            {
              name: 'bold',
              icon: <BoldOutlined />,
              command: () => editor?.chain().focus().toggleBold(),
            },
            {
              name: 'italic',
              icon: <ItalicOutlined />,
              command: () => editor?.chain().focus().toggleItalic(),
            },
            {
              name: 'underline',
              icon: <UnderlineOutlined />,
              command: () => editor?.chain().focus().toggleUnderline(),
            },
            {
              name: 'bulletList',
              icon: <UnorderedListOutlined />,
              command: () => editor?.chain().focus().toggleBulletList(),
            },
            {
              name: 'orderedList',
              icon: <OrderedListOutlined />,
              command: () => editor?.chain().focus().toggleOrderedList(),
            },
            {
              name: 'taskList',
              icon: <CheckSquareOutlined />,
              command: () => editor?.chain().focus().toggleTaskList(),
            },
            {
              name: 'highlight',
              icon: <HighlightOutlined />,
              command: () =>
                editor?.chain().focus().toggleHighlight({ color: '#ffffb8' }),
            },
          ]}
          editor={editor}
          isLoading={isLoading}
        />

        <Separator />

        {insertMenuItems && insertMenuItems.length > 0 && (
          <>
            <DropdownSelector
              icon={<PlusOutlined />}
              label="Insert"
              testId="editor-toolbar-insert"
              items={insertMenuItems}
              isLoading={isLoading || !!disableInsertMenu}
            />

            <Separator />
          </>
        )}
      </div>
    </>
  )
}
