import React from 'react'

import Payments from 'containers/Patient/Claims/Payment'
import { useTransactions } from 'hooks/useBillingInfo'
import { usePaymentsInfoForClaim } from 'hooks/usePatientProfile'
import { InsuranceClaim } from 'shared-types'
import { Card, Spinner } from 'stories/BaseComponents'
import { BillableEntityPayments } from 'stories/Invoice/BillableEntityPayments'

import { sectionContainerBodyStyle, sectionContainerStyle } from '../constants'
import { ClaimData } from '../types'

import styles from './shared.module.scss'

export const testIds = {
  payments: 'claim-payments',
  transactions: 'claim-transactions',
  loadingIndicator: 'claim-payments-loading-indicator',
}
type ClaimPaymentsProps = {
  claim: ClaimData
  patientId: string
  isEditMode: boolean
  setIsEditMode: (val: boolean) => void
  refetchClaim: () => void
}
// TODO: we are sharing Payments component with V1, pull it in when we are ready to wipe out V1
const ClaimPayments: React.FC<ClaimPaymentsProps> = ({
  claim,
  patientId,
  isEditMode,
  setIsEditMode,
  refetchClaim,
}) => {
  const { claimId, patientControlNumber, billableEntity } = claim
  const {
    data: paymentInfoData,
    isLoading: isLoadingPaymentInfo,
    refetch: refetchPaymentsInfo,
  } = usePaymentsInfoForClaim({ claimId, patientId, isEnabled: true })

  const { data: transactionsData, isLoading: isLoadingTransactionsData } =
    useTransactions({
      patientId,
      insuranceClaimUuid: claimId,
    })

  const isLoading = isLoadingPaymentInfo || isLoadingTransactionsData
  const hasPaymentInfoAndTransactionsLoaded = !!(
    paymentInfoData && transactionsData
  )
  const hasTransactions = !!transactionsData?.payments?.length
  return isLoading ? (
    <Card style={sectionContainerStyle} bodyStyle={sectionContainerBodyStyle}>
      <div
        className={styles.loadingContainer}
        data-testid={testIds.loadingIndicator}
      >
        <Spinner />
      </div>
    </Card>
  ) : (
    <>
      <Card style={sectionContainerStyle} bodyStyle={{ padding: 0 }}>
        {hasPaymentInfoAndTransactionsLoaded && (
          <Payments
            testId={testIds.payments}
            claim={claim}
            paymentInfo={paymentInfoData}
            patientId={patientId}
            claimId={claimId}
            refetch={refetchPaymentsInfo}
            refetchClaim={refetchClaim}
            isLoading={isLoadingPaymentInfo}
            companyToSubmitTo={claim.selectedInsuranceName ?? null}
            claimStatus={claim.claimStatus}
            editPayments={isEditMode}
            setEditPayments={setIsEditMode}
            payments={transactionsData.payments}
            refunds={transactionsData.refunds}
          />
        )}
      </Card>
      {hasTransactions && (
        <div style={sectionContainerStyle}>
          <BillableEntityPayments
            testId={testIds.transactions}
            isLoading={isLoadingTransactionsData}
            payments={transactionsData.payments}
            insuranceClaim={
              {
                uuid: claimId,
                patientControlNumber: patientControlNumber,
                billableEntity: billableEntity,
              } as InsuranceClaim
            }
            insuranceClaimUuid={claimId}
            patientId={patientId}
            patientControlNumber={patientControlNumber}
          />
        </div>
      )}
    </>
  )
}

export default ClaimPayments
