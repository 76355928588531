import React, { useCallback, useEffect, useMemo, useState } from 'react'

import cx from 'classnames'
import { endOfMonth, startOfDay, startOfMonth, subMonths } from 'date-fns'
import { pick } from 'lodash'
import { NotesReportFetchResponse } from 'stories/Reports/constructor/NotesConstructor'

import DatePicker, {
  NonNullableDateChangeHandler,
} from '../BaseComponents/Datepicker'
import Header from '../BaseComponents/Header'
import Metrics, { MetricObject } from '../BaseComponents/Metrics'
import { NotesReportData, TableData } from '../BaseComponents/tableData.types'
import NotesTabs from './NotesTabs'
import ReportGraph from './ReportGraph'
import {
  DataToAggregate,
  DataTypes,
  buildTableDataStrategy,
  createAggregationsForDateRange,
  graphDisplayConstructor,
} from './ReportPageHelpers'
import ReportTable from './ReportTable'
import {
  METRICS_HEADER_TOTAL_APPOINTMENTS,
  METRICS_HEADER_TOTAL_NOTES,
  METRICS_HEADER_TOTAL_SURVEYS,
  METRICS_HEADER_UNSIGNED_NOTES,
} from './constants'
import ReportFactory from './constructor'
import useGraphFilters from './hooks/useGraphFilters'
import { ActiveGraphFilter, GraphDataProps, ReportType } from './types'

import '../../components/Other/ConfirmationMessage.scss'
import styles from '../../containers/_shared.module.scss'
import '../ReportPages.scss'

interface ReportPage {
  reportType: ReportType
}

export function getReportInitialDateRange(): {
  startDate: Date
  endDate: Date
} {
  const now = new Date()
  return {
    // Note: There are performance impacts to consider if the default # of months
    // is adjusted, since many of our APIs don't currently have pagination.
    startDate: subMonths(startOfMonth(now), 5),
    endDate: endOfMonth(now),
  }
}

const ReportPage: React.FC<ReportPage> = ({ reportType }) => {
  // We need to generate a constructor based on the type
  const {
    fetch,
    header,
    graph: _graph,
    graphFilterConfigs,
    dataBatching: { options: dataBatchOptions, fetchKeyAlias },
    table,
  } = useMemo(() => ReportFactory[reportType], [reportType])

  const defaultBatchOption = dataBatchOptions[0]
    ? dataBatchOptions[0].value
    : 'type'

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dateRangeValues, setDateRange] = useState<{
    startDate: Date
    endDate: Date
  }>(getReportInitialDateRange())
  const [rawReportData, setRawReportData] = useState<DataToAggregate>([]) // currently not using granular data filters yet
  const [tableData, setTableData] = useState<TableData>([])
  const [graphData, setGraphData] = useState<GraphDataProps>(
    graphDisplayConstructor()
  )
  const [metrics, setMetrics] = useState<MetricObject[]>(header.metrics)
  const [graphDataBatchKey, setGraphDataBatchKey] = useState<keyof DataTypes>(
    defaultBatchOption as keyof DataTypes
  )
  const [graphDataBatchKeyAliases, setGraphDataBatchKeyAliases] = useState<{
    [id: string]: string
  }>({})

  const { graphFilterOptions, activeGraphFilters, setActiveGraphFilters } =
    useGraphFilters(graphFilterConfigs)

  const fetchGraphDataBatchKeyAliases = useCallback(async () => {
    dataBatchOptions.forEach(async (batchOption) => {
      const fetchMethod = fetchKeyAlias[batchOption.value]

      if (fetchMethod) {
        const aliases = await fetchMethod()
        setGraphDataBatchKeyAliases((currentAliases) => ({
          ...currentAliases,
          ...aliases,
        }))
      }
    })
  }, [dataBatchOptions, graphDataBatchKeyAliases])

  const fetchData = useCallback(async () => {
    setRawReportData([])
    setTableData([])
    setGraphData(graphDisplayConstructor())
    setIsLoading(true)
    try {
      const { startDate, endDate } = dateRangeValues

      /// Using startOfDay to avoid next day error due to timezones.
      const data = await fetch(
        startOfDay(startDate).toISOString(),
        startOfDay(endDate).toISOString()
      )
      let dataInRange: DataToAggregate

      // TODO: refactor this just to use 'data' type in the backend to reduce the complexity.
      if (reportType === ReportType.APPOINTMENTS && 'appointments' in data) {
        dataInRange = data.appointments
        setMetrics([
          { title: METRICS_HEADER_TOTAL_APPOINTMENTS, dataSource: data.total },
        ])
      } else if (reportType === ReportType.NOTES) {
        const notesData = data as NotesReportFetchResponse
        dataInRange = notesData.items
        setMetrics([
          {
            title: METRICS_HEADER_TOTAL_NOTES,
            dataSource: notesData.totalCount,
          },
          {
            title: METRICS_HEADER_UNSIGNED_NOTES,
            dataSource: notesData.unsignedCount,
          },
        ])
      } else if (reportType === ReportType.SURVEYS && 'surveys' in data) {
        dataInRange = data.surveys
        setMetrics([
          { title: METRICS_HEADER_TOTAL_SURVEYS, dataSource: data.total },
        ])
      } else {
        throw new Error('unsupported report type')
      }
      setRawReportData(dataInRange)
      const transformedGraphData = createAggregationsForDateRange(
        dataInRange,
        startDate,
        endDate,
        graphDataBatchKey,
        graphDataBatchKeyAliases
      )
      const transformedTableData: TableData = buildTableDataStrategy[
        reportType
      ](dataInRange as any) // Couldn't get the types to work
      setGraphData(transformedGraphData)
      setTableData(transformedTableData)
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
  }, [dateRangeValues, fetch, reportType])

  // This will run whenever dateRangeValues change
  useEffect(() => {
    fetchData()
  }, [dateRangeValues, fetchData])

  useEffect(() => {
    fetchGraphDataBatchKeyAliases()
  }, [dataBatchOptions, fetchKeyAlias])

  useEffect(() => {
    // rawReportData isn't a dependency here because fetchData() keeps graph/table data in sync with
    const newBatchOfGraphData = createAggregationsForDateRange(
      rawReportData,
      dateRangeValues.startDate,
      dateRangeValues.endDate,
      graphDataBatchKey,
      graphDataBatchKeyAliases
    )

    setGraphData(newBatchOfGraphData)
  }, [graphDataBatchKey])

  const handleDateChange: NonNullableDateChangeHandler = useCallback(
    (startDate, endDate) => {
      setDateRange({ startDate, endDate })
    },
    []
  )

  const handleGraphFilterChange = useCallback(
    (newFilterObj: ActiveGraphFilter) => {
      const filterKey = Object.keys(newFilterObj)[0]

      if (newFilterObj[filterKey]) {
        // filter selected, add/update it as an activeGraphFilter
        setActiveGraphFilters((prevActiveFilters) => ({
          ...prevActiveFilters,
          ...newFilterObj,
        }))
      } else {
        // filter deselected (value is null) but still set active as a filter
        setActiveGraphFilters((prevActiveFilters) =>
          pick(
            prevActiveFilters,
            Object.keys(prevActiveFilters).filter((key) => key !== filterKey)
          )
        )
      }
    },
    []
  )

  const handleGraphDataBatchChange = useCallback(
    (selectedBatchKey: keyof DataTypes) => {
      setGraphDataBatchKey(selectedBatchKey)
    },
    [graphDataBatchKey]
  )

  return (
    <div key={reportType} className={cx(['report-page', styles.scroll])}>
      <Header
        description={header.description}
        link={header.link}
        title={header.title}
      >
        <DatePicker
          initialValues={dateRangeValues}
          onDateChange={handleDateChange}
        />
        <Metrics metrics={metrics} loading={isLoading} />
      </Header>
      <ReportGraph
        dataSource={graphData}
        loading={isLoading}
        filters={graphFilterOptions}
        activeFilters={activeGraphFilters}
        onFilterChange={handleGraphFilterChange}
        dataBatchOptions={dataBatchOptions}
        onDataBatchChange={handleGraphDataBatchChange}
        currentDataBatch={graphDataBatchKey}
      />
      {reportType === ReportType.NOTES ? (
        <NotesTabs
          dataSource={tableData as NotesReportData[]}
          isLoadingDataSource={isLoading}
          csvHeaders={table.csvHeaders}
          csvDataTransformer={table.csvDataTransformer}
        />
      ) : (
        <ReportTable
          columns={table.columns}
          dataSource={tableData}
          loading={isLoading}
          csvHeaders={table.csvHeaders}
          tableName={`${header.title} Report`}
        />
      )}
    </div>
  )
}

export default ReportPage
