import { DownOutlined } from '@ant-design/icons'
import {
  CLAIM_STATUS_DISPLAY,
  StatusCategoryValue,
  TERMINAL_DISPLAY_STATUSES,
} from 'shared-types'
import { Dropdown } from 'stories/BaseComponents'

import { ClaimStatus } from '../types'
import { isClaimPresubmit } from '../utils'
import ClaimStatusDisplay from './ClaimStatusDisplay'

import styles from './ClaimStatusDisplayWithUpdate.module.scss'

export const testIds = {
  updateClaimStatusDropdown: 'claim-header-update-claim-status-dropdown',
  claimStatusContent: 'claim-header-claim-status-content',
}

export const ClaimStatusDisplayWithUpdate = ({
  claimStatus,
  displayStatus,
  statusCategory,
  isLoadingManualStatusChange,
  setShouldShowStatusUpdateModal,
}: {
  claimStatus: ClaimStatus
  displayStatus: CLAIM_STATUS_DISPLAY
  statusCategory: StatusCategoryValue
  isLoadingManualStatusChange: boolean
  setShouldShowStatusUpdateModal: (shouldShow: boolean) => void
}) => {
  const submitted = !isClaimPresubmit(claimStatus)

  return !TERMINAL_DISPLAY_STATUSES.includes(displayStatus) ? (
    <Dropdown
      testId={testIds.updateClaimStatusDropdown}
      items={[
        {
          key: submitted ? 'manually-adjudicated' : 'manually-submitted',
          text: submitted
            ? CLAIM_STATUS_DISPLAY.MANUALLY_ADJUDICATED
            : CLAIM_STATUS_DISPLAY.MANUALLY_SUBMITTED,
          onClick: () => {
            setShouldShowStatusUpdateModal(true)
          },
        },
      ]}
      trigger={['click']}
      placement="bottomRight"
    >
      <div
        className={styles.topBarFieldValueDropdown}
        data-testid={testIds.updateClaimStatusDropdown}
      >
        <ClaimStatusDisplay
          testId={testIds.claimStatusContent}
          displayMessage={displayStatus}
          statusCategory={statusCategory}
          isLoading={isLoadingManualStatusChange}
        />
        <DownOutlined style={{ fontSize: '14px', marginLeft: '7px' }} />
      </div>
    </Dropdown>
  ) : (
    <div className={styles.topBarFieldValue}>
      <ClaimStatusDisplay
        testId={testIds.claimStatusContent}
        displayMessage={displayStatus}
        statusCategory={statusCategory}
        isLoading={isLoadingManualStatusChange}
      />
    </div>
  )
}
