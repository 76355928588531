import { format } from 'date-fns'

import { Divider } from '../../BaseComponents'
import { IntroSectionHeader, ParagraphBlock } from './helpers'

type MobileIntroductionProps = {
  isViewedFromApp: boolean
  expiryDate: Date
  providerName: string
}

// This allows us to not have to pass in expiryDate and providerName
// when we know that the component will be viewed from the web
type WebIntroductionProps = {
  isViewedFromApp: false
  expiryDate?: Date
  providerName?: never
}

type IntroductionProps = MobileIntroductionProps | WebIntroductionProps

const Introduction = ({
  isViewedFromApp,
  expiryDate,
  providerName,
}: IntroductionProps) => {
  return (
    <>
      <IntroSectionHeader
        sectionTitle={isViewedFromApp ? 'Intake Form' : 'Patient Intake Form'}
      />
      {isViewedFromApp ? (
        <ParagraphBlock
          shouldAddSpacing={true}
          paragraphText={`${providerName} has requested you to fill out this intake form. This information is critical to provide you with personalized, evidence-based, and high-quality care.`}
        />
      ) : (
        <>
          <ParagraphBlock
            shouldAddSpacing={true}
            paragraphText="Please fill out this form as part of the intake process. The information we collect through this form helps us gain a detailed understanding of your health and a holistic understanding of you as a person. This is critical for us to provide you with personalized, evidence-based, and high-quality care."
          />
          <ParagraphBlock
            shouldAddSpacing={true}
            paragraphText="All information you enter into this form will be kept strictly confidential and private. This electronic form is HIPAA-compliant and the information is stored in a HIPAA-compliant, encrypted database."
          />
        </>
      )}

      <Divider />
      <IntroSectionHeader levelSize={4} sectionTitle="Before you begin" />
      {isViewedFromApp ? (
        <>
          <ParagraphBlock
            shouldAddSpacing={true}
            paragraphText="For security and privacy reasons, your progress will not be saved, so you should plan to fill this out in a single session."
          />
          <ParagraphBlock
            shouldAddSpacing={true}
            paragraphText={`This form expires on ${format(
              expiryDate,
              'MM/dd/yyyy'
            )}.`}
          />
        </>
      ) : (
        <>
          <ParagraphBlock
            shouldAddSpacing={true}
            paragraphText="For security and privacy reasons, your progress will not be saved, so you should plan to fill this out in a single session. Hitting the “Back” button in your browser will also require you to restart the form."
          />
          <ParagraphBlock
            shouldAddSpacing={true}
            paragraphText="Please be sure to have information about your medical history on hand."
          />
          <ParagraphBlock
            shouldAddSpacing={true}
            paragraphText="Thank you for taking your time to fill out this form accurately and in detail."
          />
          {expiryDate && (
            <ParagraphBlock
              shouldAddSpacing={true}
              paragraphText={`This form expires on ${format(
                expiryDate,
                'MM/dd/yyyy'
              )}.`}
            />
          )}
        </>
      )}
      <Divider />
    </>
  )
}

export default Introduction
