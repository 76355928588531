import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useFeatureFlags } from 'libs/featureFlags'
import { Modal } from 'react-bootstrap'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import {
  getNoteData,
  markNoteAsBeingEdited,
  markNoteAsNotBeingEdited,
} from '../../../api/api-lib'
import { autoSaveClinicalNotesV1 } from '../../../api/notes'
import ConfirmationModal from '../../../components/Other/ConfirmationModal'
import DisabledFeatureMessage from '../../../components/Other/DisabledFeatureMessage'
import { AutoSaveAuditLogs } from '../../../hooks/useAutoSaveNoteV1'
import useClinicalNoteFields, {
  UpdateType,
} from '../../../hooks/useClinicalNoteFields'
import { AutosaveTriggerCreateNoteKeyword } from '../../../hooks/useClinicalNoteFields/utils'
import { usePatientAllergies } from '../../../hooks/usePatientAllergies'
import { usePatientClinicalNotes } from '../../../hooks/usePatientClinicalNotes'
import { usePatientDiagnoses } from '../../../hooks/usePatientDiagnoses'
import { usePatientDemographics } from '../../../hooks/usePatientInfo'
import { usePatientMedications } from '../../../hooks/usePatientMedications'
import { useProfileUrlParams } from '../../../hooks/useProfileUrlParams'
import { useProviderDetails } from '../../../hooks/useProviderInfo'
import { useProviderSidePatientData } from '../../../hooks/useProviderSidePatientData'
import useQueryString from '../../../hooks/useQueryString'
import { useSetClinicalNoteBackup } from '../../../hooks/useSetClinicalNoteBackup'
import { QUERY_PARAMS } from '../../../libs/constants'
import { onError } from '../../../libs/errorLib'
import {
  NotesEvents,
  trackNoteEditingAcrossTabs,
  trackNoteEditingByMultiUsers,
  trackNotesEvent,
  useNoteFocusTracking,
} from '../../../libs/freshpaint/notesEvents'
import { notification } from '../../../libs/notificationLib'
import Sentry from '../../../libs/sentry'
import { parseDateOrNull } from '../../../libs/utils'
import {
  ActiveMedication,
  ClinicalNote,
  NoteTypes,
} from '../../../shared-types'
import {
  InfoPage,
  Skeleton,
  toggleConfirmModal,
} from '../../../stories/BaseComponents'
import { NotificationType } from '../../../stories/BaseComponents/Notification'
import { StandardSkeletonRows } from '../../../stories/BaseComponents/Skeleton'
import { PatientHeader } from '../PatientHeader'
import { patientDataTypes } from '../patient-data-types'
import { ClinicalNoteContext } from './ClinicalNoteContext'
import AutosaveLoaderPrompt from './ClinicalNotesComponents/GuardModals/AutosaveLoaderPrompt'
import { NoteHeaderV2 } from './ClinicalNotesComponents/NoteHeaderV2'
import {
  SpravatoGeneralAlert,
  SpravatoIsFaxedAlert,
  SpravatoViewModeAlert,
} from './ClinicalNotesComponents/NoteType/Spravato/SpravatoAlerts'
import DetailClinicalNoteForm from './DetailClinicalNoteForm'
import NoteIsBeingEditedAlert from './NoteIsBeingEditedAlert'
import { copyToNewNote } from './services'
import { clinicalNotesBaseUrl, getClinicalNotesUrl } from './utils'

import sharedStyles from '../../_shared.module.scss'
import './ClinicalNotes.scss'
import './CreateClinicalNotePage.scss'
import './ViewEditNotePage.scss'

/** Temporary helper used for logging info on Clinical Notes data loss issues */
const sentryLogger = ({
  origin,
  message,
  patientId,
  noteId,
  providerEmail,
}: {
  origin?: string
  message?: string
  patientId?: string
  noteId?: string
  providerEmail?: string
}) =>
  Sentry.captureMessage(
    `[${patientId}-${origin}] ${message}
    Note: ${noteId} | Provider: ${providerEmail}`
  )

/**
 * This is a repurposed version of deprecated ClinicalNoteTab.js de-modaled into an "Edit Note Page"
 * The tab component was left in the ClincalNoteTab.js
 */
export default function ViewEditNotePage({
  healthGorillaUserName,
}: {
  healthGorillaUserName: string
}) {
  const { autosaveDebounceNotev1Seconds } = useFeatureFlags()
  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const headerRef = useRef<HTMLDivElement>(null)
  const { urlParams } = useProfileUrlParams()
  const { noteId } = useParams<{ noteId?: string }>()
  const noteIdStateRef = React.useRef(noteId)
  const query = useQueryString()
  const reactQuery = useQueryClient()
  const patientId = query.get(QUERY_PARAMS.patientId) ?? ''
  const providerId = query.get(QUERY_PARAMS.providerId) ?? ''
  const saltNoteId = query.get(QUERY_PARAMS.saltNoteId) ?? ''
  const queryEditMode = query.get(QUERY_PARAMS.editMode) ?? ''
  const querySaltAlerts = query.get(QUERY_PARAMS.saltAlert) ?? ''
  const [inViewMode, setInViewMode] = useState(!queryEditMode)
  const inViewModeStateRef = React.useRef(inViewMode)
  const [noteRefetchLoading, setNoteRefetchLoading] = useState(false)
  const [isUpdatingCache, setUpdatingCache] = useState(false)
  const [isBeingEditedBy, setIsBeingEditedBy] = useState('')
  const [anotherProviderIsEditing, setAnotherProviderIsEditing] =
    useState(false)
  const [forcedEndEditComplete, setForcedEndEditComplete] = useState(false)
  const [showDisabledFeatureMessage, setShowDisabledFeatureMessage] =
    useState(false)
  const [isGeneratingREMS, setIsGeneratingREMS] = useState(false)
  const [activeMeds, setActiveMeds] = useState<ActiveMedication[]>([])
  const [clinicalNote, setClinicalNote] = useState<ClinicalNote | null>(null)

  const [isFormValid, setIsFormValid] = useState(true)
  const [isAllergiesBeingEdited, setIsAllergiesBeingEdited] = useState(false)
  const [showDuplicateEdit, setShowDuplicateEdit] = useState(false)
  const [creationMode, setCreationMode] = useState(false)
  const [signedAt, setSignedAt] = useState<Date | null>(null)
  const [changesMade, setChangesMade] = useState(0)
  const [saltFormattingFinalized, setSaltFormattingFinalized] = useState(false)

  const {
    setBackupFields,
    setIsNoteBackupChecked,
    setBackupFound,
    deleteCache,
    isNoteBackupChecked,
    backupFound,
  } = useSetClinicalNoteBackup(patientId, changesMade > 1)

  const notesListUrl = `${clinicalNotesBaseUrl}?${urlParams}`

  // Event listener doesnt have access to the latest state of hooks/ state values only has access to latest state of refs
  useEffect(() => {
    // Always update the state ref to access if needed
    noteIdStateRef.current = noteId
  }, [noteId])

  useEffect(() => {
    // Always update the state ref to access if needed
    inViewModeStateRef.current = inViewMode
  }, [inViewMode])

  useEffect(() => {
    // This allows us to use a query parameter to toggle edit mode for unique cases like autosave pre-creating a note and navigating to it in edit mode
    if (queryEditMode && clinicalNote && !clinicalNote.Signatures?.length) {
      const queryParams = new URLSearchParams(location.search)
      queryParams.delete(QUERY_PARAMS.editMode)
      history.replace({ search: queryParams.toString() })
      setInViewMode(false)
    }
  }, [queryEditMode, clinicalNote?.Signatures])

  //UI interactions states
  const [collapsedHeader, setCollapsedHeader] = useState(false)

  const { data: patientMedications } = usePatientMedications(patientId ?? '')

  useEffect(() => {
    if (clinicalNote?.Signatures?.length) {
      const signatureDates = clinicalNote?.Signatures.map((signature) => {
        // @ts-ignore
        return parseDateOrNull(signature?.CreatedAt, true)
      }).filter((a) => a)

      signatureDates.sort()

      setSignedAt(signatureDates[0])
    }
  }, [clinicalNote])

  const scrollToTop = () => {
    containerRef?.current?.scrollIntoView({
      block: 'center',
    })
  }

  useEffect(() => {
    scrollToTop()
  }, [])

  const {
    isLoading: patientDataIsLoading,
    isError: patientDataIsError,
    data: patient,
  } = usePatientDemographics(patientId ?? '')

  const {
    isLoading: providerSidePatientIsLoading,
    isError: providerSidePatientIsError,
    data: providerSidePatientData,
  } = useProviderSidePatientData(patientId ?? '')

  const {
    isFetching: clinicalNotesLoading,
    data: patientClinicalNotes,
    refetch: refetchNotesV1,
  } = usePatientClinicalNotes(patientId ?? '')

  const {
    isLoading: patientDiagnosesIsLoading,
    isError: patientDiagnosesIsError,
    data: patientDiagnosesData,
  } = usePatientDiagnoses(patientId ?? '')

  const {
    isLoading: patientAllergiesIsLoading,
    isError: patientAllergiesIsError,
    data: patientAllergiesData,
  } = usePatientAllergies(patientId ?? '')

  const {
    isLoading: providerDetailsIsLoading,
    isError: providerDetailsIsError,
    data: providerDetails,
  } = useProviderDetails()

  const queriesLoading =
    patientDataIsLoading ||
    providerSidePatientIsLoading ||
    patientDiagnosesIsLoading ||
    providerDetailsIsLoading ||
    patientAllergiesIsLoading ||
    clinicalNotesLoading
  const queriesError =
    patientDataIsError ||
    providerSidePatientIsError ||
    providerDetailsIsError ||
    patientDiagnosesIsError ||
    patientAllergiesIsError
  const noQueryData =
    !patient ||
    !providerSidePatientData ||
    !providerDetails ||
    (noteId !== AutosaveTriggerCreateNoteKeyword &&
      !creationMode &&
      !clinicalNote)

  useEffect(() => {
    if (!headerRef.current) return // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      if (!headerRef.current) return
      if (headerRef.current?.clientWidth < 620 && !collapsedHeader) {
        setCollapsedHeader(true)
      } else {
        setCollapsedHeader(false)
      }
    })
    resizeObserver.observe(headerRef.current)
    return () => resizeObserver.disconnect() // clean up
  }, [headerRef.current])

  useEffect(() => {
    // Find and set salt note when patientClinicalNotes is available
    if (!patientClinicalNotes || !saltNoteId) {
      return
    }
    const saltNoteFound = patientClinicalNotes.find(
      ({ NoteId }) => saltNoteId === NoteId
    )

    if (saltNoteFound) {
      setSaltFormattingFinalized(true)
    }
  }, [saltNoteId, patientClinicalNotes])

  useEffect(() => {
    // when navigating away, auto-unset editing state
    const maybeUnsetEditingBy = () => {
      const shouldUnsetEditing = !inViewModeStateRef.current
      if (shouldUnsetEditing && noteId !== AutosaveTriggerCreateNoteKeyword) {
        markNoteAsNotBeingEdited({
          PatientId: patientId,
          NoteId: noteId,
        })
      }
    }

    // @ts-expect-error navigation is not available on all browsers https://caniuse.com/mdn-api_window_navigation
    const windowNavigationApi = window.navigation
    if (windowNavigationApi) {
      // if available, listen to the "navigate" event
      windowNavigationApi.addEventListener('navigate', maybeUnsetEditingBy)
      return () =>
        windowNavigationApi.removeEventListener('navigate', maybeUnsetEditingBy)
    } else {
      // otherwise, listen to history route changes. this misses back button presses or invocations, but will handle in-app routes
      const unlistenHistory = history.listen(maybeUnsetEditingBy)
      return unlistenHistory
    }
  }, [])

  const goToNotesList = () => {
    history.push(notesListUrl)
  }

  const updateCache = async (scrollTop = false, showLoader = true) => {
    showLoader && setUpdatingCache(true)
    await reactQuery.invalidateQueries([
      patientDataTypes.ProviderSidePatientData,
      patientId,
    ])
    await reactQuery.invalidateQueries([patientDataTypes.PatientClinicalNotes])

    await reactQuery.invalidateQueries([
      patientDataTypes.PatientMedications,
      patientId,
    ])
    scrollTop && scrollToTop()

    reactQuery.invalidateQueries([patientDataTypes.PatientDiagnoses, patientId])

    reactQuery.invalidateQueries([patientDataTypes.PatientAllergies, patientId])

    setUpdatingCache(false)
  }

  const updateCacheWithNav = async () => {
    updateCache()
    goToNotesList()
  }

  const handleClose = () => {
    setInViewMode(true)
    setIsAllergiesBeingEdited(false)
  }

  const showNoteDeletedWarning = (origin: string) => {
    sentryLogger({
      origin,
      message: 'Provider attempted to view a deleted note',
      patientId: patientId,
      noteId: noteId,
      providerEmail: providerDetails?.providerEmail,
    })
    // When the note is deleted
    toggleConfirmModal({
      title: 'This note no longer exists',
      content: 'The note you are trying to view was recently deleted.',
      cancelButtonProps: { style: { display: 'none' } },
    })
    handleClose()
  }

  const clinicalNoteData = useClinicalNoteFields({
    note: clinicalNote,
    updateType: UpdateType.EDIT,
    patient: patient ?? {},
    patientId,

    previousNotes: patientClinicalNotes ?? [],
    clinicData: providerDetails?.clinicData ?? {},
    patientPhysicalNotes: providerSidePatientData?.PhysicalNotes ?? {},
    inViewMode,
    setIsGeneratingREMS,
    setInViewMode,
    saltFormattingFinalized,
    setClinicalNote,
  })

  const {
    // state
    fields,
    isValid,
    isSaving,
    showSignedAndValid,
    showSignedAndInvalid,
    showUnsignedAndInvalid,
    isSavingAutosave,
    hasAnyPendingChanges,
    isNoteCreated,
    autosaveFailed,
    auditLogs: autoSaveAuditTrail,
    saveQueue,
    // State setters
    setFields,
    resetFields,
    setShowUnsignedAndInvalid,
    setShowSignedAndValid,
    setShowSignedAndInvalid,
    // async fetch
    refetchOnEdit,
    handleForcedSave,
  } = clinicalNoteData

  useNoteFocusTracking({
    noteId: noteId ?? '',
    noteType: fields.NoteType,
    patientId: patientId,
    autosaveTiming: autosaveDebounceNotev1Seconds,
  })

  const showDuplicateEditWarning = () => {
    trackNoteEditingAcrossTabs({
      noteId: noteId ?? '',
      noteType: fields.NoteType,
      patientId: patientId,
      autosaveTiming: autosaveDebounceNotev1Seconds,
    })
    setInViewMode(true)
    toggleConfirmModal({
      title: 'You are already editing this note',
      content:
        'You are editing this note in another window or tab. Are you sure you want to proceed with a new edit?',
      okText: 'Yes',
      onOk: async () => {
        // should already be marked, but better to also do it closer to the actual button press
        await markNoteAsBeingEdited({
          PatientId: patientId,
          NoteId: noteId,
          ProviderEmail: providerDetails?.providerEmail,
        })
        setInViewMode(false)
      },
    })
  }

  const auditTrail = useMemo<AutoSaveAuditLogs | undefined>(() => {
    return autoSaveAuditTrail
  }, [autoSaveAuditTrail])

  const [hasSuperbill, hasInvoice, hasClaim] = useMemo(() => {
    return [
      Boolean(fields.invoice?.superbill?.id),
      Boolean(fields.invoice?.uuid && !fields.invoice?.deletedAt),
      Boolean(fields.InsuranceClaim?.length > 0),
    ]
  }, [fields.invoice, fields.InsuranceClaim])

  const hideDisabledFeatureLabel = () => {
    setShowDisabledFeatureMessage(false)
  }

  const closeValidSignedSpravatoModal = () => {
    setShowSignedAndValid(false)
  }

  const closeInvalidSignedSpravatoModal = () => {
    setShowSignedAndInvalid(false)
  }

  const closeInvalidUnsignedSpravatoModal = () => {
    setShowUnsignedAndInvalid(false)
  }

  useEffect(() => {
    resetFields()
  }, [clinicalNote])

  useEffect(() => {
    // poll the database every 5 seconds to check for if someone else is editing the note to force the other user out of editing
    if (!patientId || !noteId || !providerDetails?.providerEmail) {
      return
    }
    const checkIfUserIsEditing = async () => {
      try {
        const newNoteData = await getNoteData({
          patientId: patientId,
          noteId: noteId,
        })

        if (!newNoteData) {
          return showNoteDeletedWarning('CHECKIFEDIT')
        }

        // when this value is marked as true, it triggers a use effect in NoteIsBeingEditedAlert to register that the process of forcing the provider out of editing is complete
        setIsBeingEditedBy(newNoteData.IsBeingEditedBy)
        // if in edit mode and the provider currently editing the note switched, force this provider out of editing and save their changes
        setForcedEndEditComplete(newNoteData.ForcedEndEditIsComplete)
        if (
          !inViewMode &&
          !newNoteData.ForcedEndEditIsComplete &&
          newNoteData.IsBeingEditedBy &&
          newNoteData.IsBeingEditedBy !== providerDetails?.providerEmail
        ) {
          await handleForcedSave()
          setInViewMode(true)
          updateCache()
          notification(
            `${newNoteData.IsBeingEditedBy} has forced you out of editing to make edits to this clinical note themselves. Your changes have been auto saved.`,
            NotificationType.INFO,
            { duration: 5 }
          )
        }
      } catch (err) {
        console.error(err)
      }
    }
    const interval = setInterval(checkIfUserIsEditing, 5000)
    return () => clearInterval(interval)
  }, [fields, noteId, patientId, inViewMode, providerDetails?.providerEmail])

  useEffect(() => {
    // Filter any retired meds fetched from the backend (should do this from api)
    if (!patientMedications?.activeMeds) {
      return
    }

    const activeMeds = patientMedications.activeMeds.filter(
      (medication) => !medication.IsRetired
    )
    setActiveMeds(activeMeds)
  }, [patientMedications?.activeMeds])

  useEffect(() => {
    if (!noteId || !patientClinicalNotes) {
      return
    }
    const noteToView = patientClinicalNotes.find(
      ({ NoteId }) => NoteId === noteId
    )
    setClinicalNote(noteToView ?? null)
  }, [noteId, patientClinicalNotes])

  const handleCloseEdit = useCallback(async () => {
    if (!inViewModeStateRef.current) {
      await markNoteAsNotBeingEdited({
        PatientId: patientId,
        NoteId: noteIdStateRef.current,
      })
    }

    setInViewMode(true)
    setChangesMade(0)
    deleteCache()
    resetFields()
    updateCache()
    setCreationMode(false)
  }, [
    noteIdStateRef.current,
    patientId,
    inViewModeStateRef.current,
    updateCache,
    resetFields,
    deleteCache,
  ])

  // User has switched away from the tab (AKA tab is hidden)
  const onBlurTab = () => {
    // If autosave enabled, editing a note and the note is created
    if (document.visibilityState === 'hidden') {
      if (
        noteIdStateRef.current &&
        noteIdStateRef.current !== AutosaveTriggerCreateNoteKeyword &&
        !inViewModeStateRef.current
      ) {
        handleCloseEdit()
      }
    }
  }

  useEffect(() => {
    // Adding event when user switches tab
    // window.addEventListener('visibilitychange', onBlurTab)
    document.addEventListener('visibilitychange', onBlurTab)
    return () => {
      document.removeEventListener('visibilitychange', onBlurTab)
    }
  }, [])

  const clickEditButton = async () => {
    try {
      trackNotesEvent(NotesEvents.EDITED_NOTE, {
        patientId,
        noteId: clinicalNote?.NoteId,
        noteType: clinicalNote?.NoteType,
      })
      setNoteRefetchLoading(true)

      if (!noteId || !patientId || !providerDetails?.providerEmail) {
        // If ever bad/unloaded state
        sentryLogger({
          noteId,
          patientId,
          origin: 'GUARDHANDLEEDIT',
          message:
            'Missing note ID, patient ID, or provider info when trying to edit note',
          providerEmail: providerDetails?.providerEmail,
        })
        return
      }

      const currentNoteData = await refetchOnEdit(patientId, noteId)

      if (!currentNoteData && clinicalNote?.NoteId) {
        // Note not found on the database
        showNoteDeletedWarning('HANDLEEDIT')
        return
      }

      const currentlyEditedBy = currentNoteData?.IsBeingEditedBy ?? ''
      setIsBeingEditedBy(currentlyEditedBy)
      setForcedEndEditComplete(
        Boolean(currentNoteData?.ForcedEndEditIsComplete)
      )
      setClinicalNote(currentNoteData ?? null)

      if (!currentlyEditedBy) {
        // If no one updating currently, mark it as being edited by this user
        await markNoteAsBeingEdited({
          PatientId: patientId,
          NoteId: noteId,
          ProviderEmail: providerDetails?.providerEmail,
        })
        setInViewMode(false)
        return
      }

      if (currentlyEditedBy === providerDetails?.providerEmail) {
        // Same user editing in multiple tabs/windows
        setInViewMode(false)
        setShowDuplicateEdit(true)
        return
      }

      if (currentlyEditedBy !== providerDetails?.providerEmail) {
        // Different user editing, show this user modal to allow forcing off other user
        trackNoteEditingByMultiUsers({
          noteId: noteId ?? '',
          patientId,
          noteType: fields.NoteType,
          autosaveTiming: autosaveDebounceNotev1Seconds,
          user1: currentlyEditedBy,
          user2: providerDetails?.providerEmail ?? '',
        })
        setAnotherProviderIsEditing(true)
        return
      }
    } catch (err) {
      console.error('Failed to open edit form for note', err)
    } finally {
      setNoteRefetchLoading(false)
    }
  }

  useEffect(() => {
    if (!backupFound && isNoteBackupChecked && showDuplicateEdit) {
      showDuplicateEditWarning()
      setShowDuplicateEdit(false)
    }
  }, [isNoteBackupChecked, showDuplicateEdit, backupFound])

  const handleCopyToNewNote = async () => {
    const newNote = await copyToNewNote({
      noteId,
      providerId,
      refetchNotesList: refetchNotesV1,
    })
    if (newNote) {
      history.push(
        getClinicalNotesUrl({
          noteId: newNote.NoteId,
          urlParams: `${urlParams}&${QUERY_PARAMS.editMode}=true&${QUERY_PARAMS.saltAlert}=true`,
        })
      )
    }
  }

  const handleSaveNote = async (showLoader = true) => {
    try {
      if (autosaveFailed && saveQueue) {
        try {
          const { noteId, shouldAuditLog, ...paylod } = saveQueue
          await autoSaveClinicalNotesV1(paylod, noteId, shouldAuditLog)
        } catch (e) {
          onError(e, 500)
        }
      }
      deleteCache()
      setIsNoteBackupChecked(false)
      setChangesMade(0)
      setInViewMode(true)
      updateCache(false, showLoader)
    } catch (err) {
      console.error(`Saving note failed with error: ${err}`)
    }
  }

  useEffect(() => {
    if (fields && !inViewMode) {
      setBackupFields(fields)
      setChangesMade((prevValue) => prevValue + 1)
    }
  }, [fields, inViewMode])

  useEffect(() => {
    if (noteId === AutosaveTriggerCreateNoteKeyword) {
      setCreationMode(true)
      setInViewMode(false)
    }
  }, [noteId])

  const isSaveButtonDisabled =
    isSaving || fields.NoteType === '' || !fields.NoteDate

  if (queriesError || !patientId || !providerId) {
    return (
      <InfoPage
        status="warning"
        title="Sorry there was a problem loading this page"
        details="Oops something went wrong. Please contact your Osmind representative if this issue persists."
        redirectButtonText="Return to Patient List"
        redirectLink="/"
      />
    )
  }

  const showSkeleton = queriesLoading || noQueryData || isUpdatingCache

  const showSpravatoAlert =
    !inViewMode &&
    fields.NoteType === NoteTypes.SPRAVATO &&
    fields.Signatures.length === 0

  const showSpravatoViewModeAlert =
    inViewMode &&
    clinicalNote?.isValidSpravato === false &&
    clinicalNote.NoteType === NoteTypes.SPRAVATO &&
    clinicalNote.Signatures?.length > 0

  const showSpravatoIsFaxedAlert =
    inViewMode &&
    clinicalNote?.isValidSpravato === true &&
    clinicalNote.NoteType === NoteTypes.SPRAVATO &&
    clinicalNote.Signatures?.length > 0

  return (
    <>
      <div className={sharedStyles.scroll}>
        <div ref={containerRef} style={{ top: 0 }}></div>
        <div style={{ position: 'sticky', top: 0, zIndex: 4 }}>
          <PatientHeader
            providerId={providerId}
            patientId={patientId}
            healthGorillaUserName={healthGorillaUserName}
          />
          <NoteHeaderV2
            headerRef={headerRef}
            collapsedHeader={collapsedHeader}
            clinicalNote={clinicalNote}
            inViewMode={creationMode ? true : inViewMode}
            areActionButtonsDisabled={!inViewMode}
            isSaveButtonDisabled={isSaveButtonDisabled}
            inCreationMode={creationMode}
            isSaving={isSaving}
            noteRefetchLoading={noteRefetchLoading}
            noteId={noteId}
            hasClaim={hasClaim}
            hasSuperbill={hasSuperbill}
            hasInvoice={hasInvoice}
            fields={fields}
            patientId={patientId}
            providerId={providerId}
            isAllergiesBeingEdited={isAllergiesBeingEdited}
            isFormValid={isFormValid}
            hasAutosavePendingChanges={hasAnyPendingChanges}
            handleSaveNote={() => handleSaveNote(false)}
            clickEditButton={clickEditButton}
            handleClose={handleClose}
            handleCloseEdit={handleCloseEdit}
            updateCacheWithNav={updateCacheWithNav}
            handleCopyToNewNote={handleCopyToNewNote}
            setFields={setFields}
            setIsNoteBackupChecked={setIsNoteBackupChecked}
            setBackupFound={setBackupFound}
            goToNotesList={goToNotesList}
            isNoteBackupChecked={isNoteBackupChecked}
            isNoteAutoSaving={isSavingAutosave}
            isNoteBeingCreated={false}
            isNoteCreated={isNoteCreated}
            noteAutosaveFailed={autosaveFailed}
            showSkeleton={showSkeleton}
          />
          <SpravatoIsFaxedAlert isVisible={Boolean(showSpravatoIsFaxedAlert)} />
          <SpravatoViewModeAlert
            isVisible={Boolean(showSpravatoViewModeAlert)}
          />
          <SpravatoGeneralAlert isVisible={Boolean(showSpravatoAlert)} />
        </div>
        <div className="note-page">
          <div className={'note-page-container note-page-container-no-padding'}>
            <AutosaveLoaderPrompt
              shouldWaitForAutosave={isSavingAutosave || hasAnyPendingChanges}
            />
            {showSkeleton ? (
              <Skeleton
                style={{ padding: '36px' }}
                paragraph={{ rows: StandardSkeletonRows.fullPage }}
              />
            ) : (
              <>
                <ClinicalNoteContext.Provider value={clinicalNoteData}>
                  <DetailClinicalNoteForm
                    setIsFormValid={setIsFormValid}
                    fields={fields}
                    handleFieldChange={setFields}
                    isSaving={isSaving}
                    salt={Boolean(saltNoteId) || Boolean(querySaltAlerts)}
                    handleSave={handleSaveNote}
                    handleClose={handleClose}
                    Diagnosis={patientDiagnosesData ?? []}
                    activeMeds={activeMeds}
                    handleEdit={inViewMode}
                    note={clinicalNote}
                    noteId={noteId} // use noteId from url params
                    patient={patient}
                    clinicInfo={providerDetails ?? {}}
                    Allergies={patientAllergiesData}
                    previousNotes={patientClinicalNotes ?? []}
                    updateCache={updateCache}
                    updateCacheWithNav={updateCacheWithNav}
                    isValid={isValid}
                    signedAt={signedAt}
                    showSignedAndValid={showSignedAndValid}
                    setShowSignedAndValid={setShowSignedAndValid}
                    showSignedAndInvalid={showSignedAndInvalid}
                    setShowSignedAndInvalid={setShowSignedAndInvalid}
                    isGeneratingREMS={isGeneratingREMS}
                    setIsAllergiesBeingEdited={setIsAllergiesBeingEdited}
                    saltFormattingFinalized={saltFormattingFinalized}
                    setSaltFormattingFinalized={setSaltFormattingFinalized}
                    auditTrail={auditTrail}
                    isAddSignatureDisabled={
                      !isNoteCreated || hasAnyPendingChanges
                    }
                  />
                </ClinicalNoteContext.Provider>
                <Modal
                  size="xl"
                  show={showDisabledFeatureMessage}
                  onHide={hideDisabledFeatureLabel}
                  fluid="true"
                  centered
                >
                  <Modal.Header closeButton />
                  <Modal.Body>
                    <DisabledFeatureMessage
                      feature="superbill generation"
                      onModalClose={hideDisabledFeatureLabel}
                    />
                  </Modal.Body>
                </Modal>
                <NoteIsBeingEditedAlert
                  note={clinicalNote}
                  isBeingEditedBy={isBeingEditedBy}
                  anotherProviderIsEditing={anotherProviderIsEditing}
                  setAnotherProviderIsEditing={setAnotherProviderIsEditing}
                  providerEmail={providerDetails?.providerEmail}
                  setInViewMode={setInViewMode}
                  forcedEndEditComplete={forcedEndEditComplete}
                  handleApiChange={updateCache}
                  refetchNote={refetchOnEdit}
                />
                <ConfirmationModal
                  title="Note Saved"
                  okText="Confirm"
                  body={
                    <>
                      <span>
                        The Spravato® REMS Patient Monitoring form has NOT been
                        submitted yet. Once you sign the note, the form will
                        automatically be generated and submitted on your behalf.
                        OR, you can manually submit the required patient
                        monitoring form here:{' '}
                        <a
                          className="spravato-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://spravatoremsenroll.com/"
                        >
                          https://spravatoremsenroll.com/
                        </a>
                      </span>
                    </>
                  }
                  size="lg"
                  show={showUnsignedAndInvalid}
                  onConfirm={closeInvalidUnsignedSpravatoModal}
                  showIcon={false}
                  singleButton
                />
                <ConfirmationModal
                  title="Submit your Spravato® REMS form"
                  okText="Confirm"
                  body={
                    <>
                      <p>
                        The Spravato® REMS Patient Monitoring form has NOT been
                        submitted yet. You can manually submit the required
                        patient monitoring form here:&nbsp;
                        <a
                          className="spravato-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://spravatoremsenroll.com/"
                        >
                          https://spravatoremsenroll.com/
                        </a>
                      </p>
                    </>
                  }
                  size="lg"
                  show={showSignedAndInvalid}
                  onConfirm={closeInvalidSignedSpravatoModal}
                  showIcon={false}
                  singleButton
                />
                <ConfirmationModal
                  title="Spravato® REMS form submission"
                  okText="View Form"
                  cancelText="Close"
                  body={
                    <>
                      <p>
                        The Spravato® REMS Patient Monitoring form has been
                        automatically generated and will be submitted on your
                        behalf.
                      </p>
                    </>
                  }
                  size="lg"
                  show={showSignedAndValid}
                  onCancel={closeValidSignedSpravatoModal}
                  onConfirm={() =>
                    window
                      .open(
                        `/patient/documents?patientId=${patientId}&providerId=${providerId}`,
                        '_blank'
                      )
                      ?.focus()
                  }
                  showIcon={false}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
