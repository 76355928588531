import React, { useEffect } from 'react'

import { useFormikContext } from 'formik'

import { Alert, Card } from '../../../../stories/BaseComponents'
import InlineField from '../InlineField'
import SectionToggle from '../SectionToggle'
import { sectionContainerBodyStyle, sectionContainerStyle } from '../constants'
import { PAYER_ADDRESS } from '../field-constants'
import { ClaimForm, IsReadOnlyModeProp } from '../types'
import { getAddress2FromAddress } from '../utils'

import styles from './shared.module.scss'

const { TOGGLE, PAYER } = PAYER_ADDRESS

export const testIds = {
  container: 'claim-payer-address',
  alert: 'claim-payer-address-alert',
}

const PayerAddress: React.FC<IsReadOnlyModeProp> = ({ readOnly }) => {
  // current form values and touched status
  const { values, touched, setFieldValue } = useFormikContext<ClaimForm>()
  const {
    payerAddress: { isIncluded, address },
  } = values
  const addressTouched = !!touched.payerAddress?.address

  // sync address's address2 with the address2
  useEffect(() => {
    if (!addressTouched) {
      return
    }
    const newAddress2 = getAddress2FromAddress(address)
    setFieldValue('payerAddress.address2', newAddress2)
  }, [address, addressTouched])

  return (
    <Card
      testId={testIds.container}
      style={sectionContainerStyle}
      bodyStyle={sectionContainerBodyStyle}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span data-testid="PayerAddress-Title" className={styles.title}>
            {'Payer address'}
          </span>
        </div>
        <Alert
          testId={testIds.alert}
          description={`The Payer address field is required for specific insurance providers, such as Magellan Health. Please ensure this information is complete and accurate to avoid claim processing delays.`}
          message=""
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />
        <div>
          <SectionToggle {...TOGGLE} disabled={readOnly} />
          {isIncluded &&
            PAYER.map((item) => (
              <InlineField {...item} key={item.name} disabled={readOnly} />
            ))}
        </div>
      </div>
    </Card>
  )
}

export default PayerAddress
