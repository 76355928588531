import { useMemo } from 'react'

import {
  MutationStatus,
  QueryStatus,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { logErrorAndNotify } from 'libs/notificationLib'
import { keyBy } from 'lodash'
import { useHistory } from 'react-router-dom'
import { generateNotification } from 'stories/BaseComponents'
import { NotificationType } from 'stories/BaseComponents/Notification'

import { getLocation, getPOSCodes } from '../../../api/api-lib'
import {
  adjudicateClaimManually,
  createClaimFromPatientInfo,
  deleteClaim,
  getClaimById,
  getProviders,
  submitClaim,
  submitClaimManually,
  updateClaim,
  updateClaimMemo,
} from '../../../api/insuranceClaimsV2'
import { fetchDiagnoses } from '../../../api/patients'
import { QueryKeys as InsuranceClaimQueryKeys } from '../../../hooks/useInsuranceClaims'
import { useChangePayers } from '../../../hooks/usePatientProfile'
import { getInsurancePayerOptions } from '../../../libs/utils'
import { Diagnosis, PlaceOfServiceCode } from '../../../shared-types'
import { listNoteDetails } from '../../../v2/notes/api'
import { useClaimsErrorLogForExternalMonitor } from './ErrorHandling/ExternalMonitoring'
import {
  ClaimData,
  ClaimLocation,
  ClaimProvider,
  ClaimStatus,
  ClaimStatusForManualStatusUpdateModal,
} from './types'

export const QUERY_KEYS = {
  CLAIM: 'claim',
  PROVIDERS: 'providers',
  LOCATIONS: 'locations',
  POS: 'posCodes',
  NOTES: 'notes',
  ACTIVE_DIAGNOSES: 'activeDiagnoses',
} as const

const STALE_TIME = { short: 1_000, medium: 30_000 }

type UseGetClaimDataInput = {
  claimId: string
}

type UseGetClaimDataResult = {
  data?: ClaimData
  error: unknown
  status: QueryStatus | MutationStatus
  refetch?: () => Promise<UseQueryResult<ClaimData>>
}

export const DISPLAY_STRINGS = {
  manuallySubmit: {
    successNotification: 'The claim status has been manually updated',
    failureMessage: 'There was an error manually adjudicating this claim',
  },
  manuallyAdjudicate: {
    successNotification: 'The claim has been manually adjudicated.',
    failureMessage: 'Failed to manually adjudicate claim',
  },
}

export const useCreateClaimV2 = (
  onSuccess: (data: ClaimData) => Promise<void>,
  /**
   * Keeping this simplified based on needs, but it can easily be expanded, per below
   * onError: (e: unknown, variables: {patientId: string, noteId?: string}) => Promise<void>
   */
  onError: () => void
) => {
  const queryClient = useQueryClient()
  const { logClaimsErrorToExternalMonitor } =
    useClaimsErrorLogForExternalMonitor()
  return useMutation({
    mutationFn: createClaimFromPatientInfo,
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: [InsuranceClaimQueryKeys.INSURANCE_CLAIMS, data.patient?.id],
      })
      await onSuccess(data)
    },
    /**
     * onError will be triggered if there is a server error OR if the `onSuccess` callback throws an error
     */
    onError: async (e, { patientId, noteId }) => {
      generateNotification('Error creating a Claim.')
      logClaimsErrorToExternalMonitor({
        patientId,
        providerId: 'not applicable in this case',
        claimId: 'not applicable in this case',
        message: `Error when creating a claim from patient info. noteId (optional): "${noteId}"`,
        error: e,
      })
      onError()
    },
  })
}

export const useGetClaimData = ({
  claimId,
}: UseGetClaimDataInput): UseGetClaimDataResult => {
  const { logClaimsErrorToExternalMonitor } =
    useClaimsErrorLogForExternalMonitor()
  const { data, error, status, refetch } = useQuery(
    [QUERY_KEYS.CLAIM, claimId],
    () => getClaimById(claimId),
    {
      enabled: !!claimId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onError: (e) => {
        generateNotification(
          'Failed to retrieve claim.',
          NotificationType.ERROR
        )
        logClaimsErrorToExternalMonitor({
          patientId: 'not applicable in this case',
          providerId: 'not applicable in this case',
          claimId: 'not applicable in this case',
          message: `Error when getting a claim from patient info.`,
          error: e,
        })
      },
    }
  )

  return {
    data: data as ClaimData,
    error,
    status,
    refetch,
  }
}

export const useDeleteClaim = ({ patientId }: { patientId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteClaim,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [InsuranceClaimQueryKeys.INSURANCE_CLAIMS, patientId],
      })
    },
  })
}

export const useSaveClaim = ({ claimId }: { claimId?: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateClaim,
    onSuccess: () => {
      // invalidate getClaimById query after claim update.
      // Otherwise useGetClaimData will serve stale data when the same claim loads for the second time, which is not a problem if we set enableReinitialize prop to true for <Formik/>
      // However, that causes extra re-initialization and flash of old claim data.
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
    },
  })
}

export const useSaveClaimMemo = ({ claimId }: { claimId?: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateClaimMemo,
    onSuccess: () => {
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
    },
  })
}

export const useSubmitClaim = ({ claimId }: { claimId?: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: submitClaim,
    onSuccess: () => {
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
    },
  })
}

export const usePayers = () => {
  const { data, status } = useChangePayers()

  const payersData = useMemo(() => {
    if (!data?.length) {
      return { payerOptions: [], payersByName: {} }
    }
    return {
      payerOptions: getInsurancePayerOptions(data),
      payersByName: keyBy(data, 'organizationName'),
    }
  }, [data])

  return {
    status,
    data: payersData,
  }
}

export const useProviders = () => {
  const { status, data }: { status: QueryStatus; data?: ClaimProvider[] } =
    useQuery([QUERY_KEYS.PROVIDERS], getProviders, {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME.medium,
    })

  const providersData = useMemo(() => {
    if (!data?.length) {
      return { providerOptions: [], providersById: {} }
    }
    return {
      providerOptions: data.map(({ name, email, cognitoId }) => ({
        label: name || email,
        value: cognitoId,
      })),
      providersById: keyBy(data, 'cognitoId'),
    }
  }, [data])

  return {
    status,
    data: providersData,
  }
}

export type UseProvidersResult = ReturnType<typeof useProviders>

export const useLocations = () => {
  const { status, data }: { status: QueryStatus; data?: ClaimLocation[] } =
    useQuery([QUERY_KEYS.LOCATIONS], getLocation, {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME.medium,
    })

  const locationsData = useMemo(() => {
    if (!data?.length) {
      return { locationOptions: [], locationsByLocationId: {} }
    }
    return {
      locationOptions: data.map(({ LocationName, LocationId }) => ({
        label: LocationName,
        value: LocationId,
      })),
      locationsByLocationId: keyBy(data, 'LocationId'),
    }
  }, [data])

  return {
    status,
    data: locationsData,
  }
}

export const DEFAULT_NOTE_TITLE = 'No Title'
export const useNotes = (patientId: string) => {
  const { status, data } = useQuery(
    [QUERY_KEYS.NOTES, patientId],
    () => listNoteDetails({ patientId }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME.short,
    }
  )

  const notesData = useMemo(() => {
    if (!data?.data.length) {
      return { noteOptions: [], notesById: {} }
    }

    const notes = data.data.map((note) => ({
      ...note,
      title: note.title || DEFAULT_NOTE_TITLE,
    }))

    return {
      noteOptions: notes.map(({ title, noteId }) => ({
        label: title,
        value: noteId,
      })),
      notesById: keyBy(notes, 'noteId'),
    }
  }, [data])

  return {
    status,
    data: notesData,
  }
}

export const usePosCodes = () => {
  const { status, data }: { status: QueryStatus; data?: PlaceOfServiceCode[] } =
    useQuery([QUERY_KEYS.POS], getPOSCodes, {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME.medium,
    })

  const posData = useMemo(() => {
    if (!data?.length) {
      return { posOptions: [] }
    }
    return {
      posOptions: data.map(({ name, code }) => ({
        label: name || code,
        value: code,
      })),
    }
  }, [data])

  return {
    status,
    data: posData,
  }
}
/**
 @return Active diagnoses sorted by diagnosis data in descending order
*/
export const useActiveDiagnoses = (patientId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ACTIVE_DIAGNOSES, patientId],
    queryFn: async () => {
      const diagnoses: Diagnosis[] = await fetchDiagnoses(patientId)
      if (!diagnoses.length) {
        return []
      }
      return diagnoses
        .filter((diagnosis) => diagnosis.DiagnosisStatus === 'Active')
        .sort(
          (a, b) =>
            new Date(b.DiagnosisDate ?? 0).valueOf() -
            new Date(a.DiagnosisDate ?? 0).valueOf()
        )
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME.short,
  })
}

export const useAdjudicateClaimManuallyV2 = ({
  claimId,
}: {
  claimId: string
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => adjudicateClaimManually({ claimId }),
    onSuccess: () => {
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
      generateNotification(
        DISPLAY_STRINGS.manuallyAdjudicate.successNotification,
        NotificationType.SUCCESS
      )
    },
    onError: (e: AxiosError) => {
      logErrorAndNotify(DISPLAY_STRINGS.manuallyAdjudicate.failureMessage, e)
    },
  })
}
export const useSubmitClaimManuallyV2 = ({
  claimId,
  onManualSubmitErrorCallback,
}: {
  claimId: string
  onManualSubmitErrorCallback: (e: AxiosError) => void
}) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ claimData }: { claimData: ClaimData }) =>
      submitClaimManually({ claimId, claimData }),
    onSuccess: (_, { claimData }) => {
      if (claimId) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CLAIM, claimId],
        })
      }
      generateNotification(
        DISPLAY_STRINGS.manuallySubmit.successNotification,
        NotificationType.SUCCESS
      )
      history.push(
        `/patient/billing?patientId=${claimData.patient?.id}&providerId=${claimData.renderingProvider?.providerId}&tab=claims`
      )
    },
    onError: (e: AxiosError) => {
      logErrorAndNotify(
        DISPLAY_STRINGS.manuallySubmit.failureMessage,
        e as Error
      )
      onManualSubmitErrorCallback(e)
    },
  })
}

export type UpdateStatusChangeManually = (
  toStatus: ClaimStatusForManualStatusUpdateModal
) => Promise<void>

/**
 * Once ClaimsV1 is dropped, this method should be refactored (removed?) and the ManualStatusUpdateModal should just use these internal methods directly
 */
export const useUpdateStatusChangeManually = ({
  claimId,
  claimData,
  onManualSubmitErrorCallback,
}: {
  claimId: string
  claimData?: ClaimData
  onManualSubmitErrorCallback: (e: AxiosError) => void
}) => {
  const {
    mutateAsync: doAdjudicateClaimManually,
    isLoading: isAdjudicateClaimManuallyLoading,
  } = useAdjudicateClaimManuallyV2({ claimId })
  const {
    mutateAsync: doSubmitClaimManually,
    isLoading: isSubmitClaimManuallyLoading,
  } = useSubmitClaimManuallyV2({ claimId, onManualSubmitErrorCallback })

  const updateStatusChangeManually: UpdateStatusChangeManually = async (
    toStatus
  ) => {
    switch (toStatus) {
      case ClaimStatus.MANUALLY_ADJUDICATED:
        await doAdjudicateClaimManually()
          // ignore thrown errors, already handled in mutation
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {})
        break
      case ClaimStatus.MANUALLY_SUBMITTED:
        if (!claimData) throw Error('claimData not provided')
        await doSubmitClaimManually({ claimData })
          // ignore thrown errors, already handled in mutation
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {})
        break
    }
  }

  return {
    updateStatusChangeManually,
    isLoading: isAdjudicateClaimManuallyLoading || isSubmitClaimManuallyLoading,
  }
}
