import { Alert } from 'stories/BaseComponents'

import {
  ChcError,
  ClaimErrorOptions,
  ErrorList,
  ErrorResponse,
  JoiError,
} from '../types'

const isChcErrors = (errors: ErrorList): errors is ChcError[] => {
  return 'description' in errors[0]
}

const isJoiErrors = (errors: ErrorList): errors is JoiError[] => {
  return 'type' in errors[0]
}

type ClaimErrorProps = {
  options?: ClaimErrorOptions
  error?: ErrorResponse
}

const getDescriptionFromErrors = (
  errors: ErrorResponse['errors']
): string[] => {
  if (!errors) return []

  if (isChcErrors(errors)) {
    return errors.map((e) => e.description)
  }
  if (isJoiErrors(errors)) {
    return errors.map((e) =>
      e.context
        ? `${e.context.label}: ${e.context.message ?? e.message}`
        : e.message
    )
  }
  return errors.map((e) => e.message)
}
export const testIds = {
  container: 'claim-error',
}
/**
 * Honor the title and description in the "options" prop if they have value.
 * Otherwise parse the error data based on error type and compose message.
 */
const ClaimError: React.FC<ClaimErrorProps> = ({ options, error }) => {
  const getDescription = () => {
    if (options?.description) {
      return options?.description
    }
    if (error?.errors?.length) {
      return (
        <span data-testid={'claim-error--list'}>
          {getDescriptionFromErrors(error?.errors).map((message, i) => (
            <div key={i}>
              <span>{`• ${message}`}</span>
            </div>
          ))}
        </span>
      )
    }
    return error?.detail
  }

  return (
    <Alert
      testId={testIds.container}
      message={options?.title ?? error?.title ?? error?.error}
      description={getDescription()}
      type={options?.type ?? 'error'}
      showIcon
      parentStyle={{ marginBottom: 24 }}
    />
  )
}

export default ClaimError
