import React, { useMemo, useState } from 'react'

import { NoteTypes, TestId } from '../../shared-types'
import { isBeforeOrOn } from '../../shared/Helpers/utils'
import { Badge, Box, Tooltip } from '../BaseComponents'
import CSVExportButton from '../BaseComponents/CSVExportButton'
import FilterTags from '../BaseComponents/FilterTags'
import Table, { useToolsWithTableHook } from '../BaseComponents/Table'
import { Tabs } from '../BaseComponents/Tabs'
import {
  CSVTableDataTransformer,
  CsvHeaders,
  NotesReportData,
} from '../BaseComponents/tableData.types'
import {
  NOTES_COLUMNS,
  SPRAVATO_NOTES_COLUMNS,
} from './constructor/NotesConstructor'

export function filterUnsignedSpravatoNotes(
  data: NotesReportData[]
): NotesReportData[] {
  const now = new Date()
  return data.filter(
    (n) =>
      n.type === NoteTypes.SPRAVATO &&
      !n.isSigned &&
      // Filter out notes in the future.
      // From a provider's perspective, there is no action that
      // needs to be taken on an unsigned note for an appointment
      // that hasn't happened yet, so we filter these out.
      // We are currently INCLUSIVE of the current day, ignoring
      // the timestamp, since ClinicalNotes have an implicit default timestamp
      // of 12pm for some reason (Ref: https://osmind.atlassian.net/browse/PRAC-2228).
      isBeforeOrOn(n.date, now)
  )
}

type NotesTabsProps = {
  dataSource: NotesReportData[]
  isLoadingDataSource: boolean
  csvHeaders: CsvHeaders[]
  csvDataTransformer?: CSVTableDataTransformer
} & TestId

const TabKeys = {
  ALL_NOTES: '0',
  UNSIGNED_SPRAVATO_NOTES: '1',
} as const

export default function NotesTabs({
  dataSource,
  isLoadingDataSource,
  csvHeaders,
  csvDataTransformer,
  testId,
}: NotesTabsProps) {
  const [activeKey, setActiveKey] = useState<string>(TabKeys.ALL_NOTES)

  const {
    activeFilters: activeFiltersAllNotes,
    tableChangeHandler: tableChangeHandlerAllNotes,
  } = useToolsWithTableHook(dataSource)
  const {
    activeFilters: activeFiltersUnsigned,
    tableChangeHandler: tableChangeHandlerUnsigned,
  } = useToolsWithTableHook(dataSource)

  const spravatoNotes = useMemo(() => {
    return filterUnsignedSpravatoNotes(dataSource)
  }, [dataSource])

  let buttons: React.ReactNode
  if (activeKey === TabKeys.ALL_NOTES) {
    buttons = (
      <CSVExportButton
        loading={isLoadingDataSource}
        tableName={'Notes Report'}
        data={dataSource}
        dataTransformer={csvDataTransformer}
        headers={csvHeaders}
      />
    )
  } else {
    buttons = (
      <Tooltip
        title="Downloading notes is only supported in the all notes tab."
        placement={'topRight'}
      >
        {/* We need to wrap our custom component in an HTML tag for tooltips to work. */}
        {/* Ref: https://github.com/ant-design/ant-design/issues/15909 */}
        <div>
          <CSVExportButton
            loading={true} // downloading not supported for this tab; keep disabled
            tableName={'Unsigned Spravato Notes Report'}
            data={dataSource}
            headers={csvHeaders}
          />
        </div>
      </Tooltip>
    )
  }

  return (
    <Box data-testid={testId}>
      <Tabs tabBarExtraContent={buttons} onChange={(key) => setActiveKey(key)}>
        <Tabs.TabPane tab="All notes" key={TabKeys.ALL_NOTES}>
          <Table
            columns={NOTES_COLUMNS}
            dataSource={dataSource}
            loading={isLoadingDataSource}
            // Only show top tools padding if there are filters to display.
            noToolsSection={activeFiltersAllNotes.length === 0}
            showBoxContainer={false}
            onChange={tableChangeHandlerAllNotes}
            testId={'NotesTab--AllNotesTable'}
          >
            <FilterTags
              activeFilters={activeFiltersAllNotes}
              closable={false}
            />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <>
              {/* Text needs to be in the root for the 'active' tab CSS to work. */}
              Unsigned Spravato
              <Badge
                count={spravatoNotes.length}
                color="blue"
                size="small"
                offset={[10, -18]}
              >
                <span></span>
              </Badge>
            </>
          }
          key={TabKeys.UNSIGNED_SPRAVATO_NOTES}
        >
          <Table
            columns={SPRAVATO_NOTES_COLUMNS}
            dataSource={spravatoNotes}
            loading={isLoadingDataSource}
            // Only show top tools padding if there are filters to display.
            noToolsSection={activeFiltersUnsigned.length === 0}
            showBoxContainer={false}
            onChange={tableChangeHandlerUnsigned}
            testId={'NotesTab--SpravatoTable'}
          >
            <FilterTags
              activeFilters={activeFiltersUnsigned}
              closable={false}
            />
          </Table>
        </Tabs.TabPane>
      </Tabs>
    </Box>
  )
}
