import { Space, Typography } from 'antd'

import { Divider } from '../../BaseComponents'
import { FullPageTitle } from './helpers'

import sharedStyles from '../../../containers/_shared.module.scss'
import expiredStyles from './PatientIntakeExpired.module.scss'
import styles from './_shared.module.scss'

const { Text } = Typography

export const PatientIntakeExpired = () => {
  return (
    <div className={sharedStyles.scroll}>
      <div className={expiredStyles.formExpiredPage}>
        <FullPageTitle
          titleText="Intake form request has expired"
          description="Intake form requests expire after 30 days of inactivity."
          iconType={'error'}
        />
        <div className={expiredStyles.leftAlignAllText}>
          <Space className={styles.descriptionText}>
            <Text>
              Please contact your provider and request a new intake form.
            </Text>
          </Space>
          <Divider />
          <Space>
            <Text>
              If you have any questions or need other assistance, reach out to{' '}
              <a href="mailto:helpdesk@osmind.org" target="_blank">
                helpdesk@osmind.org
              </a>
            </Text>
          </Space>
        </div>
      </div>
    </div>
  )
}
