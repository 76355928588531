import React from 'react'

import { useFormikContext } from 'formik'

import { Card } from '../../../../stories/BaseComponents'
import InlineField from '../InlineField'
import SectionToggle from '../SectionToggle'
import { sectionContainerBodyStyle, sectionContainerStyle } from '../constants'
import { REFERRING_PROVIDER } from '../field-constants'
import { ClaimForm, IsReadOnlyModeProp } from '../types'

import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-referring-provider',
}

const { TOGGLE, PROVIDER } = REFERRING_PROVIDER

const ReferringProvider: React.FC<IsReadOnlyModeProp> = ({ readOnly }) => {
  const {
    values: {
      referringProvider: { isIncluded },
    },
  } = useFormikContext<ClaimForm>()

  return (
    <Card
      testId={testIds.container}
      style={sectionContainerStyle}
      bodyStyle={sectionContainerBodyStyle}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>{'Referring provider'}</span>
        </div>
        <div>
          <SectionToggle {...TOGGLE} disabled={readOnly} />
          {isIncluded &&
            PROVIDER.map((item) => (
              <InlineField {...item} key={item.name} disabled={readOnly} />
            ))}
        </div>
      </div>
    </Card>
  )
}

export default ReferringProvider
