// ATTENTION
// This file has a lot of repetition for components that are reallly similar,
// but ever so slightly difficult to consolidate. I think there's a good opportunity
// to DRY up via a custom useEditable hook that would cover the interactions + label /at least/
import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Select as AntSelect, Input, InputNumber } from 'antd'
import { InputStatus } from 'antd/lib/_util/statusUtils'
import cx from 'classnames'

import { usePrevious } from '../../hooks/usePrevious'
import { formatNumberStringToUsdString } from '../../libs/billing'
import { notification } from '../../libs/notificationLib'
import { TestId } from '../../shared-types'

import './EditableInputs.scss'

export type EditableInputProps<T> = {
  label?: string
  shouldHighlight?: boolean
  value?: string | string[] | number | T
  placeholder?: string
  onChange: (value: T) => void
  options?: React.ReactNode[]
  mode?: 'multiple'
  style?: React.CSSProperties
  limit?: number
  validation?: {
    validate: (input: string) => boolean
    errorMessage: string
  }
  closeEditing?: boolean[]
  suffix?: React.ReactNode
  disabled?: boolean
  isLoading?: boolean
} & TestId

type LabelProps = {
  text: string
  setIsEditing: (val: boolean) => void
  role?: string
  disabled?: boolean
} & TestId

const Label = ({
  text,
  setIsEditing,
  role,
  testId,
  disabled = false,
}: LabelProps) => (
  <div
    className={disabled ? undefined : 'editable-wrapper'}
    onClick={() => !disabled && setIsEditing(true)}
    role={role}
    data-testid={testId}
  >
    <span className={disabled ? undefined : 'editable'}>{text}</span>
  </div>
)

export const EditableInput = ({
  value,
  placeholder,
  closeEditing = [false],
  style,
  validation,
  onChange,
  suffix,
  testId,
  disabled = false,
  isLoading = false,
}: EditableInputProps<string>) => {
  const [isEditing, setIsEditing] = React.useState(false)
  const [current, setCurrent] = React.useState(value)
  const [isValid, setIsValid] = React.useState(true)

  React.useEffect(() => {
    setCurrent(value)
  }, [value])

  React.useEffect(() => {
    if (!isValid) validation && notification(validation.errorMessage)
  }, [isValid])

  React.useEffect(() => {
    const last = closeEditing.length - 1
    if (closeEditing[last]) setIsEditing(false)
  }, [closeEditing])

  if (isLoading) {
    return <LoadingOutlined />
  }

  if (isEditing) {
    return (
      <Input
        data-testid={testId}
        className={!isValid ? 'input-error' : ''}
        style={{ height: '30px', ...style }}
        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
          setCurrent(value?.currentTarget?.value)
        }}
        onBlur={() => {
          if (suffix) {
            onChange(current as string)
            return
          }
          if (validation) {
            const isValidCheck = validation.validate(current as string)
            setIsValid(isValidCheck)
            if (isValidCheck) {
              onChange(current as string)
              setIsEditing(false)
            }
          } else {
            onChange(current as string)
            setIsEditing(false)
          }
        }}
        value={current}
        placeholder={placeholder || ''}
        suffix={suffix}
      />
    )
  }

  return (
    <Label
      text={(value || placeholder) as string}
      setIsEditing={setIsEditing}
      testId={testId}
      disabled={disabled}
    />
  )
}

export const EditableInputNumber = ({
  value,
  label,
  placeholder,
  style,
  onChange,
  testId,
  disabled = false,
}: EditableInputProps<number>) => {
  const [isEditing, setIsEditing] = React.useState(false)
  const [current, setCurrent] = React.useState(value)

  if (isEditing) {
    return (
      <InputNumber
        data-testid={testId ? `${testId}-input` : undefined}
        style={{ height: '30px', ...style }}
        onChange={(value) => setCurrent(Math.abs(Number(value)))}
        onBlur={() => {
          onChange(current as number)
          setIsEditing(false)
        }}
        value={Number(current).toFixed(2)}
        placeholder={placeholder || ''}
        stringMode
      />
    )
  }

  return (
    <Label
      text={(label || value || placeholder) as string}
      setIsEditing={setIsEditing}
      testId={testId}
      disabled={disabled}
    />
  )
}

export const EditableSelect = <T extends unknown>({
  label,
  shouldHighlight = false,
  value,
  placeholder,
  onChange,
  options = [],
  mode,
  style,
  limit,
  disabled = false,
  testId,
}: EditableInputProps<T>) => {
  const [isEditing, setIsEditing] = React.useState(false)
  const [current, setCurrent] = React.useState(value as T | T[])

  React.useEffect(() => {
    setCurrent(value as T | T[])
  }, [value])

  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        if (current !== value) onChange(current as T)
        setIsEditing(false)
      }
    }

    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [ref, current])

  if (isEditing || shouldHighlight) {
    return (
      <div
        className={`${shouldHighlight ? 'missing-highlight' : 'full-width'}`}
        ref={ref}
        role="editing-select"
        data-testid={testId}
      >
        <AntSelect
          open={isEditing || (shouldHighlight ? undefined : false)}
          disabled={disabled}
          style={{ width: '100%', ...style }}
          onChange={(values?: unknown) => {
            if (shouldHighlight) setIsEditing(true)
            if (mode !== 'multiple') {
              onChange(values as T)
              setIsEditing(false)
            } else if (limit && (values as T[]).length > limit) {
              notification(
                `Only ${limit} items allowed. Remove one before adding another.`
              )
              setCurrent((values as T[]).slice(0, limit) as T)
            } else {
              setCurrent(values as T)
              onChange(values as T)
            }
          }}
          value={current as string[] | string}
          placeholder={placeholder || ''}
          mode={mode}
          onDeselect={
            mode === 'multiple'
              ? (value: unknown) => {
                  const currentValues = Array.from(current as T[])
                  const existingIdx = currentValues.findIndex(
                    (val) => val === value
                  )
                  if (existingIdx >= 0) {
                    currentValues.splice(existingIdx, 1)
                    setCurrent(currentValues)
                  }
                }
              : undefined
          }
        >
          {options}
        </AntSelect>
      </div>
    )
  }

  return (
    <Label
      text={(label || value || placeholder) as string}
      setIsEditing={setIsEditing}
      role="select"
      testId={testId}
      disabled={disabled}
    />
  )
}

const INITIAL_VALUE = 0

export const USDInput = ({
  disabled = false,
  value = INITIAL_VALUE,
  max,
  onChange,
  testId,
  className = '',
  onFocus,
  onBlur,
  status,
  error,
}: {
  disabled?: boolean
  value?: number | null
  max?: number
  onChange?: (val: number | null) => void
  className?: string | null
  onFocus?: () => void
  onBlur?: () => void
  status?: InputStatus
  error?: string
} & TestId) => {
  const [isEditing, setIsEditing] = React.useState(false)
  const wasEditing = usePrevious(isEditing)
  const handleBlur = () => {
    setIsEditing(false)
    onBlur?.()
  }
  const handleFocus = () => {
    setIsEditing(true)
    onFocus?.()
  }

  // If just entering input field, set value in component to "empty" if
  // the initial value in the field is a variation of 0.
  const controlledValue =
    isEditing && !wasEditing && value !== null && [0, 0.0].includes(value)
      ? undefined
      : value

  return (
    <>
      <InputNumber
        data-testid={testId}
        className={cx(
          'usd-input',
          isEditing && 'is-editing',
          className && className
        )}
        max={max}
        addonBefore="USD"
        disabled={disabled}
        value={controlledValue}
        onFocusCapture={onFocus}
        // TODO: Fix this typing issue that is error based on new versions of @types/react
        // @ts-ignore
        onChange={onChange}
        step={1}
        precision={2}
        stringMode
        formatter={(value, { userTyping }) =>
          (!userTyping &&
            formatNumberStringToUsdString(value, {
              excludeDollarSymbol: true,
            })) ||
          `${value}` ||
          ''
        }
        status={status}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={value ? value.toString() : INITIAL_VALUE.toString()}
      />
      {error && <p className="error">{error}</p>}
    </>
  )
}
