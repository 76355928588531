import { useEffect, useState } from 'react'

import qs from 'query-string'

import { QUERY_PARAMS } from '../../libs/constants'
import { useQuery } from '../../libs/utils'

export const useProfileUrlParams = (propsProviderId?: string) => {
  const query = useQuery()
  const patientId = query.get(QUERY_PARAMS.patientId) ?? ''
  const providerId = query.get(QUERY_PARAMS.providerId)
    ? query.get(QUERY_PARAMS.providerId)
    : propsProviderId ?? ''
  const profilePanel = query.get(QUERY_PARAMS.profilePanel) ?? ''
  const [urlParams, setUrlParams] = useState<string>('')

  useEffect(() => {
    if (profilePanel && patientId) {
      setUrlParams(
        qs.stringify(
          { patientId, providerId, profilePanel },
          {
            sort: false,
          }
        )
      )
    }
  }, [patientId, profilePanel, providerId])

  return { patientId, providerId, urlParams, profilePanel }
}
