import { useCallback, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { updateClaim as updateClaimV2 } from 'api/insuranceClaimsV2'
import { ChangePayersList } from 'api/intakeForms'
import { useCreateClaimV1 } from 'hooks/usePatientProfile'
import { useFeatureFlags } from 'libs/featureFlags'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { generateNotification } from 'stories/BaseComponents'
import { NotificationType } from 'stories/BaseComponents/Notification'

import { MissingClaimIdError } from './ErrorHandling/Errors'
import { useClaimsErrorLogForExternalMonitor } from './ErrorHandling/ExternalMonitoring'
import { DEFAULT_INSURANCE_TYPE_CODE } from './constants'
import { QUERY_KEYS, useCreateClaimV2, usePayers } from './hooks'
import { ClaimData, CreateClaimBaseParameter } from './types'
import {
  buildClaimRoute,
  getClaimFilingCodeForPayer,
  isMedicare,
} from './utils'

export const DISPLAY_STRINGS = {
  warningMissingProviderId:
    '`providerId` should not be null. Attempting to route anyway',
  notificationAddingDefaultValues:
    'Adding default values to claim, where applicable',
  notificationCreatingBasedOnNote: 'Creating Claim based on current note',
  notificationCreatingClaim: 'Creating Claim for patient',
  notificationCreatedButMissingClaimId:
    'Claim was created but is missing ClaimID. Try creating one again.',
}

/**
 * UseQuery observer properties
 * - mutation capabilities have been omitted
 */
export type UseCreateClaimAndNavigateThereResult = {
  /**
   * Is the claim currently being created?
   */
  isCreating: boolean
  /**
   * Create the claim and then navigate to that claim route to view it
   */
  createClaimAndNavigateThere: (options?: { noteId?: string }) => void
}

// auto fill some values if it is a newly created claim
// can be removed after implementation of CARE-2349 & CARE-2950
export const updateClaimV2WithDefaultValues = async ({
  claimData,
  payersByName,
}: {
  claimData: ClaimData
  payersByName: _.Dictionary<ChangePayersList>
}) => {
  const updatedClaimData = _.cloneDeep(claimData)

  if (updatedClaimData?.primaryInsurance?.name && payersByName) {
    updatedClaimData.primaryInsurance.claimFilingCode =
      getClaimFilingCodeForPayer(
        payersByName,
        updatedClaimData.primaryInsurance.name
      )
  } else if (updatedClaimData.primaryInsurance) {
    updatedClaimData.primaryInsurance.claimFilingCode = null
  }

  if (updatedClaimData.primaryInsurance?.name) {
    updatedClaimData.selectedInsuranceName =
      updatedClaimData.primaryInsurance?.name
  } else {
    updatedClaimData.selectedInsuranceName = null
  }

  if (updatedClaimData?.secondaryInsurance?.name && payersByName) {
    updatedClaimData.secondaryInsurance.claimFilingCode =
      getClaimFilingCodeForPayer(
        payersByName,
        updatedClaimData.secondaryInsurance.name
      )
  } else if (updatedClaimData.secondaryInsurance) {
    updatedClaimData.secondaryInsurance.claimFilingCode = null
  }

  if (
    updatedClaimData.secondaryInsurance &&
    isMedicare(updatedClaimData?.secondaryInsurance?.claimFilingCode || null)
  ) {
    updatedClaimData.secondaryInsurance.insuranceTypeCode =
      DEFAULT_INSURANCE_TYPE_CODE
  }

  await updateClaimV2({ claimId: claimData.claimId, body: updatedClaimData })
}

/**
 * create a claim and then navigate to it
 *
 * Testing covered in ./Components/CreateNewClaimButton
 */
export const useCreateClaimAndNavigateThere = ({
  patientId,
  providerId,
}: CreateClaimBaseParameter & {
  providerId: string // TODO: have this require non-null
}): UseCreateClaimAndNavigateThereResult => {
  const history = useHistory()
  const { logClaimsErrorToExternalMonitor } =
    useClaimsErrorLogForExternalMonitor()
  const { claimCreationV2 } = useFeatureFlags()
  const {
    data: { payersByName },
  } = usePayers()
  const queryClient = useQueryClient()
  /**
   * This is used for tracking the loading state across V2 Create and V2 Update calls
   * - Once the BE populates this on create, then this state can be removed
   * - @see https://osmind.atlassian.net/browse/CARE-2950
   */
  const [isLoadingV2Update, setIsLoadingV2Update] = useState(false)

  const navigateToClaim = useCallback(
    (claimId: string) => {
      if (typeof providerId !== 'string' || providerId.length < 1) {
        logClaimsErrorToExternalMonitor({
          patientId,
          providerId: providerId !== null ? providerId : '',
          claimId,
          claimsVersion: claimCreationV2 ? 2 : 1,
          message: DISPLAY_STRINGS.warningMissingProviderId,
        })
      }
      const path = buildClaimRoute({ claimId, patientId, providerId })
      history.push(path)
    },
    [providerId, patientId, claimCreationV2, history]
  )

  const onSuccessV1 = async ({ claimId }: ClaimData) => {
    if (!claimId) {
      generateNotification(DISPLAY_STRINGS.notificationCreatedButMissingClaimId)
      logClaimsErrorToExternalMonitor({
        patientId,
        providerId: providerId !== null ? providerId : '',
        claimId,
        claimsVersion: 1,
        message: DISPLAY_STRINGS.notificationCreatedButMissingClaimId,
      })
      return
    }

    // TODO [CARE-2954]: add in "update with defaults" into Claims V1 if needed, otherwise may regress in functionality
    navigateToClaim(claimId)
  }
  const onSuccessV2 = useCallback(
    async (claimData: ClaimData) => {
      if (!claimData.claimId) {
        generateNotification(
          DISPLAY_STRINGS.notificationCreatedButMissingClaimId
        )
        logClaimsErrorToExternalMonitor({
          patientId,
          providerId,
          claimId: claimData.claimId,
          message: DISPLAY_STRINGS.notificationCreatedButMissingClaimId,
        })
        throw new MissingClaimIdError({ patientId })
      }
      /**
       * Jenky and will be removable after CARE-2349
       * - Preserves prior functionality where some default values were populated upon claim creation (before saving as draft)
       * - Now has to happen as a second API call before navigating to claim
       */
      generateNotification(
        DISPLAY_STRINGS.notificationAddingDefaultValues,
        NotificationType.LOADING
      )
      await updateClaimV2WithDefaultValues({ claimData, payersByName })

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CLAIM, claimData.claimId],
      })

      setIsLoadingV2Update(false)

      navigateToClaim(claimData.claimId)
    },
    [patientId, providerId, setIsLoadingV2Update, navigateToClaim]
  )

  const onErrorSupplementaryCallback = () => {
    setIsLoadingV2Update(false)
  }
  const { mutate: doCreateClaimV1, isLoading: isLoadingV1 } =
    useCreateClaimV1(onSuccessV1)
  const { mutate: doCreateClaimV2, isLoading: isLoadingV2Creation } =
    useCreateClaimV2(onSuccessV2, onErrorSupplementaryCallback)

  /**
   * This calls a useQuery mutation to create the claim
   * OnSuccess callback of that mutation will then navigate to the claim
   */
  const createClaimAndNavigateThere = useCallback<
    UseCreateClaimAndNavigateThereResult['createClaimAndNavigateThere']
  >(
    ({ noteId } = {}) => {
      // the loading from the mutations might take time to register as loading
      if (isLoadingV2Update || isLoadingV1 || isLoadingV2Creation) {
        // TODO: ADD TESTS
        /**
         * Prevents creating two claims with double clicks
         * - should not be able to get here
         */
        return
      }
      setIsLoadingV2Update(true)

      if (!!noteId && noteId.length) {
        // TODO: ADD TESTS
        generateNotification(
          DISPLAY_STRINGS.notificationCreatingBasedOnNote,
          NotificationType.LOADING
        )
      } else {
        generateNotification(
          DISPLAY_STRINGS.notificationCreatingClaim,
          NotificationType.LOADING
        )
      }

      if (claimCreationV2) {
        // Subsequent action performed using onSuccess callbacks in the mutation
        doCreateClaimV2({ patientId, noteId })
      } else {
        // Subsequent action performed using onSuccess callbacks in the mutation
        doCreateClaimV1({ patientId, noteId })
      }
    },
    [
      isLoadingV2Update,
      isLoadingV1,
      isLoadingV2Creation,
      claimCreationV2,
      patientId,
      doCreateClaimV2,
      doCreateClaimV1,
      setIsLoadingV2Update,
    ]
  )

  const isCreating = claimCreationV2
    ? isLoadingV2Creation || isLoadingV2Update
    : isLoadingV1

  return {
    isCreating,
    createClaimAndNavigateThere,
  }
}
