import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'

import { AxiosError } from 'axios'
import { Form, Formik, FormikProps } from 'formik'
import { isEqual } from 'lodash'
import { useHistory } from 'react-router-dom'

import { PracticeDataType } from '../../hooks/usePatientProfile/shared-types'
import useQueryString from '../../hooks/useQueryString'
import { Spinner } from '../../stories/BaseComponents'
import {
  NotificationType,
  generateNotification,
} from '../../stories/BaseComponents/Notification'
import {
  MissingClaimIdError,
  MissingPatientIdError,
} from './ClaimsV2/ErrorHandling/Errors'
import {
  GATEWAY_TIMEOUT_PROBLEM_DETAILS,
  GATEWAY_TIMEOUT_STATUS,
} from './ClaimsV2/constants'
import {
  useActiveDiagnoses,
  useDeleteClaim,
  useGetClaimData,
  useLocations,
  useNotes,
  usePayers,
  usePosCodes,
  useProviders,
  useSaveClaim,
  useSaveClaimMemo,
  useSubmitClaim,
  useUpdateStatusChangeManually,
} from './ClaimsV2/hooks'
import { toClaimData, toFormValues } from './ClaimsV2/mappers'
import { DeleteClaimModal, UnsavedChangesModal } from './ClaimsV2/modals'
import {
  Appointment,
  Billing,
  ClaimError,
  ClaimHeader,
  ClaimPayments,
  ClaimStatusDetails,
  Diagnosis,
  Memo,
  PatientInfo,
  Payer,
  PayerAddress,
  PrimaryInsurance,
  Procedures,
  ReferringProvider,
  RenderingProvider,
  SecondaryInsurance,
  Signature,
  SupervisingProvider,
} from './ClaimsV2/sections'
import {
  ClaimErrorOptions,
  ClaimForm,
  ClaimFormStatus,
  ClaimStatus,
  ErrorResponse,
} from './ClaimsV2/types'
import {
  isClaimPresubmit,
  isFormDeleting,
  isFormInitialLoading,
  isLoadingStatus,
} from './ClaimsV2/utils'
import { PatientHeader } from './PatientHeader'

import styles from './ClaimsV2.module.scss'

export type ClaimsV2Props = {
  healthGorillaUserName: string
  practiceData: Omit<PracticeDataType, 'CreatedAt'>
}

const ClaimsV2: React.FC<ClaimsV2Props> = ({
  healthGorillaUserName,
  practiceData,
}) => {
  const history = useHistory()
  // get initial values from URL search params
  const query = useQueryString()
  const patientId = query.get('patientId') ?? ''
  const providerId = query.get('providerId') ?? ''
  const initialClaimId = query.get('claimId') ?? ''
  const formRef = useRef<FormikProps<ClaimForm> | null>(null)
  const containerRef: RefObject<HTMLDivElement> = useRef(null)

  if (!patientId || patientId.length < 1) {
    throw new MissingPatientIdError()
  }
  if (!initialClaimId || initialClaimId.length < 1) {
    throw new MissingClaimIdError({ patientId })
  }

  const scrollToTop = () => {
    try {
      containerRef?.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } catch {
      return
    }
  }

  const {
    data: claimData,
    status: claimFetchStatus,
    refetch: refetchClaim,
  } = useGetClaimData({
    claimId: initialClaimId,
  })

  const getClaimId = () => initialClaimId
  /**
   * We're going to trust that the claimId in the query params matches the one attached to the claim
   * - if it doesn't, there's not much we can do other than throw an error,
   *   because updating the query string would just create an endless reload loop
   */

  /*
    Calling usePayers hook in the parent and pass the data to components that require it, instead of calling it in each component.
    This ensures the expensive calculations inside of hook only run once.
    The impact can be significant considering the payers list has ~3000 items.
  */
  const { data: payersData, status: payersFetchStatus } = usePayers()

  const { status: providersFetchStatus } = useProviders()

  const { status: locationsFetchStatus } = useLocations()

  const { status: posCodesFetchStatus } = usePosCodes()

  const { status: notesFetchStatus } = useNotes(patientId)

  const { data: activeDiagnoses, status: activeDiagnosesFetchStatus } =
    useActiveDiagnoses(patientId)

  const { mutateAsync: doDeleteClaim, status: deleteClaimStatus } =
    useDeleteClaim({
      patientId,
    })

  const { mutateAsync: doSaveClaim, status: saveClaimStatus } = useSaveClaim({
    claimId: getClaimId(),
  })

  const { mutateAsync: doSaveClaimMemo, status: saveClaimMemoStatus } =
    useSaveClaimMemo({
      claimId: getClaimId(),
    })

  const { mutateAsync: doSubmitClaim, status: submitClaimStatus } =
    useSubmitClaim({
      claimId: getClaimId(),
    })

  // states
  const [errorAndOptions, setErrorAndOptions] = useState<{
    error?: ErrorResponse
    options?: ClaimErrorOptions
  } | null>(null)

  const onManualSubmitErrorCallback = (e: AxiosError) => {
    const error = e?.response?.data?.error
    if (error?.info?.type === 'tag:osmind.org,2024:error/refreshRequired') {
      setErrorAndOptions({ error: e?.response?.data?.error })
      scrollToTop()
    }
  }

  const { updateStatusChangeManually, isLoading: isLoadingManualStatusChange } =
    useUpdateStatusChangeManually({
      claimId: initialClaimId,
      claimData,
      onManualSubmitErrorCallback,
    })

  const [initialMemoValue, setInitialMemoValue] = useState<string | null>(null)
  const [initialFormValues, setInitialFormValues] = useState<ClaimForm | null>(
    null
  )

  const [isShowDeleteClaimModal, setIsShowDeleteClaimModal] =
    useState<boolean>(false)
  const [isShowUnsavedChangesModal, setIsShowUnsavedChangesModal] =
    useState(false)
  const [isPaymentsInEditMode, setIsPaymentsInEditMode] = useState(false)
  // reusable functions
  const navigateToBilling = () => {
    history.push(
      `/patient/billing?patientId=${patientId}&providerId=${providerId}&tab=claims`
    )
  }

  const getCurrentFormValues = () => {
    return formRef.current?.values
  }

  const hasUnsaved = () => {
    // memo value may have been saved independently
    const initialFormValuesWithUpdatedMemo = {
      ...initialFormValues,
      claimMemo: initialMemoValue,
    }
    return !isEqual(initialFormValuesWithUpdatedMemo, getCurrentFormValues())
  }

  const saveClaim = async () => {
    const formValues = getCurrentFormValues()
    const claimId = getClaimId()
    if (!formValues || !claimId || !claimData || !patientId) return
    const body = toClaimData(formValues, {
      claimId,
      patientId,
      claimStatus: claimData.claimStatus,
      patientControlNumber: claimData.patientControlNumber,
    })
    try {
      await doSaveClaim({ claimId, body })
      setErrorAndOptions(null)
      generateNotification(
        'Successfully saved claim as draft.',
        NotificationType.SUCCESS
      )
      navigateToBilling()
    } catch (error) {
      if (error.isAxiosError) {
        setErrorAndOptions({ error: error.response?.data })
        scrollToTop()
      }
      generateNotification(
        'Failed to save claim as draft.',
        NotificationType.ERROR
      )
    }
  }

  const deleteClaim = async () => {
    const claimId = getClaimId()
    if (!claimId) return
    try {
      await doDeleteClaim(claimId)
      generateNotification(
        'Successfully deleted claim.',
        NotificationType.SUCCESS
      )
    } catch (error) {
      generateNotification('Failed to delete claim.', NotificationType.ERROR)
    }
  }

  const saveClaimMemo = async () => {
    const claimId = getClaimId()
    if (!claimId || !patientId) {
      return
    }
    const currentMemoValue = getCurrentFormValues()?.claimMemo ?? null
    try {
      await doSaveClaimMemo({
        claimId,
        memo: currentMemoValue,
      })

      setInitialMemoValue(currentMemoValue)

      generateNotification(
        'Successfully saved claim memo.',
        NotificationType.SUCCESS
      )
    } catch (error) {
      generateNotification(
        'There was an error saving the memo.',
        NotificationType.ERROR
      )
    }
  }

  const handleRefetchClaim = () => {
    if (refetchClaim) {
      refetchClaim()
    }
  }

  // handlers
  const handleOpenDeleteClaimModal = () => {
    setIsShowDeleteClaimModal(true)
  }

  const handleCloseDeleteClaimModal = () => {
    setIsShowDeleteClaimModal(false)
  }

  const handleOpenUnsavedChangesModal = () => {
    setIsShowUnsavedChangesModal(true)
  }

  const handleCloseUnsavedChangesModal = () => {
    setIsShowUnsavedChangesModal(false)
  }

  const handleGoBack = () => {
    if (hasUnsaved()) {
      handleOpenUnsavedChangesModal()
    } else {
      history.goBack()
    }
  }

  const handleConfirmDeleteClaim = async () => {
    await deleteClaim()
    handleCloseDeleteClaimModal()
    // TODO: discuss navigation behavior after deleting
    navigateToBilling()
  }

  const handleSaveClaim = async () => {
    await saveClaim()
  }

  const handleSubmitClaim = async () => {
    const formValues = getCurrentFormValues()
    const claimId = getClaimId()
    // https://osmind.atlassian.net/browse/CARE-2740 Add validation for all required fields in formValues
    if (!formValues || !claimId || !claimData || !patientId) return
    const body = toClaimData(formValues, {
      claimId,
      patientId,
      claimStatus: claimData.claimStatus,
      patientControlNumber: claimData.patientControlNumber,
    })
    try {
      await doSubmitClaim({ body })
      setErrorAndOptions(null)
      generateNotification(
        'Successfully submitted claim.',
        NotificationType.SUCCESS
      )
      navigateToBilling()
    } catch (error) {
      const isTimeoutError = error.response?.status === GATEWAY_TIMEOUT_STATUS
      if (isTimeoutError) {
        setErrorAndOptions({ options: GATEWAY_TIMEOUT_PROBLEM_DETAILS })
        scrollToTop()
      } else if (error.isAxiosError) {
        setErrorAndOptions({ error: error.response?.data })
        scrollToTop()
      }
      generateNotification('Failed to submit claim.', NotificationType.ERROR)
    }
  }

  const handleSaveClaimMemo = async () => {
    await saveClaimMemo()
  }

  const handleCancelClaim = () => {
    generateNotification('Cancel claim not working yet!', NotificationType.INFO)
  }

  const handleEditPayments = () => {
    setIsPaymentsInEditMode(true)
  }

  const handleSaveUnsavedChanges = async () => {
    await saveClaim()
    handleCloseUnsavedChangesModal()
  }

  const handleDiscardUnsavedChanges = async () => {
    handleCloseUnsavedChangesModal()
    // TODO: discuss navigation behavior after discarding claim from the warning modal
    navigateToBilling()
  }

  // Remove payersByName dependency once BE is fully prefilling all desired data on claim create (CARE-2349)
  useEffect(() => {
    if (!claimData || !payersData.payersByName || !activeDiagnoses) {
      return
    }
    setInitialMemoValue(claimData.claimMemo ?? null)
    setInitialFormValues(
      toFormValues(claimData, payersData.payersByName, activeDiagnoses)
    )
  }, [claimData, payersData, activeDiagnoses])

  // only one action can happen at a given time, we can consolidate these network states into one variable
  const formStatus: ClaimFormStatus = useMemo(() => {
    if (
      [
        payersFetchStatus,
        claimFetchStatus,
        providersFetchStatus,
        locationsFetchStatus,
        posCodesFetchStatus,
        notesFetchStatus,
        activeDiagnosesFetchStatus,
      ].some(isLoadingStatus)
    ) {
      return 'INITIAL_LOADING'
    } else if (isLoadingStatus(saveClaimStatus)) {
      return 'SAVING_CLAIM'
    } else if (isLoadingStatus(deleteClaimStatus)) {
      return 'DELETING_CLAIM'
    } else if (isLoadingStatus(saveClaimMemoStatus)) {
      return 'SAVING_CLAIM_MEMO'
    } else if (isLoadingStatus(submitClaimStatus)) {
      return 'SUBMITTING_CLAIM'
    } else if (isLoadingManualStatusChange) {
      return 'MANUALLY_UPDATING_CLAIM_STATUS'
    }
    return null
  }, [
    claimFetchStatus,
    providersFetchStatus,
    payersFetchStatus,
    locationsFetchStatus,
    posCodesFetchStatus,
    notesFetchStatus,
    activeDiagnosesFetchStatus,
    saveClaimStatus,
    deleteClaimStatus,
    saveClaimMemoStatus,
    submitClaimStatus,
    isLoadingManualStatusChange,
  ])

  // Disable inputs and hide action buttons after submission
  const isReadOnly = !isClaimPresubmit(
    claimData?.claimStatus ?? ClaimStatus.DRAFT
  )

  return (
    <>
      <div className={styles.scroll} ref={containerRef}>
        <PatientHeader
          providerId={providerId}
          patientId={patientId}
          healthGorillaUserName={healthGorillaUserName}
        />
        <div className={styles.container}>
          {isFormInitialLoading(formStatus) && (
            <div
              data-testid="claim-loading-container"
              className={styles.loadingIndicator}
            >
              <Spinner />
            </div>
          )}
          {!!errorAndOptions && <ClaimError {...errorAndOptions} />}
          {!!claimData && (
            <>
              <ClaimHeader
                claim={claimData}
                onGoBack={handleGoBack}
                onDeleteClaim={handleOpenDeleteClaimModal}
                onSaveClaim={handleSaveClaim}
                onCancelClaim={handleCancelClaim}
                onEditPayments={handleEditPayments}
                onUpdateStatusChangeManually={updateStatusChangeManually}
                isLoadingManualStatusChange={isLoadingManualStatusChange}
                formStatus={formStatus}
              />
              {claimData.claimStatusUpdate && (
                <ClaimStatusDetails
                  claimStatusUpdate={claimData.claimStatusUpdate}
                />
              )}
              {!isClaimPresubmit(claimData.claimStatus) && (
                <ClaimPayments
                  claim={claimData}
                  patientId={patientId}
                  isEditMode={isPaymentsInEditMode}
                  setIsEditMode={setIsPaymentsInEditMode}
                  refetchClaim={handleRefetchClaim}
                />
              )}
            </>
          )}
          {claimData && initialFormValues && (
            <div data-testid="claim-data-container">
              <Formik
                initialValues={initialFormValues}
                // onSubmit has undesired side effects (setting touched for all fields to true) that screws up dependent fields logic
                // pass in a dummy function since it's a required prop
                // eslint-disable-next-line
                onSubmit={() => {}}
                innerRef={formRef}
              >
                <Form>
                  <Memo
                    initialValue={initialMemoValue}
                    onSave={handleSaveClaimMemo}
                    formStatus={formStatus}
                  />
                  <PatientInfo readOnly={isReadOnly} />
                  <PrimaryInsurance payers={payersData} readOnly={isReadOnly} />
                  <Payer
                    payerOptions={payersData.payerOptions}
                    readOnly={isReadOnly}
                  />
                  <PayerAddress readOnly={isReadOnly} />
                  <SecondaryInsurance
                    payers={payersData}
                    readOnly={isReadOnly}
                  />
                  <ReferringProvider readOnly={isReadOnly} />
                  <Appointment
                    patientId={patientId}
                    practiceData={practiceData}
                    readOnly={isReadOnly}
                  />
                  <RenderingProvider readOnly={isReadOnly} />
                  <SupervisingProvider readOnly={isReadOnly} />
                  <Billing
                    patientId={patientId}
                    practiceData={practiceData}
                    readOnly={isReadOnly}
                  />
                  <Diagnosis patientId={patientId} readOnly={isReadOnly} />
                  <Procedures patientId={patientId} readOnly={isReadOnly} />
                  <Signature
                    formStatus={formStatus}
                    onDeleteClaim={handleOpenDeleteClaimModal}
                    onSaveClaim={handleSaveClaim}
                    onSubmitClaim={handleSubmitClaim}
                    readOnly={isReadOnly}
                  />
                </Form>
              </Formik>
            </div>
          )}
        </div>
      </div>
      <DeleteClaimModal
        visible={isShowDeleteClaimModal}
        onCancel={handleCloseDeleteClaimModal}
        onDeleteClaim={handleConfirmDeleteClaim}
        isDeleteClaimLoading={isFormDeleting(formStatus)}
      />
      <UnsavedChangesModal
        visible={isShowUnsavedChangesModal}
        onCancel={handleCloseUnsavedChangesModal}
        onSave={handleSaveUnsavedChanges}
        onDiscard={handleDiscardUnsavedChanges}
        formStatus={formStatus}
      />
    </>
  )
}

export default ClaimsV2
