import React, { useMemo, useState } from 'react'

import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  mapToOptionGroups,
  useBillingCodeDropdownOptions,
} from 'containers/Billing/useBillingCodeDropdownOptions'
import { useFormikContext } from 'formik'
import { useBillingCodes } from 'hooks/queries/useBillingCodes'
import { useBillingTemplates } from 'hooks/queries/useBillingTemplates'
import { useBillingCodeDxMapping } from 'hooks/useBillingInfo/useBillingCodeDxMapping'

import {
  Button,
  Card,
  Input,
  Select,
  Spinner,
  Tooltip,
} from '../../../../stories/BaseComponents'
import { sectionContainerBodyStyle, sectionContainerStyle } from '../constants'
import { ClaimForm, ClaimFormLineItem, IsReadOnlyModeProp } from '../types'
import { getIncludedDiagnosesWithOrder } from '../utils'
import LineItemsTable from './LineItemsTable'
import NationalDrugCodeTable from './NationalDrugCodeTable'
import {
  addBillingCodeToLineItems,
  addBillingTemplateToLineItems,
} from './Procedures-helper'

import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-procedures',
  authNumber: 'claim-procedures-auth-number-input',
  billingTemplate: 'claim-procedures-billing-template-select',
  billingCode: 'claim-procedures-billing-code-select',
  addBillingCodeButton: 'claim-procedures-billing-code-add-button',
}

type ProceduresProps = {
  patientId: string
} & IsReadOnlyModeProp

const NotFoundContent: React.FC<{
  isFetching: boolean
  type: string
}> = ({ isFetching, type }) =>
  isFetching ? (
    <div
      className={styles.spinnerContainer}
      data-testid={`${type}-loading-indicator`}
    >
      <Spinner fontSize={24} />
    </div>
  ) : (
    <span data-testid={`${type}-not-found`}>Not found</span>
  )
const verticalInputStyle = { maxWidth: 530, width: '100%' }

const Procedures: React.FC<ProceduresProps> = ({ patientId, readOnly }) => {
  const {
    values: { authNumber, lineItems, diagnoses },
    setFieldValue,
  } = useFormikContext<ClaimForm>()
  const [templateSearchTerm, setTemplateSearchTerm] = useState('')
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  )
  const [billingCodeSearchTerm, setBillingCodeSearchTerm] = useState('')
  const [selectedBillingCodeId, setSelectedBillingCodeId] = useState<
    number | null
  >(null)

  const {
    templates,
    templatesById,
    isLoading: isFetchingBillingTemplates,
  } = useBillingTemplates(templateSearchTerm)

  const {
    codes,
    codesById,
    isLoading: isFetchingBillingCodes,
  } = useBillingCodes(billingCodeSearchTerm)

  const { mapping, isInitialLoading: isFetchingBillingCodeDxMapping } =
    useBillingCodeDxMapping(patientId)

  const includedDiagnosesWithOrder = useMemo(
    () => getIncludedDiagnosesWithOrder(diagnoses),
    [diagnoses]
  )

  const handleAuthNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('authNumber', e.target.value || null)
  }

  const handleSearchTemplates = (searchValue: string) => {
    setTemplateSearchTerm(searchValue)
  }

  const handleTemplateChange = (newTemplateId: any) => {
    setSelectedTemplateId(newTemplateId)
    const selectedTemplate = templatesById[newTemplateId]
    if (!selectedTemplate) {
      return
    }
    const newLineItems: ClaimFormLineItem[] = addBillingTemplateToLineItems(
      lineItems,
      selectedTemplate,
      mapping,
      includedDiagnosesWithOrder
    )
    setFieldValue('lineItems', newLineItems)
  }

  const handleSearchBillingCodes = (searchValue: string) => {
    setBillingCodeSearchTerm(searchValue)
  }

  const handleBillingCodeChange = (newBillingCode: any) => {
    setSelectedBillingCodeId(newBillingCode)
  }

  const handleAddBillingCode = () => {
    const newBillingCode =
      selectedBillingCodeId && codesById[selectedBillingCodeId]
    if (!newBillingCode) {
      return
    }
    const newLineItems = addBillingCodeToLineItems(
      lineItems,
      newBillingCode,
      mapping,
      includedDiagnosesWithOrder
    )
    setFieldValue('lineItems', newLineItems)
  }

  const templateOptions = useMemo(
    () => templates?.map(({ name, id }) => ({ label: name, value: id })),
    [templates]
  )

  const billingCodeOptions = useBillingCodeDropdownOptions(codes)

  return (
    <Card
      testId={testIds.container}
      style={sectionContainerStyle}
      bodyStyle={sectionContainerBodyStyle}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <div>
            <span className={styles.title}>{'Procedures'}</span>
            <Tooltip
              title={`Osmind uses CPT© American Medical Association, HCPCS - Centers for Medicare & Medicaid Services.`}
            >
              <InfoCircleOutlined className={styles.tooltipIcon} />
            </Tooltip>
          </div>
        </div>
        <div className={styles.verticalField}>
          <div>
            <label>{`Prior authorization number (optional)`}</label>
          </div>
          <Input
            testId={testIds.authNumber}
            placeholder="Add"
            value={authNumber ?? ''}
            onChange={handleAuthNumberChange}
            allowClear
            style={verticalInputStyle}
            disabled={readOnly}
          />
        </div>
        {!readOnly && (
          <>
            <div className={styles.verticalField}>
              <div>
                <label>{`Billing template`}</label>
              </div>
              <Select
                showSearch
                options={templateOptions}
                value={selectedTemplateId}
                onSelect={handleTemplateChange}
                onSearch={handleSearchTemplates}
                placeholder={'Select a billing template'}
                testId={testIds.billingTemplate}
                filterOption={false}
                style={verticalInputStyle}
                notFoundContent={
                  <NotFoundContent
                    isFetching={isFetchingBillingTemplates}
                    type={'billingTemplate'}
                  />
                }
                disabled={isFetchingBillingCodeDxMapping}
              />
            </div>
            <div className={styles.verticalField}>
              <div>
                <label>{`Add billing code`}</label>
                <Tooltip
                  title={`For E/M codes, Modifier 25 is commonly used. If you include another service (e.g. ketamine infusion/injection) on the same date, this modifier should be included. It indicates a significant, separately identifiable preventive or other E/M service that was provided on the same date.`}
                >
                  <InfoCircleOutlined className={styles.tooltipIcon} />
                </Tooltip>
              </div>
              <div className={styles.selectAndButtonContainer}>
                <Select
                  showSearch
                  value={selectedBillingCodeId}
                  onSelect={handleBillingCodeChange}
                  onSearch={handleSearchBillingCodes}
                  placeholder={'Select a billing code'}
                  testId={testIds.billingCode}
                  filterOption={false}
                  style={verticalInputStyle}
                  notFoundContent={
                    <NotFoundContent
                      isFetching={isFetchingBillingCodes}
                      type={'billingCode'}
                    />
                  }
                >
                  {mapToOptionGroups(billingCodeOptions)}
                </Select>
                <Button
                  testId={testIds.addBillingCodeButton}
                  icon={<PlusOutlined />}
                  type="default"
                  onClick={handleAddBillingCode}
                  disabled={isFetchingBillingCodeDxMapping}
                >
                  Add
                </Button>
              </div>
            </div>
          </>
        )}
        <LineItemsTable
          loading={isFetchingBillingCodeDxMapping}
          readOnly={readOnly}
        />
        <NationalDrugCodeTable readOnly={readOnly} />
      </div>
    </Card>
  )
}

export default Procedures
