import { useQuery } from '@tanstack/react-query'

import { ListNoteDetailsQueryParams, listNoteDetails } from '../api'
import { NOTE_DETAILS_QUERY_KEY } from '../constants'
import { NoteDetail } from '../types'

const noteDetailsQueryKey = (patientId: string | null) => [
  NOTE_DETAILS_QUERY_KEY,
  'patient',
  patientId,
]

function maybeParseDate(dateString?: string | null) {
  if (dateString) {
    return new Date(dateString)
  }
  return null
}

export const useNoteDetails = (
  patientId: string,
  queryParams: Omit<ListNoteDetailsQueryParams, 'patientId'> = {},
  reactQueryOptions: { enabled?: boolean; keepPreviousData?: boolean } = {}
) => {
  const queryOptionEnabled = reactQueryOptions.enabled ?? true
  return useQuery(
    noteDetailsQueryKey(patientId),
    async () => {
      const result = await listNoteDetails({
        ...queryParams,
        patientId: patientId,
      })

      return {
        ...result,
        data: result.data.map<NoteDetail>((detail) => {
          return {
            ...detail,
            deletedAt: maybeParseDate(detail.deletedAt),
            startTime: maybeParseDate(detail.startTime),
            endTime: maybeParseDate(detail.endTime),
            dischargeTime: maybeParseDate(detail.dischargeTime),
            appointmentDate: new Date(detail.appointmentDate),
            firstSignedAt: maybeParseDate(detail.firstSignedAt),
          }
        }),
      }
    },
    { ...reactQueryOptions, enabled: queryOptionEnabled }
  )
}
