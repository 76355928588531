import { ReactNode, useContext, useEffect, useMemo } from 'react'

import { Menu, message } from 'antd'
import cx from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'

import {
  IntakeFormSignatureRequestStatus,
  JOTFORM_IDENTIFIER,
  OSMIND_INTAKE_IDENTIFIER,
} from '../../../api/intakeForms'
import { usePatientLabsResults } from '../../../hooks/useLabsResults'
import { usePatientDemographics } from '../../../hooks/usePatientInfo'
import { useProfileUrlParams } from '../../../hooks/useProfileUrlParams'
import { useProviderSidePatientData } from '../../../hooks/useProviderSidePatientData'
import { useAppContext } from '../../../libs/contextLib'
import { useFeatureFlags } from '../../../libs/featureFlags'
import { isPatientActive } from '../../../libs/utils'
import { UnreadMessageCountsContext } from '../../../v2/messaging/UnreadMessageCounts'
import { getClinicalNotesUrl } from '../ClinicalNotes/utils'
import { HeaderItem } from './HeaderItem'

import styles from './PatientHeader.module.scss'

type MenuKeys =
  | 'clinical-notes'
  | 'progress'
  | 'profile'
  | 'billing'
  | 'documents'
  | 'medication'
  | 'journal'
  | 'messages'
  | 'settings'
  | 'labs'

type ItemType = {
  label: string | ReactNode
  key: MenuKeys
  route: string
  disabled?: boolean
  testId: string
}

export type PatientHeaderProps = {
  providerId: string
  patientId: string
  healthGorillaUserName: string
}

export const getSelectedKey = (urlPathname?: string): MenuKeys => {
  if (!urlPathname) {
    return 'progress'
  }
  const route = urlPathname.split('/')
  if (route.length < 3) {
    console.error('Selected key logic needs to be updated')
    return 'clinical-notes'
  }

  const menuKey = route[2]
  return menuKey as MenuKeys
}

export const PatientHeader = ({
  providerId,
  patientId,
  healthGorillaUserName,
}: PatientHeaderProps) => {
  const { appSettings } = useAppContext()
  const { labs: isLabsEnabled } = useFeatureFlags()
  const { urlParams } = useProfileUrlParams(providerId)
  const location = useLocation()

  // Because changing the selected menu key causing a route change,
  // the selected key must be fetched from the browser url on each mount
  const selectedKey = getSelectedKey(location.pathname as string | undefined)
  const { data: patient, isError: isPatientDemographicsError } =
    usePatientDemographics(patientId)
  const {
    isError: isPatientMessagesError,
    unreadMessageCounts: patientMessages,
  } = useContext(UnreadMessageCountsContext)
  const { data: patientLabs, isError: isPatientLabsError } =
    usePatientLabsResults(patientId)
  const { isError: isProviderSidePatientError, data: providerSidePatient } =
    useProviderSidePatientData(patientId)

  const newMessageCount = useMemo(() => {
    if (!patientMessages?.get || !patient?.ChannelUrl) {
      return 0
    }
    const newMessages = patientMessages.get(patient.ChannelUrl)
    if (!newMessages) {
      return 0
    }

    if (newMessages.count === 0 && newMessages.markedUnread) {
      return 1
    }

    return newMessages.count
  }, [patient, patientMessages])

  const unviewedLabsCount = useMemo(() => {
    if (!patientLabs) {
      return 0
    }
    return patientLabs.reduce(
      (acc: number, lab: { viewed: boolean }) => acc + (!lab.viewed ? 1 : 0),
      0
    )
  }, [patientLabs])

  const signatureRequests = useMemo(
    () => providerSidePatient?.SignatureRequests ?? [],
    [providerSidePatient]
  )
  const latestOsmindIntake = useMemo(
    () =>
      signatureRequests
        .sort(
          (a, b) => new Date(b.SentAt).getTime() - new Date(a.SentAt).getTime()
        )
        .find((req) =>
          req.RequestReference?.includes(OSMIND_INTAKE_IDENTIFIER)
        ),
    [signatureRequests]
  )

  const requiresReviewCount = useMemo(
    () =>
      signatureRequests.filter((req) => {
        if (!latestOsmindIntake) {
          // If no new Osmind Intake, still show alert for old intake forms
          return (
            req.SignatureStatus ===
            IntakeFormSignatureRequestStatus.LEGACY_INTAKE_REQUIRES_REVIEW
          )
        }

        if (req.RequestReference === latestOsmindIntake.RequestReference) {
          // If is the latest Osmind intake form, still show alert for it
          return (
            req.SignatureStatus ===
            IntakeFormSignatureRequestStatus.OSMIND_INTAKE_REQUIRES_REVIEW
          )
        }

        return (
          // Show alert for all other forms if not legacy JotForm intake form (i.e. Hellosign)
          req.SignatureStatus === 'requires review' &&
          !req.RequestReference?.includes(JOTFORM_IDENTIFIER)
        )
      }).length,
    [signatureRequests, latestOsmindIntake]
  )

  const menuItems: ItemType[] = useMemo(() => {
    const items: (ItemType | null)[] = [
      {
        label: 'Notes',
        key: 'clinical-notes',
        route: getClinicalNotesUrl({ urlParams }),
        testId: 'clinicalNotes',
      },
      {
        label: 'Progress',
        key: 'progress',
        route: `/patient/progress?${urlParams}`,
        testId: 'charts',
      },
      {
        label: 'Profile',
        key: 'profile',
        route: `/patient/profile?${urlParams}`,
        testId: 'patientProfile',
      },
      {
        label: 'Billing',
        key: 'billing',
        route: `/patient/billing?${urlParams}`,
        testId: 'billing',
      },
      {
        label:
          requiresReviewCount > 0 ? (
            <HeaderItem label="Documents" count={requiresReviewCount} />
          ) : (
            'Documents'
          ),
        key: 'documents',
        route: `/patient/documents?${urlParams}`,
        testId: 'documents',
      },
      {
        label: 'Medications',
        key: 'medication',
        route: `/patient/medication?${urlParams}`,
        testId: 'patientMeds',
      },
      isLabsEnabled && healthGorillaUserName
        ? {
            label:
              unviewedLabsCount > 0 ? (
                <HeaderItem label="Labs" count={unviewedLabsCount} />
              ) : (
                'Labs'
              ),
            key: 'labs',
            route: `/patient/labs?${urlParams}`,
            testId: 'labs',
          }
        : null,
      {
        label: 'Journal',
        key: 'journal',
        route: `/patient/journal?${urlParams}`,
        disabled: !isPatientActive(patient?.IsActive),
        testId: 'journal',
      },
      {
        label:
          isPatientActive(patient?.IsActive) && newMessageCount > 0 ? (
            <HeaderItem label="Messages" count={newMessageCount} />
          ) : (
            'Messages'
          ),
        key: 'messages',
        route: `/patient/messages?${urlParams}`,
        disabled: !isPatientActive(patient?.IsActive),
        testId: 'messaging',
      },
      {
        label: 'Settings',
        key: 'settings',
        route: `/patient/settings?${urlParams}`,
        testId: 'patientSettings',
      },
    ]
    return items.reduce<ItemType[]>((acc, item) => {
      if (item) {
        acc.push(item)
      }
      return acc
    }, [])
  }, [
    urlParams,
    patient,
    healthGorillaUserName,
    newMessageCount,
    unviewedLabsCount,
    requiresReviewCount,
  ])

  useEffect(() => {
    if (isPatientDemographicsError) {
      message.error('Failed to get patient details')
    }

    if (isPatientLabsError) {
      message.error('Failed to get patient labs data')
    }

    if (isPatientMessagesError && appSettings.secureMessaging) {
      message.error('Failed to load patient messages')
    }

    if (isProviderSidePatientError) {
      message.error('Failed to load patient documents data')
    }
  }, [
    isPatientDemographicsError,
    isPatientLabsError,
    isPatientMessagesError,
    appSettings,
    isProviderSidePatientError,
  ])

  return (
    <div className={styles.container}>
      <Menu mode="horizontal" selectedKeys={[selectedKey]}>
        {menuItems.map(({ key, label, route, disabled, testId }, idx) => {
          return (
            <Menu.Item
              key={key}
              className={cx(styles.menuItem, {
                [styles.firstMenuItem]: idx === 0,
              })}
              disabled={disabled}
              id={testId}
            >
              <NavLink to={route}>{label}</NavLink>
            </Menu.Item>
          )
        })}
      </Menu>
    </div>
  )
}
