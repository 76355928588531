import { PracticeDataType } from '../../../../hooks/usePatientProfile/shared-types'
import { NoteDetailFromApi } from '../../../../v2/notes/types'
import { BILLING } from '../field-constants'
import {
  toAddressInputValue,
  toEinInputValue,
  toSsnInputValue,
} from '../mappers'
import {
  AddressInput,
  BillingInfoFieldProps,
  BillingType,
  ClaimForm,
  ClaimLocation,
  ClaimProvider,
  InlineFieldProps,
  Option,
  TAX_TYPE,
} from '../types'
import {
  getAddress2FromAddress,
  getNewBillingValuesOnProviderChange,
  getNewValuesOnNestedFieldChange,
} from '../utils'

// hide/show input fields based on billing type and tax type
const getActiveFields = (
  billingType: BillingType,
  taxType: TAX_TYPE | null
) => {
  if (billingType === 'Practice') {
    return BILLING.INFO.filter(
      (field) =>
        !['billing.providerId', 'billing.taxType', 'billing.ssn'].includes(
          field.name
        )
    )
  } else {
    return BILLING.INFO.filter((field) => {
      const excludeFields = ['billing.name']
      switch (taxType) {
        case TAX_TYPE.EIN:
          excludeFields.push('billing.ssn')
          break
        case TAX_TYPE.SSN:
          excludeFields.push('billing.ein')
          break
        default:
          excludeFields.push('billing.ssn', 'billing.ein')
      }
      return !excludeFields.includes(field.name)
    })
  }
}

const getPopulatedFields = (
  fields: BillingInfoFieldProps[],
  billingType: BillingType,
  locationOptions: Option[],
  providerOptions: Option[]
) =>
  fields.map((field) => {
    // attach provider / practice specific label
    const newField: InlineFieldProps = {
      ...field,
      label:
        typeof field.label === 'string'
          ? field.label
          : field.label[billingType],
    }
    // attach options for location and provider dropdowns
    if (field.name === 'billing.locationId') {
      newField.options = locationOptions
    } else if (field.name === 'billing.providerId') {
      newField.options = providerOptions
    }
    return newField
  })

export const getActivePopulatedFields = (
  billingType: BillingType | null,
  taxType: TAX_TYPE | null,
  locationOptions: Option[],
  providerOptions: Option[]
) => {
  if (billingType === null) {
    return []
  }
  const activeFields = getActiveFields(billingType, taxType)
  return getPopulatedFields(
    activeFields,
    billingType,
    locationOptions,
    providerOptions
  )
}

const getNewValuesOnBillingTypeChange = (
  values: ClaimForm,
  newBillingType: BillingType | null,
  locationsByLocationId: { [index: string]: ClaimLocation },
  {
    PracticeName,
    ProviderPracticeEIN,
    ProviderPracticeBillingNPI,
  }: Pick<
    PracticeDataType,
    'PracticeName' | 'ProviderPracticeEIN' | 'ProviderPracticeBillingNPI'
  >,
  notesById: { [index: string]: NoteDetailFromApi },
  providersById: { [index: string]: ClaimProvider }
): ClaimForm => {
  // clear out all other fields except for location and address
  let newBilling: ClaimForm['billing'] | undefined = undefined

  if (newBillingType === 'Practice') {
    const selectedLocation = values.billing.locationId
      ? locationsByLocationId[values.billing.locationId]
      : null
    // prefill location's phone, ein, and npi if a location is selected
    newBilling = {
      ...values.billing,
      type: newBillingType,
      name: PracticeName ?? null,
      providerId: null,
      phoneNumber: selectedLocation?.PhoneNumber ?? null,
      taxType: null,
      ein: selectedLocation?.Ein || null, // need to use "||" here since empty Ein on Location is represented by an empty string
      ssn: null,
      ssnFirstFive: null,
      ssnLastFour: null,
      npi: selectedLocation?.BillingNPI || null, // need to use "||" here since empty BillingNPI on Location is represented by an empty string
      taxonomyCode: null,
    }
    // only try to fallback to practice EIN if a location is selected
    if (selectedLocation && !newBilling.ein && ProviderPracticeEIN) {
      newBilling.ein = ProviderPracticeEIN
    }
    // only try to fallback to practice NPI if a location is selected
    if (selectedLocation && !newBilling.npi && ProviderPracticeBillingNPI) {
      newBilling.npi = ProviderPracticeBillingNPI
    }
  } else if (newBillingType === 'Provider') {
    newBilling = {
      ...values.billing,
      type: newBillingType,
      name: null,
      providerId: null,
      phoneNumber: null,
      taxType: null,
      ein: null,
      ssn: null,
      ssnFirstFive: null,
      ssnLastFour: null,
      npi: null,
      taxonomyCode: null,
    }
    const selectedNoteId = values.note.noteId
    const selectedNoteRenderingProviderId =
      selectedNoteId &&
      notesById[selectedNoteId] &&
      notesById[selectedNoteId].renderingProviderId
    if (selectedNoteRenderingProviderId) {
      newBilling = getNewBillingValuesOnProviderChange(
        newBilling,
        selectedNoteRenderingProviderId,
        providersById
      )
    }
  } else {
    newBilling = {
      ...values.billing,
      type: newBillingType,
      name: null,
      providerId: null,
      phoneNumber: null,
      taxType: null,
      ein: null,
      ssn: null,
      ssnFirstFive: null,
      ssnLastFour: null,
      npi: null,
      taxonomyCode: null,
    }
  }
  return {
    ...values,
    billing: newBilling,
  }
}

const getNewValuesOnProviderChange = (
  values: ClaimForm,
  newProviderId: string | null,
  providersById: { [index: string]: ClaimProvider }
) => {
  return {
    ...values,
    billing: getNewBillingValuesOnProviderChange(
      values.billing,
      newProviderId,
      providersById
    ),
  }
}

const getNewValuesOnTaxTypeChange = (
  values: ClaimForm,
  newTaxType: TAX_TYPE | null,
  providersById: { [index: string]: ClaimProvider }
): ClaimForm => {
  const selectedProvider = values.billing.providerId
    ? providersById[values.billing.providerId]
    : null
  const isSsn = newTaxType === TAX_TYPE.SSN
  const isEin = newTaxType === TAX_TYPE.EIN
  return {
    ...values,
    billing: {
      ...values.billing,
      taxType: newTaxType,
      ein: isEin ? toEinInputValue(selectedProvider?.ein) : null,
      ssn: isSsn ? toSsnInputValue(selectedProvider?.ssnLastFour) : null,
      ssnFirstFive: isSsn ? selectedProvider?.ssnFirstFive : null,
      ssnLastFour: isSsn ? selectedProvider?.ssnLastFour : null,
    },
  }
}

const getNewValuesOnLocationChange = (
  values: ClaimForm,
  newLocationId: string | null,
  locationsByLocationId: { [index: string]: ClaimLocation },
  {
    ProviderPracticeEIN,
    ProviderPracticeBillingNPI,
  }: Pick<
    PracticeDataType,
    'ProviderPracticeEIN' | 'ProviderPracticeBillingNPI'
  >
): ClaimForm => {
  const selectedLocation = newLocationId
    ? locationsByLocationId[newLocationId]
    : null

  const newBilling = {
    ...values.billing,
    locationId: newLocationId,
    address: toAddressInputValue(selectedLocation) ?? null,
    address2: selectedLocation?.AddressLine2 || null, // AddressLine2 can be empty string
  }
  if (values.billing.type === 'Practice') {
    newBilling.phoneNumber = selectedLocation?.PhoneNumber ?? null
    newBilling.taxonomyCode = null
    // only attempt to fallback to practice data if a location is selected
    if (selectedLocation) {
      newBilling.ein = selectedLocation.Ein || ProviderPracticeEIN || null
      newBilling.npi =
        selectedLocation.BillingNPI || ProviderPracticeBillingNPI || null
    } else {
      newBilling.ein = null
      newBilling.npi = null
    }
  }
  return {
    ...values,
    billing: newBilling,
  }
}

export const getNewValuesOnAddressChange = (
  values: ClaimForm,
  newAddress: AddressInput
): ClaimForm => {
  return {
    ...values,
    billing: {
      ...values.billing,
      address: newAddress,
      address2: getAddress2FromAddress(newAddress),
    },
  }
}

export const getNewValues = (
  values: ClaimForm,
  fieldName: string,
  fieldValue: any,
  providersById: { [index: string]: ClaimProvider },
  locationsByLocationId: { [index: string]: ClaimLocation },
  practiceData: Omit<PracticeDataType, 'CreatedAt'>,
  notesById: { [index: string]: NoteDetailFromApi }
): ClaimForm => {
  switch (fieldName) {
    case 'billing.type':
      return getNewValuesOnBillingTypeChange(
        values,
        fieldValue,
        locationsByLocationId,
        practiceData,
        notesById,
        providersById
      )
    case 'billing.providerId':
      return getNewValuesOnProviderChange(values, fieldValue, providersById)
    case 'billing.taxType':
      return getNewValuesOnTaxTypeChange(values, fieldValue, providersById)
    case 'billing.locationId':
      return getNewValuesOnLocationChange(
        values,
        fieldValue,
        locationsByLocationId,
        practiceData
      )
    case 'billing.address':
      return getNewValuesOnAddressChange(values, fieldValue)
    default:
      return getNewValuesOnNestedFieldChange(values, fieldName, fieldValue)
  }
}
