export const MISSING_CLAIM_ID_ERROR_MESSAGE =
  'Unable to navigate to claim because the ClaimID was not found.'
export class MissingClaimIdError extends Error {
  readonly patientId?: string
  /**
   * Providing patientId enables better handling at the ErrorBoundary screen
   */
  constructor({ patientId }: { patientId?: string } = {}) {
    super(MISSING_CLAIM_ID_ERROR_MESSAGE)
    /**
     * Instance property `name` is able to be set,
     * - Static property `name` is not able to be overridden
     */
    this.name = 'MissingClaimIdError'
    this.patientId = patientId
  }
}

export const MISSING_PROVIDER_ID_ERROR_MESSAGE =
  'Unable to navigate to claim because the ProviderID was not found.'
export class MissingProviderIdError extends Error {
  readonly patientId?: string
  /**
   * Providing patientId enables better handling at the ErrorBoundary screen
   */
  constructor({ patientId }: { patientId?: string } = {}) {
    super(MISSING_PROVIDER_ID_ERROR_MESSAGE)
    this.name = 'MissingProviderIdError'
    this.patientId = patientId
  }
}

export const MISSING_PATIENT_ID_ERROR_MESSAGE =
  'Unable to navigate to claim because the ProviderID was not found.'
export class MissingPatientIdError extends Error {
  // Will never be defined for this error
  readonly patientId = ''
  constructor() {
    super(MISSING_PATIENT_ID_ERROR_MESSAGE)
    this.name = 'MissingPatientIdError'
  }
}

export type ClaimsV2Error =
  | MissingClaimIdError
  | MissingProviderIdError
  | MissingPatientIdError

export const ClaimsV2KnownErrorNameSet = new Set<string>([
  'MissingClaimIdError',
  'MissingProviderIdError',
  'MissingPatientIdError',
])
